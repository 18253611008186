import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import LogComponent from '../../component/diagnostics/LogComponent';
import Date from '../../component/common/Date';
import {isEmpty} from '../../util/helpers';

function LogContainer(props) {
    const startDate = dayjs().subtract(1, 'hour').startOf("minutes");
    const [changeDateValue, setChangeDateValue] = useState(null);
    const [selectedDate, setSelectedDate] = useState(startDate.unix() * 1000);
    const [nextToken, setNextToken] = useState(null);
    const [localLogs, setLocalLogs] = useState([]);
    const [loadingMoreDisabled, setLoadingMoreDisabled] = useState(false);
    
    const changeDate = (data) => {
        if (!isEmpty(data)) {
            let newDate = data.startOf("minutes").unix() * 1000;
            setChangeDateValue(newDate);
        }
    };

    const {isLoading, logs, logsFetchedForStartDate, nextForwardToken, error} = props.useLogsEffect({
        params: {startDate: selectedDate, nextToken}
    });

    useEffect(() => {
        if (changeDateValue && changeDateValue !== selectedDate && !isLoading) {
            //This effect stops changes from happening until loading is done
            setSelectedDate(changeDateValue);
            setLocalLogs([]);
            setNextToken(null);
        }
    }, [changeDateValue, isLoading, selectedDate]);

    const loadMore = () => {
        if (nextToken !== nextForwardToken && nextForwardToken !== null) {
            setNextToken(nextForwardToken);
        }
    };

    useEffect(() => {
        if (!isLoading) {
            if (logsFetchedForStartDate !== selectedDate) {
                setLocalLogs([]);
            } else if (logs) {
                setLocalLogs(prevState => [].concat(prevState, logs));
            }
        }
    }, [logs, isLoading, logsFetchedForStartDate, selectedDate]);

    useEffect(() => {
        setLoadingMoreDisabled(nextForwardToken === null);
    }, [nextForwardToken])

    return (<Box>
            <Typography variant='h6'>{props.title}</Typography>
            <Grid container alignItems='flex-start' sx={{mt: 3}}>
                <Grid container item md={12} xl={8}>
                    <Date name='startDate' label='Start Date' onChange={changeDate} value={startDate}/>
                </Grid>
                <Grid container item md={12}>
                    <LogComponent
                        logs={localLogs}
                        error={error}
                        isLoading={isLoading}
                        loadMore={loadMore}
                        loadMoreDisabled={loadingMoreDisabled}
                    />
                </Grid>
            </Grid>
        </Box>)
}

LogContainer.propTypes = {
    title: PropTypes.string, useLogsEffect: PropTypes.func
};

LogContainer.defaultProps = {};

export default LogContainer;