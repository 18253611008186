import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Hidden, Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import HelpSystemContext from '../context/HelpSystemContext';
import HelpSystemDrawer from './HelpSystemDrawer';

const {cloudProvider} = window._env_ || {};
const CLOUD_PROVIDER = cloudProvider || process.env.REACT_APP_CLOUD_PROVIDER;
const drawerWidth = 360;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        }),
        overflow: 'hidden',
    }),
);

// noinspection FunctionNamingConventionJS
function HelpSystemComponent({open, setOpen, ...props}) {

    const location = useLocation();
    const [post, setPost] = useState('');
    const [fileName, setFileName] = useState('');
    const [provider, setProvider] = useState('');

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setFileName(location.pathname
            .replace(/^\/|\/$/g, '')
            .split('/')
            .join('_')
            .replace(/_[0-9]+/g, '') + '.md');
    }, [location.pathname]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('provider')) {
            setProvider(`/${searchParams.get('provider')}`);
        } else {
            setProvider('');
        }
    }, [location.pathname, location.search]);

    useEffect(() => {
        if (open) {
            import(`../helpDoc${provider}/${fileName}`)
                .then(res => {
                    fetch(res.default)
                        .then(res => res.text())
                        .then(text => setPost(text.toString()))
                        .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
        }
    }, [open, fileName, provider]);

    return <>
        <Hidden mdDown>
            <Box sx={{display: 'flex'}}>
                <HelpSystemContext.Provider value={open}>
                    <Main open={open} className={open ? 'help-opened' : ''}>
                        {props.children}
                    </Main>
                </HelpSystemContext.Provider>

                <Box style={{...(open && {paddingRight: 0}), ...(!open && {pointerEvents: 'none'})}}>
                <HelpSystemDrawer drawerAnchor='right' post={post}
                                  handleDrawerClose={handleDrawerClose} open={open} drawerWidth={drawerWidth}/>
                </Box>
            </Box>
        </Hidden>


        <Hidden mdUp>
            {!open &&
                <HelpSystemContext.Provider value={open}>
                    {props.children}
                </HelpSystemContext.Provider>
            }

            <HelpSystemDrawer drawerAnchor='top' post={post} cloudProvider={CLOUD_PROVIDER}
                              handleDrawerClose={handleDrawerClose} open={open} drawerWidth={'100%'}/>
        </Hidden>
    </>;
}

HelpSystemComponent.propTypes = {};

HelpSystemComponent.defaultProps = {};

export default HelpSystemComponent;
