import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import toast from 'toasted-notes';
import axios from 'axios';
import api_routes from '../util/api_routes';
import {handleErrors} from '../util/errorHandler';
import {isEmpty} from '../util/helpers';
import httpStatus from '../util/http_status';
import {authenticationService} from '../container/auth/authenticationService';
import CustomAlert from '../component/common/CustomAlert';
import {setAuthenticated} from '../action';

const initParams = {
    // Cloud connection id to fetch by
    cloudConnectionId: 0,
    // If true, cloud connection connectivity will be updated
    updateConnectivity: false,
};

const initCloudConnection = {};

function useCloudConnection({ params = initParams }) {

    const dispatch = useDispatch();

    const { cloudConnectionId, updateConnectivity } = params;

    const [cloudConnection, setCloudConnection] = useState(initCloudConnection);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadCloudConnection() {
        const fetchCloudConnectionData = async function fetchCloudConnectionData() {
            if(cloudConnectionId) {
                try {
                    const result = await axios.get(`${api_routes.cloudConnection.endpoint}/${cloudConnectionId}`, {
                        params: {updateConnectivity}
                    });
                    setCloudConnection(result.data);
                    setIsLoading(false);
                } catch (error) {
                    const tempErrors = handleErrors(error.response);
                    toast.notify(({onClose}) =>
                        <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
                    // Let the app know that this user is no longer authenticated (this will trigger the login page)
                    if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
                        authenticationService.clearTokenInfo();
                        dispatch(setAuthenticated(false));
                    }
                }
            }
        };

        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchCloudConnectionData();
    }, [cloudConnectionId, updateConnectivity, dispatch]);

    return {isLoading, cloudConnection};
}

export default useCloudConnection;
