import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Box, Grid, ListItemIcon, Typography} from '@mui/material';
import UserFolderPermissionsTable from './UserFolderPermissionsTable';
import InlineEditTextField from '../common/InlineEditTextField';
import InlineEditSelectField from '../common/InlineEditSelectField';
import HelpSystemContext from '../../context/HelpSystemContext';
import {Icon} from '@iconify/react';
import awsIcon from '@iconify-icons/mdi/aws';
import googleIcon from '@iconify-icons/mdi/google-cloud';
import azureIcon from '@iconify-icons/mdi/microsoft-azure';
import {isEmpty} from '../../util/helpers';
import {ALIBABA, AWS, AZURE, GCP} from '../../util/cloud_providers';

const StyledTableIcon = styled(ListItemIcon)`
  margin-left: 0.5rem;
  min-width: 32px;
  vertical-align: middle;
`;

function FolderEditForm(props) {

    const providerIcon = (connection) => {
        if (connection) {
            switch (connection.cloudProvider) {
                case AWS:
                    return awsIcon;
                case AZURE:
                    return azureIcon;
                case GCP:
                    return googleIcon
                case ALIBABA:
                    return 'simple-icons:alibabacloud';
                default:
                    return null;
            }
        }
    }

    const getOptions = function getOptions() {
        let tempOptions = [];
        if (props.id !== 0) {
            tempOptions = [{key: '-1', value: '-1', displayValue: 'Inherited'}];
        }
        return tempOptions.concat(props.cloudConnections.map(cloudConnection =>
            ({key: cloudConnection.id, value: cloudConnection.id, displayValue: cloudConnection.name})));
    };

    return (
        <HelpSystemContext.Consumer>
            {() =>
                <form>
                    <Grid container justifyContent='flex-start'>
                        <Grid container item>
                            <InlineEditTextField name='name' label='Folder Name' labelVariant='body1' value={props.name}
                                                 updateMessage='Saved' required
                                                 save={(event, data) => props.handleSubmit(event, data)}/>
                        </Grid>
                        <Grid container item>
                            <UserFolderPermissionsTable
                                folderId={props.id}
                                userFolderPermissions={props.folderPermissions}
                                updatePermission={props.updatePermission}
                                loadingFolderPermissions={props.loadingFolderPermissions}/>
                        </Grid>
                        <Grid container item>
                            <Box sx={{mt: 3, width: '100%'}}>
                                <InlineEditSelectField name='cloudConnectionId' label='Cloud Connection' width='100'
                                                       helperText='This is the cloud connection that will be used by this folder.'
                                                       labelVariant='body1' updateMessage='Saved'
                                                       value={isEmpty(props.cloudConnectionId?.toString()) ? '-1'
                                                           : props.cloudConnectionId?.toString()}
                                                       save={(event, data) => props.handleSubmit(event, data)}
                                                       options={getOptions()}
                                />
                            </Box>
                        </Grid>
                        <Grid container item>
                            <Box mt={3}>
                                <Typography gutterBottom variant='body1' component='div'>
                                    Resolved Cloud Path
                                </Typography>
                                <Box display='flex'>
                                {!isEmpty(props.cloudConnectionId) && !props.loadingCloudConnections &&
                                    <StyledTableIcon>
                                        <Icon width={22}
                                              icon={
                                                  providerIcon(
                                                      props.cloudConnections.find(
                                                          connection => connection.id === props.cloudConnectionId)
                                                  )}/>
                                    </StyledTableIcon>
                                }
                                <Typography variant='body2'>{props.cloudHttpPath}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            }
        </HelpSystemContext.Consumer>
    );
}

const CloudConnection = PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
});

FolderEditForm.propTypes = {
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    name: PropTypes.string,
    cloudConnectionId: PropTypes.any,
    cloudHttpPath: PropTypes.string,
    setName: PropTypes.func,
    setCloudConnectionId: PropTypes.func,
    cloudConnections: PropTypes.arrayOf(CloudConnection),
    folderPermissions: PropTypes.array,
    deleteFolder: PropTypes.func,
    absolutePath: PropTypes.string,
    updatePermission: PropTypes.func,
    loadingFolderPermissions: PropTypes.bool,
    id: PropTypes.any,
};

FolderEditForm.defaultProps = {
    cloudConnections: [],
    cloudConnectionId: '',
};

export default FolderEditForm;
