import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '@mui/material';
import {HelpOutline} from '@mui/icons-material';
import {FormControl, FormHelperText, Select, Tooltip, Typography} from '@mui/material';
import {isEmpty} from '../../util/helpers';

const StyledTypographyLabel = styled(Typography)`
    color: ${props => {
        let color;
        if (props.disabled) {
            color = '#0000008a';
        } else {
            color = '#000000cf';
        }
        return color;
    }};
    letter-spacing: 0;
`;

const tooltipLeaveDelay = 100;
const StyledLabelTooltip = styled(Tooltip)`
  margin-left: 5px;
`;

const StyledInput = styled(Select)`
  background-color: rgba(0,0,0,0.02);
  color: #333;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  padding-left: 12px;
  border-radius: 3px;
  padding-right: 12px;
`;

const StyledFormControl = styled(FormControl)`
  width: ${function setWidth(props) {
    return props.width
}}%;
`;

// noinspection FunctionNamingConventionJS
export default function FormControlSelect(props) {
    const theme = useTheme();
    return (
        <StyledFormControl variant={'standard'} error={props.error} width={props.width}
                           margin={props.margin}>
            {!isEmpty(props.label) &&
                <StyledTypographyLabel required={props.required} disabled={props.disabled} variant={"body1"}>
                    {props.label}
                    {(props.tooltip) &&
                    <StyledLabelTooltip title={<Typography variant={'body2'}>{props.tooltip}</Typography>}
                                        leaveDelay={tooltipLeaveDelay}
                                        placement='right'>
                        <HelpOutline/>
                    </StyledLabelTooltip>
                    }
                </StyledTypographyLabel>
            }
            <StyledInput fullWidth id={props.name} native={props.native} style={isEmpty(props.label) ? {marginTop: theme.spacing(3)} : {}}
                         onChange={function handlechange(event) {
                             props.onChange(event)
                         }} defaultValue={props.defaultValue}
                         name={props.name} value={props.value} onBlur={props.onBlur}
                         required={props.required} disabled={props.disabled} onKeyDown={props.onKeyDown}
            >
                {props.children}
            </StyledInput>
            {!isEmpty(props.helperText) && isEmpty(props.errorMessage) &&
            <FormHelperText id={`${props.name}-helper-text`}>
                {props.helperText}
            </FormHelperText>
            }
            {!isEmpty(props.errorMessage) &&
            <FormHelperText id={`${props.name}-error-text`}>
                {props.errorMessage}
            </FormHelperText>
            }
        </StyledFormControl>
    )
};

FormControlSelect.propTypes = {
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    width: PropTypes.string,
    name: PropTypes.string,
    helperText: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    tooltip: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.any,
    native: PropTypes.bool,
    margin: PropTypes.string,
};

FormControlSelect.defaultProps = {
    error: false,
    native: false,
    margin: 'normal',
};
