import React, {useState} from 'react';
import {Card, CardContent, Grid, Link, Typography, Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import routes from '../../util/routes';
import SettingsCompCloudConnectionsList from './cloudconnection/SettingsCompCloudConnectionsList';
import SettingsCompAdminUsers from './adminuser/SettingsCompAdminUsers';
import SettingsBackupComponent from './backup/SettingsBackupComponent';
import SettingsCompSftpConfig from './sftpconfig/SettingsCompSftpConfig';
import SettingsCompIdpList from './identityprovider/SettingsCompIdpList';
import EncryptionAlgorithmComponent from './encryptionalgorithm/EncryptionAlgorithmComponent';

const useStyles = makeStyles((theme) => ({
    subheader: {
        marginTop: theme.spacing(2), marginBottom: theme.spacing(3),
    }, card: {
        marginBottom: theme.spacing(5),
        padding: theme.spacing(2)
    }
}));

export default function SettingsComponent() {
    const classes = useStyles();

    const [triggerRefresh, setRefresh] = useState(false);
    const refresh = function refreshListOfUsers() {
        setRefresh(!triggerRefresh);
    };

    // noinspection MagicNumberJS
    return (<Box sx={{p: 3}}>
            <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
                <Grid item>
                    <Typography variant='h5'>{routes.settings.pageTitle}</Typography>
                </Grid>
            </Grid>
            <Typography className={classes.subheader} variant='body1'>Use these settings to manage
                some global configuration options for SFTP Gateway. These settings are a subset of
                available configurations. If you would like to set advanced configuration options, visit
                the <Link href={routes.kb.path} target='_blank' rel='noopener noreferrer'>
                    {routes.kb.menuTitle}</Link> or contact us at <Link href='mailto:support@thorntech.com'>
                    support@thorntech.com</Link>.
            </Typography>

            <Card className={classes.card} elevation={0} variant='outlined'>
                <CardContent sx={{ p: 0 }}>
                    <SettingsCompAdminUsers triggerRefresh={triggerRefresh}/>
                </CardContent>
            </Card>

            <Card className={classes.card} elevation={0} variant='outlined'>
                <CardContent sx={{ p: 0 }}>
                    <SettingsCompCloudConnectionsList triggerRefresh={triggerRefresh} refresh={refresh}/>
                </CardContent>
            </Card>

            <Card className={classes.card} elevation={0} variant='outlined'>
                <CardContent sx={{ p: 0 }}>
                    <SettingsCompSftpConfig/>
                </CardContent>
            </Card>

            <Card className={classes.card} elevation={0} variant='outlined'>
                <CardContent sx={{ p: 0 }}>
                    <SettingsCompIdpList triggerRefresh={triggerRefresh} refresh={refresh}/>
                </CardContent>
            </Card>

            <Card className={classes.card} elevation={0} variant='outlined'>
                <CardContent sx={{ p: 0 }}>
                    <EncryptionAlgorithmComponent triggerRefresh={triggerRefresh} refresh={refresh}/>
                </CardContent>
            </Card>

            <Card className={classes.card} elevation={0} variant='outlined'>
                <CardContent sx={{ p: 0 }}>
                    <SettingsBackupComponent refresh={refresh}/>
                </CardContent>
            </Card>
        </Box>)
}

SettingsComponent.prototype = {};
