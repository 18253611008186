import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';

// noinspection FunctionNamingConventionJS
function NonEditableTextField({labelVariant, label, value, contentTypographyVariant, style}) {
    return <div style={{display: 'flex', flexDirection: 'column', marginTop: '16px', width: '100%', ...style}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography gutterBottom variant={labelVariant}>{label}</Typography>
        </div>
        <Typography variant={contentTypographyVariant}>{value}</Typography>
    </div>;
}

NonEditableTextField.propTypes = {
    labelVariant: PropTypes.string,
    contentTypographyVariant: PropTypes.string,
    value: PropTypes.any,
};

NonEditableTextField.defaultProps = {
    labelVariant: 'caption',
    contentTypographyVariant: 'body2',
};

export default NonEditableTextField;
