import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, useTheme} from '@mui/material';
import { faFolder as solidFolderIcon } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@iconify/react';
import awsIcon from '@iconify-icons/mdi/aws';
import googleIcon from '@iconify-icons/mdi/google-cloud';
import azureIcon from '@iconify-icons/mdi/microsoft-azure';
import { isEmpty, isUndefined } from '../../util/helpers';
import { ALIBABA, AWS, AZURE, GCP } from '../../util/cloud_providers';
import { StyledRowIcon } from './FolderListComponent';
import { Link } from "react-router-dom";
import routes from "../../util/routes";
import { Edit as EditIcon } from "@mui/icons-material";
import {
    StyledActionCell,
    StyledEditIconButton,
    StyledTableCell,
    StyledTableIcon,
    StyledTableRow
} from "../common/styled";
import CustomDeleteIcon from "../common/CustomDeleteIcon";
import ConfirmDialog from "../common/ConfirmDialog";


function FoldersTable({folder, goToParentFolder, openFolder, searching, deleteFolder, refresh}) {

    const theme = useTheme();

    const [deletingFolder, setDeletingFolder] = useState(null);

    const providerIcon = (connection) => {
        switch (connection.cloudProvider) {
            case AWS:
                return awsIcon;
            case AZURE:
                return azureIcon;
            case GCP:
                return googleIcon
            case ALIBABA:
                return 'simple-icons:alibabacloud';
            default:
                return null;
        }
    }

    return (
        <TableContainer>
            <Table aria-label='folders-table'>
                {folder.id !== 0 &&
                <TableHead>
                    <StyledTableRow theme={theme} key={-1}>
                        <TableCell theme={theme} onClick={() => goToParentFolder(folder)}
                                   style={{fontWeight: '900',
                                       ...((!isUndefined(folder.folders) && folder.folders.length === 0) && { borderBottom: 'none' })
                                   }}>
                            ..
                        </TableCell>
                        <StyledTableCell align='right' theme={theme}></StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                }
                <TableBody>
                    {!isEmpty(folder.folders) && folder.folders
                        .map((item, idx) =>
                            <StyledTableRow theme={theme} key={item.id}>
                                <TableCell style={idx === folder.folders.length - 1 ? {borderBottom: 'none'} : {}}
                                           onClick={() => openFolder(item)}>
                                    <StyledRowIcon size='lg' icon={solidFolderIcon}
                                                   color={theme.palette.primary.main}/>
                                    {searching ? item.absolutePath.replaceAll('/', ' / ') : item.name}
                                    {!isEmpty(item.cloudConnection) &&
                                    <StyledTableIcon>
                                        <Icon width={22} icon={providerIcon(item.cloudConnection)}/>
                                    </StyledTableIcon>}

                                </TableCell>
                                <StyledTableCell align='right' theme={theme} style={{...{width:"0.1%", whiteSpace: "nowrap"},
                                        ...(idx === folder.folders.length - 1 ? {borderBottom: 'none'} : {})}}>
                                    <StyledActionCell className='actions'>
                                        <Link to={`${routes.folders.path(item.id)}${routes.updateFolder.path}`}>
                                            <StyledEditIconButton size='small'>
                                                <EditIcon fontSize='small' color='primary'/>
                                            </StyledEditIconButton>
                                        </Link>
                                        <IconButton size='small' onClick={() => setDeletingFolder(item)}>
                                            <CustomDeleteIcon fontSize='small'/>
                                        </IconButton>
                                        <ConfirmDialog
                                            title='Delete Folder'
                                            open={deletingFolder !== null && deletingFolder?.id === item.id}
                                            setOpen={setDeletingFolder}
                                            onConfirm={() => {
                                                deleteFolder(deletingFolder).finally(refresh);
                                            }}
                                        >
                                            Are you sure you want to delete folder `{deletingFolder?.absolutePath}`?
                                        </ConfirmDialog>
                                    </StyledActionCell>
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    {(!isEmpty(folder.folders) && folder.folders.length === 0) &&
                    <StyledTableRow theme={theme}>
                        <TableCell colSpan={2} align='center' theme={theme} style={{borderBottom: 'none'}}>
                            No folders to display
                        </TableCell>
                    </StyledTableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const Folder = PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
});

FoldersTable.propTypes = {
    folder: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        absolutePath: PropTypes.string,
        ltreePath: PropTypes.string,
        folders: PropTypes.arrayOf(Folder),
    }),
    searching: PropTypes.bool,
    goToParentFolder: PropTypes.func,
    openFolder: PropTypes.func,
    deleteFolder: PropTypes.func,
    refresh: PropTypes.func,
};

FoldersTable.defaultProps = {
    searching: false,
};

export default FoldersTable;
