const routes = {
    home: {path: '/', menuTitle: '', pageTitle: ''},
    settings: {path: '/settings', menuTitle: 'Settings', pageTitle: 'Settings'},
    cloudConnections: {path: '/cloudconnections'},
    createCloudConnection: {path: '/new', pageTitle: (provider) => `Add ${provider} Cloud Connection`},
    updateCloudConnection: {path: '/edit', pageTitle: (provider) => `Edit ${provider} Cloud Connection`},
    identityProviders: {path: '/identityproviders'},
    createIdentityProvider: {path: '/new', pageTitle: `Add Identity Provider`},
    updateIdentityProvider: {path: '/edit', pageTitle: 'Edit Identity Provider'},
    users: {path: '/users', menuTitle: 'Users', pageTitle: 'SFTP Users'},
    createUser: {path: '/new', pageTitle: 'Add User'},
    updateUser: {path: '/edit', pageTitle: 'Edit User'},
    profile: {path: '/changepassword', menuTitle: 'Change Password'},
    diagnostics: {path: '/diagnostics', menuTitle: 'Diagnostics', pageTitle: 'Diagnostics'},
    folders: {path: (folderId = ':folderId') => `/folders/${folderId}`, menuTitle: 'Folders', pageTitle: 'SFTP Folders'},
    token: { path: '/token' },
    createFolder: {path: '/new', pageTitle: 'Add Folder'},
    updateFolder: {path: '/edit', pageTitle: 'Edit Folder'},
    kb: {path: 'http://sftpgateway.com/kb', menuTitle: 'Knowledge Base'},
    kbArticle: {path: 'http://www.sftpgateway.com/s3-permissions', menuTitle: 'Knowledge Base Article'},
};

export default routes;
