import {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import api_routes from '../util/api_routes';

function usePasswordPolicy() {

    const [passwordPolicy, setPasswordPolicy] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadingPasswordPolicy() {
        const fetchPasswordPolicy = async function fetchPasswordPolicy() {
            try {
                const result = await axios.get(api_routes.passwordPolicy.endpoint);
                setPasswordPolicy(result.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchPasswordPolicy();
    }, []);

    const checkPassword = useCallback((password) => {
        return axios.post(`${api_routes.passwordCheck.endpoint}`, {password});
    }, []);

    return {isLoading, passwordPolicy, checkPassword};
}

export default usePasswordPolicy;
