export const AWS = 'aws';
export const AZURE = 'azure';
export const GCP = 'gcp';
export const ALIBABA = 'alibaba';

const cloud_providers = [
    {name: AWS, documentationUrl: "http://www.sftpgateway.com/getting-started-aws"},
    {name: AZURE, documentationUrl: "http://www.sftpgateway.com/getting-started-azure"},
    {name: GCP, documentationUrl: "http://www.sftpgateway.com/getting-started-gcp"},
    {name: ALIBABA, documentationUrl: "http://www.sftpgateway.com/getting-started-alibaba"},
];

export default cloud_providers;
