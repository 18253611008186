import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'
import 'core-js/features/string/replace-all'
import 'core-js/features/string/includes'
import 'core-js/features/array/find-index'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import rootReducer from "./reducer";

const store = createStore(rootReducer);

const root = document.getElementById('root');
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
