import React, {useEffect, useState} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {isEmpty} from '../../util/helpers';
import usePasswordPolicy from '../../api/usePasswordPolicy';

const PasswordValidator = ({password}) => {

    const {isLoading, passwordPolicy, checkPassword} = usePasswordPolicy();

    const [requirementsMet, setRequirementsMet] = useState({
        minLength: false,
        maxLength: true,
        digit: false,
        specialChar: false,
        uppercaseChar: false,
        lowercaseChar: false,
        notInWordList: false
    });

    useEffect( () => {
        async function validatePassword() {
            if (!isEmpty(password)) {
                try {
                    const response = await checkPassword(password);
                    if (response.data) {
                        setRequirementsMet(prevState => ({
                            ...prevState,
                            notInWordList: response.data.notInWordList,
                            minLength: response.data.minLength,
                            maxLength: response.data.maxLength,
                            specialChar: response.data.specialChar,
                            digit: response.data.digit,
                            lowercaseChar: response.data.lowercaseChar,
                            uppercaseChar: response.data.uppercaseChar
                        }));
                    }
                } catch (e) {
                    console.error(e);
                    setRequirementsMet({
                        minLength: false,
                        maxLength: true,
                        digit: false,
                        specialChar: false,
                        uppercaseChar: false,
                        lowercaseChar: false,
                        notInWordList: false
                    })
                }
            }
        }
        validatePassword();
    }, [password, passwordPolicy.minLength, passwordPolicy.allowedSpecialCharacters, checkPassword]);

    const getColorAndIcon = (conditionMet) => {
        if (conditionMet) {
            return {color: '#00b500', icon: <CheckIcon sx={{color: '#00b500'}} fontSize='small'/>};
        }
        return {color: '#EA1111', icon: <CloseIcon sx={{color: '#EA1111'}} fontSize='small'/>};
    }

    const getListItem = (requirement, label) => (
        <ListItem sx={{padding: 'unset'}}>
            <ListItemIcon sx={{minWidth: '28px'}}>{getColorAndIcon(requirementsMet[requirement]).icon}</ListItemIcon>
            <ListItemText primaryTypographyProps={{variant: 'body2'}} primary={label}
                          style={{ color: getColorAndIcon(requirementsMet[requirement]).color, margin: 0 }}
            />
        </ListItem>
    );

    return !isEmpty(password) && !isLoading ? (
            <List>
                {("minLength" in passwordPolicy && passwordPolicy.minLength > 0) &&
                    getListItem("minLength", `At least ${passwordPolicy.minLength} characters`)}
                {("maxLength" in passwordPolicy && passwordPolicy.maxLength > 0) &&
                    getListItem("maxLength", `At most ${passwordPolicy.maxLength} characters`)}
                {("requireDigit" in passwordPolicy && passwordPolicy.requireDigit) &&
                    getListItem("digit", "At least one digit")}
                {("requireSpecial" in passwordPolicy && passwordPolicy.requireSpecial) &&
                    getListItem("specialChar", "At least one special character")}
                {("requireUpper" in passwordPolicy && passwordPolicy.requireUpper) &&
                    getListItem("uppercaseChar", "At least one uppercase character")}
                {("requireLower" in passwordPolicy && passwordPolicy.requireLower) &&
                    getListItem("lowercaseChar", "At least one lowercase character")}
                {("notInWordList" in passwordPolicy && passwordPolicy.notInWordList) &&
                    getListItem("notInWordList", "Not in the prohibited word list")}
            </List>
        ) : null;
}

export default PasswordValidator
