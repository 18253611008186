import React, { useRef, useState } from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    ClickAwayListener,
    Grid,
    Grow,
    IconButton,
    Input,
    Link as MUILink,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    ArrowDropDownOutlined,
    ArrowDropUp as ArrowDropUpIcon,
    Edit as EditIcon,
    FirstPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
    Refresh as RefreshIcon,
    Search as SearchIcon,
} from '@mui/icons-material';
import CustomAlert from '../common/CustomAlert';
import ConfirmDialog from '../common/ConfirmDialog';
import CustomDeleteIcon from '../common/CustomDeleteIcon';
import {
    StyledActionCell,
    StyledEditIconButton,
    StyledLockIcon,
    StyledNavControls,
    StyledStatusCell,
    StyledTableCell,
    StyledTableRow
} from '../common/styled';
import CircularProgressButton from '../common/CircularProgressButton';
import routes from '../../util/routes';
import { displayMomentDate, isEmpty } from '../../util/helpers';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';

const useStyles = makeStyles((theme) => ({
    iconButton: {padding: theme.spacing(1)},
    pagination: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    card: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
        overflow: 'inherit',
    },
}));

const TablePaginationActions = (props) => {
    const classes = useStyles();
    const {count, page, rowsPerPage, onPageChange} = props;
    const handleFirstPageClick = (event) => {
        onPageChange(event, 0);
    };
    const handleBackPageClick = (event) => {
        onPageChange(event, page - 1);
    };
    const handleNextPageClick = (event) => {
        onPageChange(event, page + 1);
    };
    const handleLastPageClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.pagination}>
            <IconButton
                onClick={handleFirstPageClick}
                disabled={page === 0}
                aria-label="first page"
                size="large">
                <FirstPage/>
            </IconButton>
            <IconButton
                onClick={handleBackPageClick}
                disabled={page === 0}
                aria-label="previous page"
                size="large">
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={handleNextPageClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                size="large">
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={handleLastPageClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                size="large">
                <LastPage/>
            </IconButton>
        </div>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// noinspection FunctionNamingConventionJS
function UserListComponent(props) {
    const {
        userPages,
        refresh,
        loadingUsers,
        rowsPerPage,
        handleChangeRowsPerPage,
        handleChangePage,
        searchBox,
        handleChangeSearch
    } = props;
    const [showConfirmDeleteId, setShowConfirmDeleteId] = useState(null);

    const sortOptions = [
        {label: 'Username', value: 'username'},
        {label: 'Created Date', value: 'createdDate'},
        {label: 'Last Login Date', value: 'lastLoginDate'},
    ]

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const handleDeleteUser = async function(userId) {
        try {
            await axios.delete(`${api_routes.user.endpoint}/${userId}`);
            refresh();
        } catch (error) {
            if (!isEmpty(error.response) && error.response.status === httpStatus.conflict) {
                toast.notify(({onClose}) =>
                    <CustomAlert type='error' message={'Could not delete SFTP user.'} onClose={onClose}/>);
            }
        }
    };

    // Sort by menu popper vars
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    let ariaControls;
    let ariaExpanded;
    if (open) {
        ariaControls = 'sort-by-menu';
        ariaExpanded = 'true';
    } else {
        ariaControls = undefined;
        ariaExpanded = undefined;
    }
    const handleMenuItemClick = (event, index) => {
        props.handleChangeSortBy(sortOptions[index].value);
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(function toggle(prevOpen) {
            return !prevOpen
        });
    };
    const handleClose = (event) => {
        if (!anchorRef.current?.contains(event.target)) {
            setOpen(false);
        }
    };

    // noinspection MagicNumberJS
    return (
        <Box sx={{ p: 3 }}>
            <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
                <Grid item>
                    <Typography variant='h5'>{routes.users.pageTitle}</Typography>
                </Grid>
                <Box flexGrow={1}/>
                <Grid item>
                    <IconButton
                        type='submit'
                        className={classes.iconButton}
                        aria-label='search users'
                        size="large">
                        <SearchIcon/>
                    </IconButton>
                    <Input
                        value={searchBox}
                        onChange={(e) => handleChangeSearch(e.target.value)}
                        placeholder='Search users'
                        inputProps={{'aria-label': 'search users'}}
                    />
                </Grid>
            </Grid>
            <Card className={classes.card} variant='outlined'>
                <StyledNavControls theme={theme}>
                    <Grid container justifyContent={'space-between'} alignItems='center' wrap='nowrap' spacing={4}>
                        <Grid item>
                            <Grid container alignItems='center'>
                                <Grid item>
                                    <Box mr={1}>Sort By:</Box>
                                </Grid>
                                <Grid item>
                                    <ButtonGroup
                                        disableElevation
                                        color="primary"
                                        variant="text"
                                        size="small"
                                        ref={anchorRef}
                                        aria-label="Sort By">
                                        <Button aria-controls={ariaControls}
                                                style={{
                                                    padding: '2px 0px 2px 9px',
                                                    textTransform: 'none',
                                                    borderRight: "none",
                                                    textDecoration: "underline"
                                                }}
                                                aria-expanded={ariaExpanded}
                                                aria-label="sort by"
                                                aria-haspopup="menu" size='small'
                                                onClick={handleToggle} variant='text'>
                                            {sortOptions[sortOptions.findIndex(value => value.value === props.userSort.by)].label}
                                        </Button>
                                        <Button variant={"text"} aria-controls={ariaControls}
                                                aria-label='refresh-folder-list' size='small'
                                                style={{
                                                    borderLeft: "none",
                                                    padding: '2px 9px 2px 0px'
                                                }}
                                                onClick={props.handleChangeSortOrder}>
                                            {props.userSort.order === 'ASCENDING' ?
                                                <ArrowDropDownOutlined fontSize='small'/> :
                                                <ArrowDropUpIcon fontSize='small'/>}
                                        </Button>
                                    </ButtonGroup>
                                    <Popper open={open} anchorEl={anchorRef.current} role={undefined}
                                            transition disablePortal placement={"bottom-start"}>
                                        {function conditionalShow({TransitionProps, placement}) {
                                            return <Grow
                                                {...TransitionProps}
                                                style={{
                                                    // eslint-disable-next-line no-mixed-operators
                                                    transformOrigin: placement === 'bottom-start' && 'top right'
                                                        // eslint-disable-next-line no-mixed-operators
                                                        || 'right bottom',
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList id="sort-by-menu">
                                                            {sortOptions.map(function createMenuItem(option, index) {
                                                                return <MenuItem
                                                                    value={option.value}
                                                                    selected={option.value === props.userSort.by}
                                                                    onClick={function selectItem(event) {
                                                                        handleMenuItemClick(event, index)
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            })}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        }}
                                    </Popper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CircularProgressButton label='Add User' buttonTextTransform='none' size='small'
                                                    fullWidth={false}
                                                    theme={theme} mt={0} mr={1} ml={0} display='inline'
                                                    onClick={() => history.push(`${routes.users.path}${routes.createUser.path}`)}/>

                            <IconButton aria-label='refresh-folder-list' size='small'
                                        onClick={props.refresh}>
                                <RefreshIcon fontSize='small'/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </StyledNavControls>
                <TableContainer>
                    {isEmpty(searchBox) && userPages.content.length === 0 && !loadingUsers && <Box p={2}>
                        <Typography gutterBottom variant='body1'>Add a new SFTP user by clicking
                            the <Link to={`${routes.users.path}${routes.createUser.path}`}>Add User</Link> at the
                            top right.
                        </Typography>
                        <Typography gutterBottom variant='body1'><Link to={routes.settings.path}>Click here</Link> to
                            restore
                            an SFTP Gateway back up file.
                        </Typography>
                        <Typography gutterBottom variant='body1'>Find out more about SFTP Gateway by visiting
                            the <MUILink href={routes.kb.path} target='_blank' rel='noopener noreferrer'>
                                {routes.kb.menuTitle}</MUILink>.
                        </Typography>
                    </Box>}
                    {!isEmpty(searchBox) && userPages.content.length === 0 && <Box style={{textAlign: 'center'}}>
                        <Typography style={{padding: '.875rem 1rem'}} color='textSecondary'>No users found.</Typography>
                    </Box>}
                    <Table aria-label='users-table'>
                        <colgroup>
                            <col style={{width: '1%'}}/>
                            <col style={{width: '74%'}}/>
                            <col style={{width: '25%'}}/>
                        </colgroup>
                        <TableBody>
                            {userPages.content.map(function makeUserRow(user) {
                                return <StyledTableRow theme={theme} key={user.id}>
                                    <StyledStatusCell theme={theme}>

                                        <StyledLockIcon fontSize={"small"} color={"error"}
                                                        locked={(user.locked).toString()}/>

                                    </StyledStatusCell>
                                    <StyledTableCell theme={theme} onClick={function goTo() {
                                        history.push(`${routes.users.path}/${user.id}/edit`)
                                    }}>
                                        {user.username}
                                        <Typography variant='caption' component='div'
                                                    style={{color: theme.palette.text.secondary}}>
                                            Last Login: {displayMomentDate({date: user.lastLoginDate})}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='right' theme={theme}>
                                        <StyledActionCell className='actions'>
                                            <Link to={`${routes.users.path}/${user.id}/edit`}>
                                                <StyledEditIconButton size='small'>
                                                    <EditIcon fontSize='small' color='primary'/>
                                                </StyledEditIconButton>
                                            </Link>
                                            <IconButton size='small' onClick={() => setShowConfirmDeleteId(user.id)}>
                                                <CustomDeleteIcon fontSize='small'/>
                                            </IconButton>
                                        </StyledActionCell>
                                    </StyledTableCell>
                                    <ConfirmDialog
                                        title='Delete SFTP User'
                                        open={showConfirmDeleteId === user.id}
                                        setOpen={setShowConfirmDeleteId}
                                        onConfirm={() => handleDeleteUser(user.id)}
                                    >
                                        Are you sure you want to delete {user.username}?
                                    </ConfirmDialog>
                                </StyledTableRow>
                            })}
                        </TableBody>
                        {userPages.content.length !== 0 &&
                        <TableFooter>
                            <TableRow theme={theme}>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    colSpan={2}
                                    count={userPages.totalElements}
                                    rowsPerPage={rowsPerPage}
                                    page={userPages.number}
                                    SelectProps={{
                                        inputProps: {'aria-label': 'rows per page'},
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    style={{border: 'none'}}
                                />
                            </TableRow>
                        </TableFooter>
                        }
                    </Table>
                </TableContainer>
            </Card>
        </Box>
    );
}

const User = PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
});

UserListComponent.propTypes = {
    loadingUsers: PropTypes.bool,
    userPages: PropTypes.shape({
        content: PropTypes.arrayOf(User),
        totalPages: PropTypes.number,
        totalElements: PropTypes.number,
        number: PropTypes.number,
    }),
    refresh: PropTypes.func,
    userSort: PropTypes.object,
    handleChangePage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    handleChangeRowsPerPage: PropTypes.func,
    searchBox: PropTypes.string,
    handleChangeSearch: PropTypes.func
};

export default UserListComponent;
