const httpStatus = {
    ok: 200,
    created: 201,
    noContent: 204,
    badRequest: 400,
    unAuthorized: 401,
    notFound: 404,
    methodNotAllowed: 405,
    conflict: 409,
    internalServerError: 500,
};

export default httpStatus;
