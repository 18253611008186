import { useHistory } from 'react-router-dom';
import { authenticationService } from './authenticationService';
import routes from '../../util/routes';
import React, { useEffect } from 'react';
import useQuery from '../../hook/useQuery';

import toast from 'toasted-notes';
import CustomAlert from "../../component/common/CustomAlert";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../action";

export default function TokenIngest() {
    let query = useQuery();
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        //Exchange single use token for access token
        const singleUseToken = query.get("code");
        authenticationService
            .loginWithSingleUseToken(singleUseToken)
            .then(({token, username}) => {
                dispatch(setCurrentUser({username}));
                history.replace(routes.home.path)
            })
            .catch((error) => {
                toast.notify(({ onClose }) => <CustomAlert type='error'
                                                           message='There was a problem with authentication'
                                                           onClose={onClose}/>);
                console.error(error);
                history.replace(routes.home.path);
            });
    }, [history, query, dispatch]);

    return null;
}
