import React from 'react';
import PropTypes from 'prop-types';
import {CardHeader} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    action: {
        alignSelf: 'auto',
        marginTop: '0px',
        marginRight: '0px',
    },
}));

// noinspection FunctionNamingConventionJS
function CustomCardHeader({subheader, subheaderTypographyProps,
                              avatar, action,
                              title, titleVariant, titleAlign,
                              className, style
}) {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <CardHeader
            titleTypographyProps={{variant: titleVariant, align: titleAlign}}
            subheaderTypographyProps={subheaderTypographyProps}
            avatar={avatar}
            action={action}
            title={title}
            subheader={subheader}
            classes={{action: classes.action}}
            className={className}
            theme={theme}
            style={style}
        />
    );
}

CustomCardHeader.propTypes = {
    subheader: PropTypes.string,
    subheaderTypographyProps: PropTypes.bool,
    avatar: PropTypes.any,
    action: PropTypes.any,
    title: PropTypes.string,
    titleVariant: PropTypes.string,
    titleAlign: PropTypes.string,
    className: PropTypes.any,
    style: PropTypes.object,
};

CustomCardHeader.defaultProps = {
    titleVariant: 'h5',
    titleAlign: 'left',
};

export default CustomCardHeader;
