import React, {useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import {Card, CardContent, Grid, Box, useTheme} from '@mui/material';
import CustomAlert from '../../common/CustomAlert';
import CustomCardHeader from '../../common/CustomCardHeader';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../../common/styled';
import routes from '../../../util/routes';
import {isEmpty} from '../../../util/helpers';
import {convertErrorsToObject} from '../../../util/errorHandler';
import {ALIBABA, AWS, AZURE, GCP} from '../../../util/cloud_providers';
import AwsCloudConnectionForm from './AwsCloudConnectionForm';
import AzureCloudConnectionForm from './AzureCloudConnectionForm';
import GoogleCloudConnectionForm from './GoogleCloudConnectionForm';
import AlibabaCloudConnectionForm from './AlibabaCloudConnectionForm';
import HelpSystemContext from '../../../context/HelpSystemContext';

const {cloudProvider} = window._env_ || {};
const CLOUD_PROVIDER = cloudProvider || process.env.REACT_APP_CLOUD_PROVIDER;

const initCloudConnection = (provider, defaultName) => {
    return {
        name: defaultName,
        notes: '',
        basePrefix: '',
        ...(provider === AWS && {
            cloudProvider: 'aws',
            encryptionType: 'SSE_S3',
            encryptionId: '',
            region: null,
            useInstanceCredentials: CLOUD_PROVIDER === AWS,
            accessKeyId: '',
            accessSecret: '',
        }),
        ...(provider === AZURE && {
            cloudProvider: 'azure',
            useInstanceCredentials: false,
            connectionString: '',
            hnsEnabled: false,
        }),
        ...(provider === GCP && {
            cloudProvider: 'gcp',
            useInstanceCredentials: false,
            jwtToken: '',
            jsonKeyFilename: '',
        }),
        ...(provider === ALIBABA && {
            cloudProvider: 'alibaba',
            useInstanceCredentials: CLOUD_PROVIDER === ALIBABA,
            accessKeyId: '',
            accessSecret: '',
            instanceRoleName: '',
            region: null,
            encryptionType: 'OSSManaged',
        }),
    };
};

const initCloudConnectivity = {
    canList: false,
    canRead: false,
    canWrite: false,
}

// noinspection FunctionNamingConventionJS
function CloudConnectionCreateComponent(props) {
    const history = useHistory();
    const theme = useTheme();
    const location = useLocation();
    const provider = (!isEmpty(new URLSearchParams(location.search).get('provider')))
        ? new URLSearchParams(location.search).get('provider')
        : 'aws';
    const defaultName = (!isEmpty(new URLSearchParams(location.search).get('name')))
        ? new URLSearchParams(location.search).get('name')
        : '';
    const providerTitle = () => {
        switch (provider) {
            case AWS:
                return 'AWS';
            case AZURE:
                return 'Azure';
            case GCP:
                return 'Google';
            case ALIBABA:
                return 'Alibaba'
            default: {
                console.error("Unsupported Cloud Provider")
                return 'Unsupported'
            }
        }
    }

    const [isBlocking, setIsBlocking] = useState(false);
    const [cloudConnection, setCloudConnection] = useState(initCloudConnection(provider, defaultName));
    const [errors, setErrors] = useState(initCloudConnection);

    const resetErrors = function resetCloudConnectionErrors() {
        setErrors(initCloudConnection);
    };

    const handleTextChange = function handleTextChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.value});
    };

    const setBasePrefix = (value) => {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, basePrefix: value});
    };

    const handleJwtTokenChange = function handleJwtTokenChangeOnGcpCloudConnection(data = {
        jwtToken: '',
        jsonKeyFilename: ''
    }) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, ...data});
    };

    const handleInstanceCredentialsChange = function handleInstanceCredentialsChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.value === "true"});
    };

    const handleCheckboxChange = function handleCheckboxChange(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.checked});
    }

    const onSubmit = async function createCloudConnection(event) {
        event.preventDefault();
        try {
            await props.saveCloudConnection(cloudConnection);
            resetErrors();
            toast.notify(({onClose}) =>
                <CustomAlert message={`Created cloud connection ${cloudConnection.name}.`} onClose={onClose}/>);
            history.push(routes.settings.path);
        } catch (error) {
            let tempErrors = convertErrorsToObject(error.response);
            setErrors(tempErrors);
        }
    };

    const handleCancel = function handleCancel() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.push(routes.settings.path);
            }
        } else {
            history.push(routes.settings.path);
        }
    };

    // noinspection MagicNumberJS
    return (<HelpSystemContext.Consumer>
            {() => <Box sx={{ p: 3 }}>
                <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                    <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.settings.path)}>
                        <StyledBreadcrumbIcon theme={theme}/>
                        Back to settings
                    </StyledBreadcrumbLink>
                </StyledBreadcrumbs>
                <Grid item sm={12}>
                    <Card elevation={0}>
                        <CustomCardHeader title={routes.createCloudConnection.pageTitle(providerTitle())}/>
                        <CardContent>
                            {provider === AWS &&
                                <AwsCloudConnectionForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                                        name={cloudConnection.name} setName={handleTextChange}
                                                        notes={cloudConnection.notes} setNotes={handleTextChange}
                                                        basePrefix={cloudConnection.basePrefix}
                                                        setBasePrefix={handleTextChange}
                                                        encryptionType={cloudConnection.encryptionType}
                                                        setEncryptionType={handleTextChange}
                                                        encryptionId={cloudConnection.encryptionId}
                                                        setEncryptionId={handleTextChange}
                                                        region={cloudConnection.region} setRegion={handleTextChange}
                                                        useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                                        setInstanceCredentials={handleInstanceCredentialsChange}
                                                        keyId={cloudConnection.accessKeyId}
                                                        setKeyId={handleTextChange}
                                                        keySecret={cloudConnection.accessSecret}
                                                        setKeySecret={handleTextChange}
                                                        errors={errors}
                                                        setErrors={setErrors}
                                                        showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                                        connectivity={initCloudConnectivity}
                                />
                            }
                            {provider === AZURE &&
                                <AzureCloudConnectionForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                                          name={cloudConnection.name} setName={handleTextChange}
                                                          notes={cloudConnection.notes} setNotes={handleTextChange}
                                                          basePrefix={cloudConnection.basePrefix}
                                                          setBasePrefix={setBasePrefix}
                                                          hnsEnabled={cloudConnection.hnsEnabled}
                                                          setHnsEnabled={handleCheckboxChange}
                                                          useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                                          setInstanceCredentials={handleInstanceCredentialsChange}
                                                          connectionString={cloudConnection.connectionString}
                                                          setConnectionString={handleTextChange}
                                                          errors={errors}
                                                          setErrors={setErrors}
                                                          showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                                          connectivity={initCloudConnectivity}
                                />
                            }
                            {provider === GCP &&
                                <GoogleCloudConnectionForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                                           name={cloudConnection.name} setName={handleTextChange}
                                                           notes={cloudConnection.notes} setNotes={handleTextChange}
                                                           basePrefix={cloudConnection.basePrefix}
                                                           setBasePrefix={handleTextChange}
                                                           useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                                           setInstanceCredentials={handleInstanceCredentialsChange}
                                                           jwtToken={cloudConnection.jwtToken}
                                                           setJwtToken={handleJwtTokenChange}
                                                           errors={errors}
                                                           setErrors={setErrors}
                                                           showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                                           connectivity={initCloudConnectivity}
                                />
                            }
                            {provider === ALIBABA &&
                                <AlibabaCloudConnectionForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                                            cloudConnection={cloudConnection}
                                                            handleTextChange={handleTextChange}
                                                            connectivity={initCloudConnectivity}
                                                            setInstanceCredentials={handleInstanceCredentialsChange}
                                                            showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                                            errors={errors} setErrors={setErrors}/>
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Box>}
        </HelpSystemContext.Consumer>
    );
}

CloudConnectionCreateComponent.propTypes = {
    saveCloudConnection: PropTypes.func,
};

export default CloudConnectionCreateComponent;
