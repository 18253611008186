import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import {CardContent} from '@mui/material';
import PageLoader from './PageLoader';

function ProgressFormCard(props) {
    return (<Card elevation={0} style={props.cardStyle}>
        {props.header}
        <CardContent style={{position: 'relative', ...props.contentStyle}}>
            {props.loadingContent &&
                <PageLoader style={{marginLeft: '-16px', marginTop: '-16px'}}/>
            }
            {!props.loadingContent &&
                props.content
            }
        </CardContent>
    </Card>);
}

ProgressFormCard.propTypes = {
    loadingContent: PropTypes.bool,
    content: PropTypes.any,
};

ProgressFormCard.defaultProps = {};

export default ProgressFormCard;
