const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VERSION = "3.0.0";

const api_routes = {
  auth: {endpoint: `${BASE_URL}/login`},
  ping: {endpoint: `${BASE_URL}/`},
  logout: {endpoint: `${BASE_URL}/logout`},
  user: {endpoint: `${BASE_URL}/${VERSION}/users`},
  userSshKey: {endpoint: '/sshkeys'},
  allowedips: {endpoint: '/allowedips'},
  adminUser: {endpoint: `${BASE_URL}/${VERSION}/admin/users`},
  adminUserConfirmIdentity: {endpoint: `${BASE_URL}/${VERSION}/admin/users/confirmidentity`},
  userPublicKey: {endpoint: '/sshkeys'},
  userAllowedIps: {endpoint: '/allowedips'},
  adminUserPassword: {endpoint: `${BASE_URL}/${VERSION}/admin/users/password`},
  firstUserConfiguration: {endpoint: `${BASE_URL}/${VERSION}/admin/config`},
  generateKey: {endpoint: `${BASE_URL}/keypair`},
  firstConnectionTest: {endpoint: `${BASE_URL}/admin/config/test`},
  cloudConnectionTest: {endpoint: `${BASE_URL}/${VERSION}/cloudconnections/test`},
  cloudConnection: {endpoint: `${BASE_URL}/${VERSION}/cloudconnections`},
  cloudConnectionEncryption: {endpoint: 'encryptionOptions'},
  kmsKeys: {endpoint: `${BASE_URL}/kmsKeys`},
  folders: {endpoint: `${BASE_URL}/${VERSION}/folders`},
  folderPermissions: {endpoint: `/permissions`},
  generatePassword: {endpoint: `${BASE_URL}/${VERSION}/password`},
  backup: {endpoint: `${BASE_URL}/${VERSION}/backup`},
  bannerText: {endpoint: `${BASE_URL}/${VERSION}/bannertext`},
  configuredIdps: {endpoint: `${BASE_URL}/idps`},
  cloudLogs: {endpoint: `${BASE_URL}/${VERSION}/cloudlogs`},
  identityProvider: {endpoint: `${BASE_URL}/${VERSION}/identityproviders`},
  encryptionAlgorithms: {endpoint: `${BASE_URL}/${VERSION}/encryptionalgorithms`},
  passwordPolicy: {endpoint: `${BASE_URL}/passwordpolicy`},
  passwordCheck: {endpoint: `${BASE_URL}/${VERSION}/password/validate`},
};

export default api_routes;
