import {useEffect, useState} from 'react';
import axios from 'axios';
import api_routes from '../util/api_routes';

const initParams = {
    // Folder id which permissions to fetch
    folderId: 0,
}

const initDependencies = {
    refreshFolderPermissions: false,
}

const initFolderPermissions = [];

function useFolderPermissions({ params = initParams, dependencies = initDependencies }) {

    const { folderId } = params;
    const { refreshFolderPermissions } = dependencies;

    const [folderPermissions, setFolderPermissions] = useState(initFolderPermissions);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadFolder() {
        const fetchData = async function fetchData() {
            try {
                const result = await axios.get(
                    `${api_routes.folders.endpoint}/${folderId}${api_routes.folderPermissions.endpoint}`);
                setFolderPermissions(result.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchData();
    }, [folderId, refreshFolderPermissions]);

    return {isLoading, folderPermissions};
}

export default useFolderPermissions;
