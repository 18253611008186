export const setAuthenticated = function setAuthenticated(authenticated) {
    return {
        type: 'SET_AUTHENTICATED',
        authenticated
    }
};

export const setCurrentUser = function setCurrentUser(currentUser) {
    return {
        type: 'SET_CURRENT_USER',
        currentUser
    }
};

export const setSystemInformation = function setSystemInformation(info) {
    return {
        type: 'SET_SYSTEM_INFO',
        info
    }
};

export const setAuthenticationMethod = function setAuthenticationMethod(authMethod) {
    return {
        type: 'SET_AUTH_METHOD',
        authMethod
    }
};

export const setUserSort = function setUserSort(userSort) {
    return {
        type: 'SET_USER_SORT',
        userSort
    }
}