import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, IconButton, InputAdornment, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy as copyIcon } from '@fortawesome/free-regular-svg-icons';
import TestConnectionResultTable from './TestConnectionResultTable';
import api_routes from '../../../util/api_routes';
import { ALIBABA } from '../../../util/cloud_providers';
import { copyTextToClipboard, isEmpty } from '../../../util/helpers';
import HelpSystemContext from '../../../context/HelpSystemContext';
import PageSection from '../../common/PageSection';
import FormControlInput from '../../common/FormControlInput';
import FormControlRadioGroup from '../../common/FormControlRadioGroup';
import CircularProgressButton from '../../common/CircularProgressButton';
import identityOptions from './InstanceProfileOption';

function AlibabaCloudConnectionForm(props) {

    const theme = useTheme();
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(() => {
        setLoadingSubmit(false);
    }, [props.errors]);

    const handleSubmit = async function handleFolderSubmit(event) {
        setLoadingSubmit(true);
        if (props.handleSubmit) {
            await props.handleSubmit(event);
        } else {
            console.error('Provide handleSubmit function to Alibaba cloud connection form.')
        }
    };

    const testConnectivity = async () => {
        return await axios.post(`${api_routes.cloudConnectionTest.endpoint}`, {
            basePrefix: props.cloudConnection.basePrefix,
            accessKeyId: props.cloudConnection.accessKeyId,
            accessSecret: props.cloudConnection.accessSecret,
            region: props.cloudConnection.region,
            useInstanceCredentials: props.cloudConnection.useInstanceCredentials,
            instanceRoleName: props.cloudConnection.instanceRoleName,
            encryptionType: props.cloudConnection.encryptionType,
            cloudProvider: ALIBABA
        });
    }

    return (<HelpSystemContext.Consumer>
        {() =>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container justifyContent='flex-start'>
                    <Grid container item>
                        <FormControlInput value={props.cloudConnection.name} width='100' label='Connection Name' name='name'
                                          placeholder='Enter unique name' onChange={props.handleTextChange} required
                                          helperText='Name used to identify this cloud connection.'
                                          errorMessage={props.errors.name}/>
                    </Grid>
                    <Grid container item>
                        <FormControlInput value={props.cloudConnection.notes} width='100' label='Cloud Connection Notes'
                                          name='notes' multiline onChange={props.handleTextChange}
                                          inputProps={{maxLength: 1000}} showCount
                                          placeholder='Enter notes about the cloud connection (optional)'
                                          errorMessage={props.errors.notes}
                        />
                    </Grid>
                    <Grid container item>
                        <FormControlInput value={props.cloudConnection.basePrefix} width='100' label='OSS URL'
                                          name='basePrefix' required onChange={props.handleTextChange}
                                          placeholder='Enter URL for OSS bucket'
                                          helperText="Ex: oss://bucket-name/key-prefix"
                                          errorMessage={props.errors.basePrefix} autoComplete='off'
                                          endAdornment={<InputAdornment position='end'>
                                              <IconButton size="large" onClick={event =>
                                                  copyTextToClipboard(event, props.cloudConnection.basePrefix)}>
                                                  <FontAwesomeIcon icon={copyIcon} size="xs"
                                                                   color={theme.palette.primary.main}/>
                                              </IconButton>
                                          </InputAdornment>}
                        />
                    </Grid>
                    <Grid container item>
                        <FormControlInput value={props.cloudConnection.region} width='100' label='Region' name='region'
                                          onChange={props.handleTextChange} autoComplete='off'
                                          placeholder='Enter a bucket region or leave blank for default'
                                          helperText="Ex: us-east-1" errorMessage={props.errors.region}
                        />
                    </Grid>
                    <Grid container item>
                        <PageSection title='OSS Encryption Type' titleVariant='body1'
                                     subtitle='Uploaded objects will be encrypted to this level.'
                                     subtitleVariant='body2' pb={0} mt={3}/>
                        <FormControlRadioGroup name='encryptionType' value={props.cloudConnection.encryptionType}
                                               onChange={props.handleTextChange}
                                               helperText={'New objects uploaded by the users will be encrypted to this level. ' +
                                                   'Default and recommended option is OSS Managed.'}
                                               options={[{
                                                   value: 'OSSManaged', label: 'OSS Managed'
                                               }, {
                                                   value: 'NONE', label: 'No Encryption'
                                               }]}
                        />
                    </Grid>
                    <Grid container item>
                        <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                                     subtitle='Credentials used to access this cloud connection.'
                                     subtitleVariant='body2'/>
                        <FormControlRadioGroup name='useInstanceCredentials'
                                               value={props.cloudConnection.useInstanceCredentials}
                                               onChange={props.setInstanceCredentials}
                                               options={[
                                                   ...(props.showInstanceCredentialsOption ?
                                                       [{...identityOptions.alibaba,
                                                           subcomponent: <div style={{
                                                               width: '100%',
                                                               paddingLeft: '28px',
                                                               marginTop: '-16px'
                                                           }}>
                                                               <FormControlInput value={props.cloudConnection.instanceRoleName}
                                                                                 width='100' label='RAM role name'
                                                                                 name='instanceRoleName'
                                                                                 onChange={props.handleTextChange}
                                                                                 placeholder='Enter RAM role name'
                                                                                 disabled={!props.cloudConnection.useInstanceCredentials}
                                                                                 required={props.cloudConnection.useInstanceCredentials}
                                                                                 errorMessage={props.errors.instanceRoleName}
                                                                                 autoComplete='off'
                                                               />
                                                           </div>}] :
                                                       []),
                                                   {
                                                       value: false, label: 'Use unique credentials',
                                                       subcomponent: <div style={{
                                                           width: '100%',
                                                           paddingLeft: '28px',
                                                           marginTop: '-16px'
                                                       }}>
                                                           <FormControlInput value={props.cloudConnection.accessKeyId}
                                                                             width='100' label='Access Key ID'
                                                                             name='accessKeyId'
                                                                             onChange={props.handleTextChange}
                                                                             placeholder='Enter Key ID'
                                                                             disabled={props.cloudConnection.useInstanceCredentials}
                                                                             required={!props.cloudConnection.useInstanceCredentials}
                                                                             errorMessage={props.errors.accessKeyId}
                                                                             autoComplete='off'
                                                           />
                                                           <FormControlInput value={props.cloudConnection.accessSecret}
                                                                             width='100' label='Access Key Secret'
                                                                             name='accessSecret'
                                                                             onChange={props.handleTextChange}
                                                                             autoComplete='off'
                                                                             placeholder={`${props.cloudConnection.accessSecretSet ? '**********' : 'Enter secret for key ID'}`}
                                                                             type='password'
                                                                             errorMessage={props.errors.accessSecret}
                                                                             disabled={props.cloudConnection.useInstanceCredentials}
                                                                             required={!props.cloudConnection.useInstanceCredentials}
                                                           />
                                                       </div>
                                                   }]}
                        />
                    </Grid>
                    {!isEmpty(props.connectivity) &&
                        <Grid container item>
                            <TestConnectionResultTable connectivity={props.connectivity}
                                                       disabled={isEmpty(props.cloudConnection.basePrefix)
                                                           || (props.cloudConnection.useInstanceCredentials && isEmpty(props.cloudConnection.instanceRoleName))
                                                           || (!props.cloudConnection.useInstanceCredentials && (isEmpty(props.cloudConnection.accessKeyId) || isEmpty(props.cloudConnection.accessSecret)))}
                                                       test={testConnectivity} setErrors={props.setErrors}
                            />
                        </Grid>
                    }
                    <Grid container item justifyContent='flex-end'>
                        <CircularProgressButton label='Save' type='submit' inProgress={loadingSubmit} theme={theme}
                                                size='small' mr={1} mt={2} />
                        <CircularProgressButton label='Cancel' type='reset' onClick={props.handleCancel}
                                                theme={theme} variant='text' size='small' mt={2} />
                    </Grid>
                </Grid>
            </form>
        }
    </HelpSystemContext.Consumer>);
}

AlibabaCloudConnectionForm.prototype = {}

AlibabaCloudConnectionForm.defaultProps = {}

export default AlibabaCloudConnectionForm;
