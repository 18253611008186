import React from 'react';
import axios from 'axios';
import CloudConnectionCreateComponent from '../../component/settings/cloudconnection/CloudConnectionCreateComponent';
import api_routes from '../../util/api_routes';

// noinspection FunctionNamingConventionJS
function CloudConnectionCreateContainer() {

    const save = async function createCloudConnection(cloudConnection) {
        return await axios.post(api_routes.cloudConnection.endpoint, cloudConnection);
    };

    return <CloudConnectionCreateComponent saveCloudConnection={save} />;
}

CloudConnectionCreateContainer.propTypes = {};

CloudConnectionCreateContainer.defaultProps = {};

export default CloudConnectionCreateContainer;
