import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import {useHistory} from 'react-router-dom';
import {red} from '@mui/material/colors';
import {Grid, Box, useTheme} from '@mui/material';
import UserEditForm from './UserEditForm';
import CustomAlert from '../common/CustomAlert';
import CustomCardHeader from '../common/CustomCardHeader';
import ProgressFormCard from '../common/ProgressFormCard';
import CircularProgressButton from '../common/CircularProgressButton';
import SuccessMessageComponent from '../common/SuccessMessageComponent';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../common/styled';
import routes from '../../util/routes';
import {isEmptyObject} from '../../util/helpers';
import useFolder from '../../api/useFolder';
import HelpSystemContext from '../../context/HelpSystemContext';

// noinspection FunctionNamingConventionJS
function UserCreateComponent(props) {
    const history = useHistory();
    const theme = useTheme();

    const [user, setUser] = useState(props.user);
    const {folder: propsHomeFolder} = useFolder({params: {folderId: user.homeFolderId}});
    const [homeFolder, setHomeFolder] = useState(propsHomeFolder);
    const [showDisableEnableMessage, setShowDisableEnableMessage] = useState(false);


    // Set user when the props are loaded.
    useEffect(() => {
        setUser(props.user)
    }, [props.user]);

    // Set home folder when the folder is loaded.
    useEffect(() => {
        setHomeFolder(propsHomeFolder);
    }, [propsHomeFolder]);

    const handleSubmit = async function handleUpdateUser(event, data) {
        event.preventDefault();
        let response = await props.saveUser(data);
        setUser({...user, ...data, folderPermissions: response.data.folderPermissions});
    };

    const disableUser = async function disableUser(event) {
        try {
            await handleSubmit(event, {enabled: false});
            setUser({...user, enabled: false});
            setShowDisableEnableMessage(true);
        } catch (error) {
            console.error(error);
            toast.notify(
                ({onClose}) => <CustomAlert type='error' message='Could not disable this user!' onClose={onClose}/>);
        }
    };

    const enabledUser = async function enabledUser(event) {
        try {
            await handleSubmit(event, {enabled: true});
            setUser({...user, enabled: true});
            setShowDisableEnableMessage(true);
        } catch (error) {
            console.error(error);
            toast.notify(
                ({onClose}) => <CustomAlert type='error' message='Could not enable this user!' onClose={onClose}/>);
        }
    };

    return (
        <HelpSystemContext.Consumer>
            {() => <Box sx={{p: 3}}>
                <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                    <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.users.path)}>
                        <StyledBreadcrumbIcon theme={theme}/>
                        Back to users
                    </StyledBreadcrumbLink>
                </StyledBreadcrumbs>
                <Grid item sm={12}>
                    <ProgressFormCard
                        loadingContent={props.loadingUser}
                        header={<CustomCardHeader title={routes.updateUser.pageTitle}/>}
                        content={<>
                            <Grid container justifyContent='flex-start' sx={{mb: 1}}>
                                {!isEmptyObject(user) && user.enabled &&
                                    <CircularProgressButton mt={0} size='small'
                                                            label='Disable User'
                                                            buttonTextTransform='none'
                                                            onClick={disableUser}/>
                                }
                                {!isEmptyObject(user) && !user.enabled &&
                                    <CircularProgressButton
                                        style={{backgroundColor: red['800']}}
                                        mt={0}
                                        size='small' label='Enable User'
                                        buttonTextTransform='none'
                                        onClick={enabledUser}/>
                                }
                                <SuccessMessageComponent show={showDisableEnableMessage}
                                                         setShow={setShowDisableEnableMessage}
                                                         ml={2}/>
                            </Grid>
                            <UserEditForm id={user.id} username={user.username} notes={user.notes}
                                          homeFolder={homeFolder} folderPermissions={user.folderPermissions}
                                          publicKeys={user.publicKeys} allowedIps={user.allowedIps}
                                          uid={user.uid} gid={user.gid} ipAllowListEnabled={user.ipAllowListEnabled}
                                          isPasswordSet={user.isPasswordSet} usesPassword={user.usesPassword}
                                          lastLoginDate={user.lastLoginDate}
                                          updatePassword={handleSubmit} handleSubmit={handleSubmit}
                            />
                        </>
                        }
                    />
                </Grid>
            </Box>}
        </HelpSystemContext.Consumer>
    );
}

export default UserCreateComponent;
