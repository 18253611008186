import React from 'react';
import axios from 'axios';
import IdentityProviderCreateComponent from '../../component/settings/identityprovider/IdentityProviderCreateComponent';
import api_routes from '../../util/api_routes';

// noinspection FunctionNamingConventionJS
function IdentityProviderCreateContainer() {

    const save = async function createIdentityProvider(idp) {
        return await axios.post(api_routes.identityProvider.endpoint, idp);
    };

    return <IdentityProviderCreateComponent saveIdentityProvider={save} />;
}

IdentityProviderCreateContainer.propTypes = {};

IdentityProviderCreateContainer.defaultProps = {};

export default IdentityProviderCreateContainer;
