import React from 'react';
import toast from 'toasted-notes';
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme
} from '@mui/material';
import {ContentCopyRounded} from '@mui/icons-material';
import CustomAlert from '../../common/CustomAlert';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function IdentityProviderConfigInfo(props) {
    const theme = useTheme();

    const regPrefix = BASE_URL.startsWith("http") ? "" : window.location.origin;
    const values = [
        {
            label: 'Authorized JavaScript Origin',
            value: window.location.origin
        },
        {
            label: 'Authorized Redirect URI / Callback URL',
            value: (props.registrationId && `${regPrefix}${BASE_URL}/login/oauth2/code/${props.registrationId}`) ||
                <i>Provide registration id to view Redirect URI</i>
        },
        {
            label: 'Front-channel Logout URI / Sign out URL',
            value: window.location.origin
        },
    ]

    const copyValue = (label, value) => {
        navigator.clipboard.writeText(value).then(() => {
            toast.notify(({onClose}) => <CustomAlert type='success' message={`${label} copied!`} onClose={onClose}/>);
        });
    };

    return (
        <Box pt={2}>
            <Box sx={{backgroundColor: '#f7f8f9', width: 'fit-content'}} p={2}>
                <Box sx={{textAlign: 'center'}}>
                    <Typography variant={'h6'}>Fields for Identity Provider</Typography>
                    <Typography variant={'caption'} style={{color: theme.palette.text.secondary}}>
                        Provide these fields when appropriate during configuration of OIDC on the identity provider.
                    </Typography>
                </Box>

                <List>
                    {values.map(row =>
                        <ListItem key={row.label}>
                            <ListItemText primary={row.label}
                                          secondary={<Box component='span' display='flex' alignItems='center'
                                                          sx={{color: theme.palette.text.primary}}>
                                              {row.value}
                                              <IconButton size='small'
                                                          onClick={event => copyValue(row.label, row.value)}>
                                                  <ContentCopyRounded fontSize='small' color='primary'/>
                                              </IconButton></Box>}
                            />
                        </ListItem>
                    )}
                </List>
            </Box>
        </Box>
    );
}