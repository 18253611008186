import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import {Box, Typography, Switch, Grid, FormControlLabel, FormGroup} from '@mui/material';
import api_routes from '../../../util/api_routes';
import {isEmpty, isEmptyObject} from '../../../util/helpers';
import PageSection from '../../common/PageSection';
import InlineEditTable from '../../common/InlineEditTable';
import FormControlInput from '../../common/FormControlInput';
import CircularProgressButton from '../../common/CircularProgressButton';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import {convertErrorsToObject} from '../../../util/errorHandler';

function displayRow(data) {
    return [data.label, data.cidr];
}

function editRow({data, updateData, errors}) {
    return [<FormControlInput value={data.label} width='100' name='label' margin='none' removeBackground
                              errorMessage={errors.label}
                              placeholder='Add Allowlist Label' onChange={updateData} autoFocus/>,
        <FormControlInput value={data.cidr} width='100' name='cidr' margin='none' removeBackground
                          errorMessage={errors.cidr}
                          placeholder='Add CIDR IP xxx.xxx.xxx.xxx/xx' onChange={updateData}/>
    ];
}

function addRow(data, updateData, errors) {
    return [
        <FormControlInput value={data.label} width='100' name='label' margin='none'
                          removeBackground
                          errorMessage={errors.label}
                          placeholder='Add Allowlist Label' onChange={updateData} autoFocus/>,
        <FormControlInput value={data.cidr} width='100' name='cidr' margin='none'
                          removeBackground
                          errorMessage={errors.cidr}
                          placeholder='Add CIDR IP xxx.xxx.xxx.xxx/xx' onChange={updateData}/>
    ];
}

const initIpAllowListError = {
    ipAllowListEnabled: '',
};

// noinspection FunctionNamingConventionJS, FunctionWithMoreThanThreeNegationsJS
function IpAllowListTable(props) {
    const {userId} = props;
    const [ipAllowListEnabled, setIpAllowListEnabled] = useState(props.ipAllowListEnabled);
    const [ipAllowListError, setIpAllowListError] = useState(initIpAllowListError);
    const [showAddForm, setShowAddForm] = useState(false);
    const [allowedIps, setAllowedIps] = useState(props.allowedIps);
    const [showSavedMessage, setShowSavedMessage] = useState(false);

    const fieldRef = useRef(null);

    useEffect(() => {
        if ((!isEmpty(props.errorMessage)) && fieldRef.current) {
            fieldRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [props.errorMessage]);

    useEffect(() => {
        setIpAllowListEnabled(props.ipAllowListEnabled);
    }, [props.ipAllowListEnabled]);

    useEffect(() => {
        setAllowedIps(props.allowedIps);
    }, [props.allowedIps]);

    const validateIpAddress = function validateIpAddress(ipAddress) {
        let errors = {};
        if (isEmpty(ipAddress.label)) {
            errors.label = 'Label is required.'
        }
        if (isEmpty(ipAddress.cidr)) {
            errors.cidr = 'CIDR is required.'
        }
        if (!isEmptyObject(errors)) {
            throw errors;
        }
    }

    const removeIpAddress = async function handleDeleteIpAddressOnUserForm({index, itemToRemove: ipAddress}) {
        if (props.creatingUser) {
            await props.removeIpAddress(index);
        } else {
            await axios.delete(`${api_routes.user.endpoint}/${userId}${api_routes.allowedips.endpoint}/${ipAddress.id}`);
            const tempAllowedIps = allowedIps.filter(item => item.id !== ipAddress.id);
            setAllowedIps(tempAllowedIps);
            if (tempAllowedIps.length === 0) {
                setIpAllowListEnabled(false);
            }
            setShowSavedMessage(true);
        }
    };

    const updateIpAddress = async function handleIpAddressUpdate({index, itemToEdit: data}) {
        validateIpAddress(data);
        if (props.creatingUser) {
            setAllowedIps(allowedIps => {
                allowedIps[index] = data
                return allowedIps;
            });
        } else {
            let response = await axios.put(
                `${api_routes.user.endpoint}/${userId}${api_routes.allowedips.endpoint}/${data.id}`, data);
            let tempIpAddresses = _.map(allowedIps, function updateIpAddress(ip) {
                if (ip.id === data.id) {
                    return response.data;
                }
                return ip;
            });
            setAllowedIps(tempIpAddresses);
            setShowSavedMessage(true);
        }
    };

    const handleEnablingIpAddressAllowlist = async function handleEnablingIpAddressAllowlist(event, checked) {
        if (props.creatingUser) {
            props.setIpAllowListEnabled({name: 'ipAllowListEnabled', value: checked});
        } else {
            try {
                setIpAllowListEnabled(checked);
                await axios.put(`${api_routes.user.endpoint}/${userId}`,
                    {ipAllowListEnabled: checked});
                setIpAllowListError(initIpAllowListError);
            } catch (error) {
                setIpAllowListError(convertErrorsToObject(error.response));
            }
        }
    }

    const handleSaveIpAddress = async function handleSaveIpAddress(ipAddress) {
        validateIpAddress(ipAddress);
        if (props.creatingUser) {
            return await props.addIpAddress(ipAddress);
        } else {
            let response = await axios.post(
            `${api_routes.user.endpoint}/${userId}${api_routes.allowedips.endpoint}`, ipAddress);
            if (!isEmpty(ipAllowListError.ipAllowListEnabled)) {
                await handleEnablingIpAddressAllowlist(null, ipAllowListEnabled);
            }
            setAllowedIps(allowedIps => [...allowedIps, response.data]);
            setShowSavedMessage(true);
        }
    };

    // noinspection OverlyComplexBooleanExpressionJS, ConditionalExpressionJS
    return <>
        <PageSection title='IP Address Allowlist' titleVariant='body1'
                     subtitle='Connections from this user will be limited to the
                                     following IP addresses. If the table is blank, all IP addresses will be permitted.'
                     subtitleVariant='body2'
                     mt={4}
                     action={<Grid container alignItems='center'>
                         <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2} />
                         <CircularProgressButton variant='outlined' label='Add IP Address' buttonTextTransform='none'
                                                 mt={0} color='primary' disableElevation size='small'
                                                 onClick={() => setShowAddForm(true)}/>
                     </Grid>}
        />
        <FormGroup style={{marginBottom: '4px'}}>
            <FormControlLabel
                label={<Typography variant='body2'>Enable IP Allowlist</Typography>}
                control={<Switch
                    checked={ipAllowListEnabled}
                    onChange={handleEnablingIpAddressAllowlist}
                />}
            />
        </FormGroup>
        <InlineEditTable
            header={['Label', 'CIDR']}
            data={allowedIps}
            displayRow={displayRow}
            editRow={editRow}
            columnWidths={[40, 40, 20]}
            update={updateIpAddress}
            remove={removeIpAddress}
            showAddRow={showAddForm}
            setShowAddRow={setShowAddForm}
            addRow={addRow}
            itemToCreateInitState={{label: '', cidr: ''}}
            create={handleSaveIpAddress}
            deleteModalTitle='Delete IP Address'
            deleteModalField='label'
            disabled={!ipAllowListEnabled}
        />
        {(!isEmpty(props.errorMessage) || !isEmpty(ipAllowListError.ipAllowListEnabled)) &&
        <Box mt={1}>
            <Typography variant='caption' color='error'>
                {props.errorMessage || ipAllowListError.ipAllowListEnabled}
            </Typography>
        </Box>
        }
    </>
}

const IpAddress = PropTypes.shape({
    label: PropTypes.string,
    cidr: PropTypes.string,
});

IpAllowListTable.propTypes = {
    allowedIps: PropTypes.arrayOf(IpAddress),
    ipAllowListEnabled: PropTypes.bool,
    creatingUser: PropTypes.bool,
    errorMessage: PropTypes.string,
};

IpAllowListTable.defaultProps = {
    creatingUser: false,
};

export default IpAllowListTable;
