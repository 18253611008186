import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import routes from '../../util/routes';
import {KEY_USER_LOGIN_METHOD, VALUE_USER_LOGIN_METHOD_SLINK} from '../../container/auth/authenticationService';

// noinspection FunctionNamingConventionJS
function ConditionalChangePasswordRoute({component: Component, ...rest}) {

    return (<Route
        {...rest}
        render={(props) =>
            localStorage.getItem(KEY_USER_LOGIN_METHOD) === VALUE_USER_LOGIN_METHOD_SLINK ?
                <Component {...props} /> :
                <Redirect to={routes.users.path}/>
        }
    />);
}

ConditionalChangePasswordRoute.propTypes = {};

ConditionalChangePasswordRoute.defaultProps = {};

export default ConditionalChangePasswordRoute;
