import React from 'react';
import { Grid } from '@mui/material';
import HelpSystemContext from '../../../context/HelpSystemContext';
import InlineEditTextField from '../../common/InlineEditTextField';

// noinspection FunctionNamingConventionJS
function IdentityProviderEditForm(props) {

    return (<HelpSystemContext.Consumer>
        {open =>
            <form>
                <Grid container justifyContent='flex-start'>
                    <Grid container item>
                        <InlineEditTextField name='name' label='Identity Provider Display Name'
                                             value={props.name} required labelVariant='body1'
                                             save={(event, data) => props.handleSubmit(event, data)}
                                             updateMessage='Saved'
                        />
                    </Grid>
                    <Grid container item>
                        <InlineEditTextField name='notes' label='Notes' value={props.notes} labelVariant='body1'
                                             placeholder='Enter notes about this identity provider (optional)'
                                             save={(event, data) => props.handleSubmit(event, data)}
                                             multiline showCount inputProps={{ maxLength: 1000 }}
                                             updateMessage='Saved'
                        />
                    </Grid>
                    <Grid container item>
                        <InlineEditTextField name='issuerUri' label='Issuer URI'
                                             value={props.issuerUri} required labelVariant='body1'
                                             save={(event, data) => props.handleSubmit(event, data)}
                                             updateMessage='Saved'
                                             helperText={'Enter issuer URI.'}
                        />
                    </Grid>
                    <Grid container item>
                        <InlineEditTextField name='clientId' label='Client ID'
                                             value={props.clientId} required labelVariant='body1'
                                             save={(event, data) => props.handleSubmit(event, data)}
                                             updateMessage='Saved'
                                             helperText={'Enter the client ID from the IdP.'}
                        />
                    </Grid>
                    <Grid container item>
                        <InlineEditTextField name='clientSecret' label='Client Secret'
                                             value={props.clientSecret} required labelVariant='body1'
                                             save={(event, data) => props.handleSubmit(event, data)}
                                             updateMessage='Saved'
                                             helperText={'Enter the client secret from the IdP.'}
                        />
                    </Grid>
                    <Grid container item>
                        <InlineEditTextField name='nameAttribute' label='Name Attribute'
                                             value={props.nameAttribute} required labelVariant='body1'
                                             save={(event, data) => props.handleSubmit(event, data)}
                                             updateMessage='Saved'
                                             helperText={'Set the attribute name used to access the user\'s name from the user info response.'}
                        />
                    </Grid>
                    <Grid container item>
                        <InlineEditTextField name='registrationId' label='Registration Id'
                                             value={props.registrationId} required labelVariant='body1'
                                             save={(event, data) => props.handleSubmit(event, data)}
                                             updateMessage='Saved'
                                             helperText={'Set registration id.'}
                        />
                    </Grid>
                    <Grid container item>
                        <InlineEditTextField name='scopes' label='Scopes'
                                             value={props.scopes} required labelVariant='body1'
                                             save={(event, data) => props.handleSubmit(event, data)}
                                             updateMessage='Saved'
                                             helperText={'Set a list of extra OpenID Connect scopes separated by comma. The \'openid\' scope is always included.'}
                        />
                    </Grid>
                </Grid>
            </form>
        }
    </HelpSystemContext.Consumer>);
}

IdentityProviderEditForm.propTypes = {};

IdentityProviderEditForm.defaultProps = {};

export default IdentityProviderEditForm;
