import {useEffect, useState} from 'react';
import axios from 'axios';
import api_routes from '../util/api_routes';
import {isEmpty} from "../util/helpers";

const initCloudLogConnInfo = {
    accessKeyId: '',
    accessSecret: '',
    region: '',
    resourceId: '',
    applicationLogName: '',
    auditLogName: '',
    logLimit: 100, // Keep this as 100. It is the max and the default for Alibaba and a good starting point for other clouds.
}

function useCloudLogConnection() {

    const [cloudLogConnInfo, setCloudLogConnInfo] = useState(initCloudLogConnInfo);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async function fetchData() {
            try {
                const result = await axios.get(api_routes.cloudLogs.endpoint);

                let data = result.data;
                if (isEmpty(data.logLimit)) {
                    data.logLimit = initCloudLogConnInfo.logLimit;
                }

                setCloudLogConnInfo(data);
            } catch(error) {
                console.error(error);
            }
            setIsLoading(false);
        }

        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchData();

    }, []);

    const updateCloudLogConnectionInfo = async function updateCloudLogConnectionInfo(data){
        return axios.post(api_routes.cloudLogs.endpoint, data);
    }

    return {isLoading, cloudLogConnInfo, updateCloudLogConnectionInfo};
}

export default useCloudLogConnection;
