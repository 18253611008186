import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {Box, Grid, Tab, Tabs, Typography, useTheme} from '@mui/material';
import routes from '../../util/routes';
import api_routes from '../../util/api_routes';
import {HA, SINGLE} from '../../util/architecture';
import PageSection from '../common/PageSection';
import CircularProgressButton from '../common/CircularProgressButton';
import LogContainer from '../../container/diagnostics/LogContainer';
import CloudLogConnectionInfoComponent from './CloudLogConnectionInfoComponent';
import {downloadBackupArtifact} from '../settings/backup/SettingsBackupComponent';
import useAuditLogs from '../../api/useAuditLogs';
import useApplicationLogs from '../../api/useApplicationLogs';

function TabPanel(props) {
    const {children, value: tab, index, ...other} = props;
    return (
        <div
            role='tabpanel'
            hidden={tab !== index}
            id={`sftpgw-tab-panel-${index}`}
            aria-labelledby={`sftpgw-tab-${index}`}
            {...other}
        >
            {tab === index && (
                <Box sx={{paddingTop: 3}}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const StyledSystemInfoTable = styled.table`
  border-spacing: unset;
  width: 100%;

  & tr:nth-child(odd) {
    background-color: ${props => props.theme.palette.action.hover};
  }

  & tr:nth-child(1) {
    & th, & td {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  }

  & th, & td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  & th {
    text-align: left;
    font-weight: normal;
    text-transform: uppercase;
    padding: ${props => `${props.theme.spacing(.5)} ${props.theme.spacing(1)}`};
  }

  & td {
    padding: ${props =>
            `${props.theme.spacing(.5)} ${props.theme.spacing(1)} ${props.theme.spacing(.5)} ${props.theme.spacing(3)}`};
  }

  & td:last-child {
    text-align: right;
  }
`;

function a11yProps(index) {
    return {
        id: `sftpgw-tab-${index}`,
        'aria-controls': `sftpgw-tab-panel-${index}`,
    };
}

// noinspection FunctionNamingConventionJS
function DiagnosticsComponent() {
    const theme = useTheme();
    const [tab, setTab] = React.useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const systemInfo = useSelector(function getSystemInfo(state) {
        return state.systemInfo;
    });

    const displayHaStatus = (systemInfo) => {
        let status;
        if (systemInfo.architecture === HA) {
            status = 'Yes';
        } else if (systemInfo.architecture === SINGLE) {
            status = 'No';
        }
        return status;
    }

    const supportBackUpFile = () => {
        try {
            axios.get(`${api_routes.backup.endpoint}`,
                {params: {support: true}, responseType: 'blob'}
            ).then(
                response => downloadBackupArtifact(response)
            );
        } catch (error) {
            alert("Export failed due to: " + error);
            console.error(error);
        }

    }

    return (<Box sx={{p: 3}}>
        <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item>
                <Typography variant='h5'>{routes.diagnostics.pageTitle}</Typography>
            </Grid>
        </Grid>

        <PageSection title='System Information' mt={4}/>
        <Grid container>
            <Grid item>
                <StyledSystemInfoTable theme={theme}>
                    <tbody>
                    <tr>
                        <th>Version</th>
                        <td>{systemInfo.activeVersion}</td>
                    </tr>
                    <tr>
                        <th>Cloud Provider</th>
                        <td>{systemInfo.cloudProvider}</td>
                    </tr>
                    <tr>
                        <th>High Availability</th>
                        <td>{displayHaStatus(systemInfo)}</td>
                    </tr>
                    </tbody>
                </StyledSystemInfoTable>
            </Grid>
        </Grid>

        <Grid container sx={{ mt: 2 }}>
            <Grid container item md={12} xl={8}>
                <Typography variant='body2'>
                    When providing support we are more effective when we can replicate your setup. Support
                    Backup produces a backup file without any sensitive information like secrets and passwords. When
                    contacting support please provide this file.
                </Typography>
            </Grid>
            <Grid container item>
                <CircularProgressButton mt={1} size='small' label='Support Backup' buttonTextTransform='none'
                                        onClick={supportBackUpFile} fullWidth={false}/>
            </Grid>
        </Grid>

        <PageSection title='Logs' mt={5} pb={0}/>
        <Grid container direction='column'>
            <Grid item>
                <Tabs value={tab} onChange={handleChange} aria-label='diagnostics tabs'>
                    <Tab label='SFTP Activity Log' {...a11yProps(0)} />
                    <Tab label='Server Log' {...a11yProps(1)} />
                    <Tab label='Cloud Log Connection' {...a11yProps(2)} />
                </Tabs>
            </Grid>
            <Grid item>
                <TabPanel value={tab} index={0}>
                    <LogContainer useLogsEffect={useAuditLogs} title={'audit.log'}/>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <LogContainer useLogsEffect={useApplicationLogs} title={'application.log'}/>
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <CloudLogConnectionInfoComponent provider={systemInfo.cloudProvider}/>
                </TabPanel>
            </Grid>
        </Grid>

    </Box>
    );
}

DiagnosticsComponent.propTypes = {};

DiagnosticsComponent.defaultProps = {};

export default DiagnosticsComponent;
