import React, {useState} from 'react';
import UserListComponent from '../../component/user/UserListComponent';
import useUsers from '../../api/useUsers';
import {useDispatch} from "react-redux";
import {setUserSort} from "../../action";

// noinspection FunctionNamingConventionJS
function UserListContainer() {
    const [page, setPage] = useState(0);
    const [searchBox, setSearchBox] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [triggerRefresh, setRefresh] = useState(false);
    const dispatch = useDispatch();
    const {isLoading, userPages, userSort} = useUsers({
        params: {
            page: page,
            size: rowsPerPage,
            username: searchBox,
        },
        dependencies: {
            triggerRefresh
        }
    });

    const refresh = function refreshListOfUsers() {
        setRefresh(!triggerRefresh);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
    };

    const handleChangeSortBy = (event) => {
        dispatch(setUserSort({...userSort, by: event}))
    };

    const handleChangeSortOrder = () => {
        dispatch(setUserSort({
            ...userSort,
            order: (userSort.order === 'ASCENDING') ? 'DESCENDING' : 'ASCENDING'
        }))
    };

    const handleChangeSearch = (searchTerm) => {
        setPage(0);
        setSearchBox(searchTerm);

    };

    return <UserListComponent userPages={userPages} loadingUsers={isLoading} refresh={refresh}
                              searchBox={searchBox} handleChangeSearch={handleChangeSearch}
                              userSort={userSort} handleChangeSortBy={handleChangeSortBy}
                              handleChangeSortOrder={handleChangeSortOrder}
                              rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                              page={page} handleChangePage={handleChangePage}/>;
}

export default UserListContainer;
