import React from 'react';
import toast from 'toasted-notes';
import Moment from 'react-moment';
import folderPermissions from './folder_permissions_map';
import CustomAlert from '../component/common/CustomAlert';

function isEmpty(a) {
    return a === undefined || a === null || a.toString().trim() === '';
}

function isUndefined(a) {
    return a === undefined;
}

function isEmptyObject(a) {
    return a === undefined || a === null || Object.keys(a).length === 0
}

function truncateString(text, length) {
    let str = text;
    if (!isEmpty(text) && text.length > length + 3) {
        str = text.slice(0, length) + "...";
    }
    return str;
}

function truncateStringMiddle(text, length) {
    let str = text;
    if (!isEmpty(text) && text.length > length + 3) {
        str = text.slice(0, Math.floor(length / 2)) + "..." +
            text.slice(text.length - Math.floor(length / 2), text.length);
    }
    return str;
}

function getFolderPermissionString(key) {
    let value = '';
    if (!isEmpty(key)) {
        let lowerCaseKey = key.toLowerCase();
        value = folderPermissions[lowerCaseKey];
    }
    return value;
}

const copyTextToClipboard = (event, text) => {
    event.stopPropagation()
    navigator.clipboard.writeText(text).then(() => {
        toast.notify(({onClose}) => <CustomAlert type='success' message='Text copied!' onClose={onClose}/>);
    });
};

const consolidatePasswordErrorMessage = (error) => {
    if (!isEmpty(error) && typeof error === "string") {

        const criteria = error.split('. ');

        const specificCriteria = criteria.map(criterion => {
            if (criterion.includes('uppercase')) {
                return 'uppercase';
            } else if (criterion.includes('digit')) {
                return 'numeric';
            } else if (criterion.includes('special characters')) {
                return 'special';
            } else if (criterion.includes('lowercase')) {
                return 'lowercase';
            }
            return null;
        }).filter(Boolean);

        const lengthCriteria = criteria.map(criterion => {
            if (criterion.includes('8 or more characters')) {
                return 'be at least 8 characters in length';
            }
            return null;
        }).filter(Boolean);

        if (specificCriteria.length === 0 && lengthCriteria.length === 0) {
            return error;
        }

        let msgPart1 = 'Password must';
        let msgPart2 = specificCriteria.length > 0 ? 'contain at least 1 or more '.concat(specificCriteria.join(', ')).concat(' characters') : '';
        let msgPart3 = lengthCriteria.length > 0 ? ' be at least 8 characters in length' : '';

        return `${msgPart1} ${msgPart2}${!isEmpty(msgPart2) && !isEmpty(msgPart3) ? ' and' : ''}${msgPart3}.`;
    }

    return error;
}

const displayMomentDate = ({
                               date,
                               shortFormat = 'MMM D, YYYY',
                               longFormat = 'MMM D, YYYY h:mm a',
                               displayShortFormat = false,
                               emptyDateText = '-'
}) => {
    if (isEmpty(date)) {
        return emptyDateText;
    }
    if (displayShortFormat) {
        return <Moment format={shortFormat}>{date}</Moment>;
    }
    return <Moment format={longFormat}>{date}</Moment>
}

export { isEmpty, isEmptyObject, truncateString, getFolderPermissionString, truncateStringMiddle, isUndefined,
    copyTextToClipboard, consolidatePasswordErrorMessage, displayMomentDate };
