import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTheme, Box, RadioGroup, Radio, FormControl, FormControlLabel, Link} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {isEmpty} from "../../util/helpers";
import {OpenInNew} from "@mui/icons-material";

const useStyles = makeStyles({
    label: {
        fontSize: '.875rem',
        width: '100%',
    }
});

const StyledLink = styled(Link)`
    font-size: .875rem
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-top: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(1)};
`;

// noinspection FunctionNamingConventionJS
function FormControlRadioGroup(props) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <StyledFormControl component='fieldset' theme={theme}>
            <RadioGroup name={props.name} value={props.value} onChange={props.onChange}>
                {props.options.map((option, idx) => !option.hidden &&
                    <Box key={idx}>
                        <FormControlLabel classes={{label: classes.label}} value={option.value} label={option.label}
                                          control={<Radio size='small' checked={option.value === props.value}/>}
                                          style={option.style} disabled={option.disabled}/>
                        {!isEmpty(option.helpLink) &&
                            <StyledLink
                                href={option.helpLink?.href}
                                target={option.helpLink?.target}
                                rel='noopener'
                            >
                                {option.helpLink?.target === '_blank' &&
                                    <OpenInNew fontSize='.875rem' style={{verticalAlign: 'sub', marginRight: '4px'}}/>}
                                {option.helpLink?.text}
                            </StyledLink>
                        }
                        {option.subcomponent}
                    </Box>
                )}
            </RadioGroup>
        </StyledFormControl>
    );
}

FormControlRadioGroup.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.array,
    helpLink: PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
};

FormControlRadioGroup.defaultProps = {
};

export default FormControlRadioGroup;
