import React from 'react';
import axios from 'axios';
import {useParams} from 'react-router';
import useIdentityProvider from '../../api/useIdentityProvider.js';
import IdentityProviderUpdateComponent from '../../component/settings/identityprovider/IdentityProviderUpdateComponent';
import api_routes from '../../util/api_routes';

// noinspection FunctionNamingConventionJS
function IdentityProviderUpdateContainer() {

    const {identityProviderId} = useParams();
    const {isLoading, identityProvider} = useIdentityProvider({ params: { identityProviderId } });

    const save = async function updateIdentityProvider(idp) {
        return await axios.put(`${api_routes.identityProvider.endpoint}/${identityProviderId}`, idp);
    }

    return <IdentityProviderUpdateComponent
        identityProvider={identityProvider}
        loadingIdentityProvider={isLoading}
        updateIdentityProvider={save}
    />;
}

IdentityProviderUpdateContainer.propTypes = {};

IdentityProviderUpdateContainer.defaultProps = {};

export default IdentityProviderUpdateContainer;
