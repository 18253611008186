import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import CustomCardHeader from '../common/CustomCardHeader';
import FormControlInput from '../common/FormControlInput';
import ProgressFormCard from '../common/ProgressFormCard';
import CircularProgressButton from '../common/CircularProgressButton';
import SuccessMessageComponent from '../common/SuccessMessageComponent';
import useCloudLogConnection from '../../api/useCloudLogConnection';
import {ALIBABA, AWS, AZURE, GCP} from '../../util/cloud_providers';

const formText = {
    [AZURE]: {
        resourceId: {
            label: 'Workspace ID',
            helperText: 'Enter Workspace ID from Log Analytics.',
            placeholder: 'Enter a Workspace ID',
        },
        applicationLogName: {
            label: 'Application Log Table',
            helperText: 'Application logs table name.',
            placeholder: 'ApplicationLog_CL',
        },
        auditLogName: {
            label: 'Audit Log Table',
            helperText: 'Audit logs table name.',
            placeholder: 'AuditLog_CL',
        }
    },
    [AWS]: {
        resourceId: {
            label: 'Group Name',
            helperText: 'Enter a group name from Cloud Watch.',
            placeholder: 'Enter a group name',
        }
    },
    [GCP]: {
        applicationLogName: {
            label: 'Application Log Name',
            helperText: 'Provide the log name for application logs.',
            placeholder: 'Enter application log name',
        },
        auditLogName: {
            label: 'Audit Log Name',
            helperText: 'Provide the log name for audit logs.',
            placeholder: 'Enter audit log name',
        }
    },
    [ALIBABA]: {
        accessKeyId: {
            label: 'Access Key Id',
            helperText: 'Enter access key id.',
            placeholder: '',
        },
        accessSecret: {
            label: 'Access Secret',
            helperText: 'Enter access secret.',
            placeholder: '**********',
        },
        region: {
            label: 'Region',
            helperText: 'Enter region.',
            placeholder: 'cn-shanghai',
        },
        resourceId: {
            label: 'Project Name',
            helperText: 'Enter project name from Log Service.',
            placeholder: 'sftp-gateway-logs',
        },
        applicationLogName: {
            label: 'Application Logstore',
            helperText: 'Application Logstore name.',
            placeholder: 'application-logstore'
        },
        auditLogName: {
            label: 'Audit Logstore',
            helperText: 'Enter Audit Logstore name.',
            placeholder: 'audit-logstore'
        }
    }

}

// noinspection FunctionNamingConventionJS
function CloudLogConnectionInfoComponent(props) {

    const {isLoading, cloudLogConnInfo, updateCloudLogConnectionInfo} = useCloudLogConnection();

    const [cloudLogConnInfoState, setCloudLogConn] = useState(cloudLogConnInfo);
    const [submitting, setSubmitting] = useState(false);
    const [showSavedMessage, setShowSavedMessage] = useState(false);

    useEffect(() => {
        setCloudLogConn(cloudLogConnInfo);
    }, [cloudLogConnInfo]);

    const handleChange = (event) => {
        setCloudLogConn({...cloudLogConnInfoState, [event.target.name]: event.target.value});
    }

    const handleSubmit = async function handleUpdateCloudLogInformation(event) {
        event.preventDefault();
        setSubmitting(true);
        try {
            await updateCloudLogConnectionInfo(cloudLogConnInfoState);
            setShowSavedMessage(true);
        } catch (error) {

        }
        setSubmitting(false);
    };

    return (
        <ProgressFormCard loadingContent={isLoading}
                          header={
                              <CustomCardHeader
                                  title={'Cloud Log Connection Info'}
                                  titleVariant={'h6'}
                                  style={{padding: '0px'}}
                                  action={<Grid container justifyContent='center'>
                                      <SuccessMessageComponent show={showSavedMessage}
                                                               setShow={setShowSavedMessage} mr={2}/>
                                  </Grid>}
                              />
                          }
                          content={
                              <form onSubmit={handleSubmit}>
                                  {props.provider === ALIBABA &&
                                      <Grid container item md={12} xl={8}>
                                          <FormControlInput value={cloudLogConnInfoState.accessKeyId}
                                                            width='100' required
                                                            label={formText[props.provider].accessKeyId.label}
                                                            name='accessKeyId'
                                                            placeholder={formText[props.provider].accessKeyId.placeholder}
                                                            onChange={handleChange}
                                                            helperText={formText[props.provider].accessKeyId.helperText}
                                          />
                                      </Grid>
                                  }
                                  {props.provider === ALIBABA &&
                                      <Grid container item md={12} xl={8}>
                                          <FormControlInput value={cloudLogConnInfoState.accessSecret}
                                                            width='100' required autoComplete={'off'}
                                                            label={formText[props.provider].accessSecret.label}
                                                            name='accessSecret'
                                                            placeholder={formText[props.provider].accessSecret.placeholder}
                                                            onChange={handleChange}
                                                            helperText={formText[props.provider].accessSecret.helperText}
                                          />
                                      </Grid>
                                  }
                                  {props.provider === ALIBABA &&
                                      <Grid container item md={12} xl={8}>
                                          <FormControlInput value={cloudLogConnInfoState.region}
                                                            width='100' required
                                                            label={formText[props.provider].region.label}
                                                            name='region'
                                                            placeholder={formText[props.provider].region.placeholder}
                                                            onChange={handleChange}
                                                            helperText={formText[props.provider].region.helperText}
                                          />
                                      </Grid>
                                  }
                                  {(props.provider === AZURE || props.provider === AWS || props.provider === ALIBABA) &&
                                      <Grid container item md={12} xl={8}>
                                          <FormControlInput value={cloudLogConnInfoState.resourceId}
                                                            width='100'
                                                            label={formText[props.provider].resourceId.label}
                                                            name='resourceId'
                                                            placeholder={formText[props.provider].resourceId.placeholder}
                                                            onChange={handleChange}
                                                            helperText={formText[props.provider].resourceId.helperText}
                                          />
                                      </Grid>
                                  }
                                  {(props.provider === AZURE || props.provider === ALIBABA) &&
                                      <Grid container item md={12} xl={8}>
                                          <FormControlInput value={cloudLogConnInfoState.applicationLogName}
                                                            width='100'
                                                            label={formText[props.provider].applicationLogName.label}
                                                            name='applicationLogName'
                                                            placeholder={formText[props.provider].applicationLogName.placeholder}
                                                            onChange={handleChange}
                                                            helperText={formText[props.provider].applicationLogName.helperText}
                                          />
                                      </Grid>
                                  }
                                  {(props.provider === AZURE || props.provider === ALIBABA) &&
                                      <Grid container item md={12} xl={8}>
                                          <FormControlInput value={cloudLogConnInfoState.auditLogName}
                                                            width='100'
                                                            label={formText[props.provider].auditLogName.label}
                                                            name='auditLogName'
                                                            placeholder={formText[props.provider].auditLogName.placeholder}
                                                            onChange={handleChange}
                                                            helperText={formText[props.provider].auditLogName.helperText}
                                          />
                                      </Grid>
                                  }
                                  <Grid container item md={12} xl={8}>
                                      <FormControlInput type='number'
                                                        value={cloudLogConnInfoState.logLimit}
                                                        width='50'
                                                        label='Log Limit'
                                                        name='logLimit'
                                                        placeholder='100'
                                                        onChange={handleChange}
                                                        required
                                                        disabled={props.provider === ALIBABA}
                                                        helperText='Number of log entries to view at a time.'
                                      />
                                  </Grid>
                                  <Grid container justifyContent='flex-start'>
                                      <CircularProgressButton type='submit' size='small' label='Save'
                                                              mt={2} mr={1} inProgress={submitting}/>
                                  </Grid>
                              </form>
                          }
        />
    );
}

CloudLogConnectionInfoComponent.propTypes = {};

CloudLogConnectionInfoComponent.defaultProps = {};

export default CloudLogConnectionInfoComponent;
