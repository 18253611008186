import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import toast from 'toasted-notes';
import api_routes from '../util/api_routes';
import {handleErrors} from '../util/errorHandler';
import {isEmpty} from '../util/helpers';
import httpStatus from '../util/http_status';
import {authenticationService} from '../container/auth/authenticationService';
import CustomAlert from '../component/common/CustomAlert';
import {setAuthenticated} from '../action';

const initParams = {
    // User id to fetch by
    userId: 0,
    // If true, additional call will be made to fetch user's home folder
    includeHomeFolder: false,
};

const initUser = {};

const initHomeFolder = {};

function useUser({params = initParams}) {

    const dispatch = useDispatch();

    const {userId, includeHomeFolder} = params;

    const [user, setUser] = useState(initUser);
    const [homeFolder, setHomeFolder] = useState(initHomeFolder);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadUser() {
        const fetchUserData = async function fetchUserData() {
            try {
                // Fetch user by user id
                const userResponse = await axios.get(`${api_routes.user.endpoint}/${userId}`);
                setUser(userResponse.data);

                // Fetch home folder if includeHomeFolder is set to true
                if (includeHomeFolder) {
                    const homeFolderId = userResponse.data.homeFolderId;
                    const homeFolderResponse = await axios.get(`${api_routes.folders.endpoint}/${homeFolderId}`);
                    setHomeFolder(homeFolderResponse.data);
                }
                setIsLoading(false);
            } catch (error) {
                const tempErrors = handleErrors(error.response);
                toast.notify(({onClose}) =>
                    <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
                // Let the app know that this user is no longer authenticated (this will trigger the login page)
                if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
                    authenticationService.clearTokenInfo();
                    dispatch(setAuthenticated(false));
                }
            }
        };
        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchUserData();
    }, [userId, includeHomeFolder, dispatch]);

    return {isLoading, user, homeFolder};
}

export default useUser;
