import React from 'react';
import PageSection from "../../common/PageSection";

// noinspection FunctionNamingConventionJS
function UidAndGidHeader(props) {
    return (
        <PageSection title='Object Ownership' titleVariant='body1'
                     subtitle='Use these fields to override numeric user ID (UID) and group ID (GID) to define the
                     object ownership for this user.'
                     subtitleVariant='body2' pb={0} mt={4}/>
    );
}

UidAndGidHeader.propTypes = {};

UidAndGidHeader.defaultProps = {};

export default UidAndGidHeader;
