import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {red} from '@mui/material/colors';
import {Grid, Box, useTheme} from '@mui/material';
import routes from '../../../util/routes';
import {isEmptyObject} from '../../../util/helpers';
import CustomAlert from '../../common/CustomAlert';
import ProgressFormCard from '../../common/ProgressFormCard';
import CustomCardHeader from '../../common/CustomCardHeader';
import CircularProgressButton from '../../common/CircularProgressButton';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../../common/styled';
import IdentityProviderEditForm from './IdentityProviderEditForm';
import HelpSystemContext from '../../../context/HelpSystemContext';
import {IdentityProviderConfigInfo} from './IdentityProviderConfigInfo';

// noinspection FunctionNamingConventionJS
function IdentityProviderUpdateComponent(props) {
    const history = useHistory();
    const theme = useTheme();

    const [identityProvider, setIdentityProvider] = useState(props.identityProvider);
    const [showDisableEnableMessage, setShowDisableEnableMessage] = useState(false);

    // Update the state from updated props.
    useEffect(() => {
        setIdentityProvider(props.identityProvider);
    }, [props.identityProvider]);

    const onSubmit = async function updateIdentityProvider(event, data) {
        event.preventDefault();
        await props.updateIdentityProvider(data);
    }

    const disableIdp = async function disableIdp(event) {
        try {
            await onSubmit(event, {enabled: false});
            setIdentityProvider({...identityProvider, enabled: false});
            setShowDisableEnableMessage(true);
        } catch (error) {
            console.error(error);
            toast.notify(
                ({onClose}) => <CustomAlert type='error' message='Could not disable this identity provider!'
                                            onClose={onClose}/>);
        }
    }

    const enableIdp = async function disableIdp(event) {
        try {
            await onSubmit(event, {enabled: true});
            setIdentityProvider({...identityProvider, enabled: true});
            setShowDisableEnableMessage(true);
        } catch (error) {
            console.error(error);
            toast.notify(
                ({onClose}) => <CustomAlert type='error' message='Could not enable this identity provider!'
                                            onClose={onClose}/>);
        }
    }

    return (
        <HelpSystemContext.Consumer>
            {open => <Box sx={{p: 3}}>
                <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                    <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.settings.path)}>
                        <StyledBreadcrumbIcon theme={theme}/>
                        Back to settings
                    </StyledBreadcrumbLink>
                </StyledBreadcrumbs>
                <Grid container>
                    <Grid item sm={12} lg={open ? 8 : 6}>
                        <ProgressFormCard
                            header={<CustomCardHeader title={routes.updateIdentityProvider.pageTitle}/>}
                            content={<>
                                <Grid container justifyContent='flex-start' sx={{mb: 1}}>
                                    {!isEmptyObject(identityProvider) && !identityProvider.enabled &&
                                        <CircularProgressButton mt={0} size='small'
                                                                label='Enable IdP'
                                                                style={{backgroundColor: red['800']}}
                                                                buttonTextTransform='none'
                                                                onClick={enableIdp}/>
                                    }
                                    {!isEmptyObject(identityProvider) && identityProvider.enabled &&
                                        <CircularProgressButton mt={0} size='small'
                                                                label='Disable IdP'
                                                                buttonTextTransform='none'
                                                                onClick={disableIdp}/>
                                    }
                                    <SuccessMessageComponent show={showDisableEnableMessage}
                                                             setShow={setShowDisableEnableMessage}
                                                             ml={2}/>
                                </Grid>
                                <IdentityProviderEditForm handleSubmit={onSubmit}
                                                          name={identityProvider.name}
                                                          notes={identityProvider.notes}
                                                          issuerUri={identityProvider.issuerUri}
                                                          clientId={identityProvider.clientId}
                                                          clientSecret={identityProvider.clientSecret}
                                                          nameAttribute={identityProvider.nameAttribute}
                                                          registrationId={identityProvider.registrationId}
                                                          scopes={identityProvider.scopes}/>
                            </>
                            }
                        />
                    </Grid>
                    <Grid item sm={12} lg={open ? 4 : 6}>
                        <IdentityProviderConfigInfo registrationId={identityProvider.registrationId}/>
                    </Grid>
                </Grid>
            </Box>}
        </HelpSystemContext.Consumer>
    )
}

IdentityProviderUpdateComponent.propTypes = {
    identityProvider: PropTypes.object,
};

IdentityProviderUpdateComponent.defaultProps = {};

export default IdentityProviderUpdateComponent;
