import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import toast from 'toasted-notes';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import { MenuItem, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CustomAlert from './CustomAlert';
import FormControlSelect from './FormControlSelect';
import SuccessMessageComponent from './SuccessMessageComponent';
import {isEmpty} from '../../util/helpers';

// noinspection MagicNumberJS
const useStyles = makeStyles((theme) => ({
    editRoot: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
        },
    },
    iconMarginLeft: {
        marginLeft: theme.spacing(0.5)
    },
    emptyText: {
        color: theme.palette.action.active,
    },
    iconColor: {
        color: theme.palette.primary.main,
    }
}));

// noinspection FunctionNamingConventionJS
function InlineEditSelectField({
                                   name, value, emptyText, defaultValue, label, labelVariant,
                                   helperText, save, updateMessage, options, width,
                               }) {

    const classes = useStyles();
    const [editing, setEditing] = useState(false);
    const [valueToEdit, setValueToEdit] = useState(value);
    const [oldValue, setOldValue] = useState(value);
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [event, setEvent] = useState(null);

    const submit = useCallback(async function selectInlineEditingSubmit(event) {
        event.preventDefault();
        // Do not update if the value didn't change.
        if (oldValue === valueToEdit) {
            setEditing(false);
        } else {
            try {
                let data = {};
                data[name] = valueToEdit;
                await save(event, data);
                setEditing(false);
                setOldValue(valueToEdit);
                setShowSavedMessage(true);
            } catch (error) {
                if (isEmpty(error.response)) {
                    toast.notify(({onClose}) => <CustomAlert type='error'
                                                             message={'Something went wrong. update failed.'}
                                                             onClose={onClose}/>);
                }
            }
        }
    }, [name, oldValue, save, valueToEdit]);

    const onBlur = function inlineEditingOnBlur(event) {
        submit(event);
    };

    const handleKeyDown = function inlineEditingKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            submit(event);
        }
    };

    const displayValueWhenNotEditing = function displayValueWhenNotEditing() {
        return options.filter(option => option.value.toString() === valueToEdit.toString())[0]?.displayValue;
    }

    const handleChange = function handleSelectInlineEditFieldChange(event) {
        setValueToEdit(event.target.value);
        setEvent(event);
    }

    useEffect(() => {
        if (!isEmpty(event)) {
            submit(event);
        }
    }, [valueToEdit, event, submit]);

    let component;

    if (editing) {
        component = <>
            <FormControlSelect name={name} width={width} type='select' defaultValue={defaultValue} label={label}
                               value={valueToEdit} onChange={handleChange}
                               helperText={helperText} margin='none' onKeyDown={handleKeyDown}
                               onBlur={onBlur}
            >
                {options.length === 0 && <MenuItem disabled value=''>None</MenuItem>}
                {options.map((option) =>
                    <MenuItem key={option.key}
                              value={option.value}>{option.displayValue}</MenuItem>
                )}
            </FormControlSelect>
        </>
    } else {
        component = <>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography gutterBottom variant={labelVariant}>{label}</Typography>
                <SuccessMessageComponent mb={1} ml={2} message={updateMessage} show={showSavedMessage}
                                         setShow={setShowSavedMessage} />
            </div>
            <Typography variant='body2' onClick={() => setEditing(true)}>
                {!isEmpty(valueToEdit) && displayValueWhenNotEditing()}
                {isEmpty(valueToEdit) && <span className={classes.emptyText}>{emptyText}</span>}
                <IconButton aria-label='Edit' size='small'
                            onClick={() => setEditing(true)} className={classes.iconMarginLeft}>
                    <CreateSharpIcon fontSize='small' className={classes.iconColor}/>
                </IconButton>
            </Typography>
        </>
    }

    return component;
}

const Option = PropTypes.shape({
    key: PropTypes.any,
    value: PropTypes.any,
    displayValue: PropTypes.any,
});

InlineEditSelectField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    save: PropTypes.func.isRequired,
    emptyText: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.any,
    helperText: PropTypes.string,
    labelVariant: PropTypes.string,
    updateMessage: PropTypes.string,
    options: PropTypes.arrayOf(Option),
    width: PropTypes.string,
};

InlineEditSelectField.defaultProps = {
    emptyText: 'None',
    defaultValue: '',
    labelVariant: 'caption',
    options: [],
};

export default InlineEditSelectField;
