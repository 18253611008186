import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import toast from 'toasted-notes';
import { Grid, Link, Typography, useTheme } from '@mui/material';
import CustomAlert from '../../common/CustomAlert';
import PageSection from '../../common/PageSection';
import FormControlRadioGroup from '../../common/FormControlRadioGroup';
import CircularProgressButton from '../../common/CircularProgressButton';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import api_routes from '../../../util/api_routes';
import { convertErrorsToObject } from '../../../util/errorHandler';
import { isEmpty } from "../../../util/helpers";
import PasswordField from "../../common/PasswordField";

// noinspection FunctionNamingConventionJS
function PasswordSelector(props) {
    const theme = useTheme();
    const {handleChange} = props;
    // noinspection ConditionalExpressionJS
    const [sftpPassOpt, setSftpPassOpt] = useState((props.usesPassword && props.isPasswordSet) ? 'keep' : 'no');
    const [password, setPassword] = useState('');
    // This value is used to track the change in the password and gets updated when 'Update Password' button is clicked.
    const [initPassword, setInitPassword] = useState('');
    const [errors, setErrors] = useState(isEmpty(props.errors) ? {} : props.errors);
    const [showSavedMessage, setShowSavedMessage] = useState(false);


    useEffect(() => {
        if (!isEmpty(props.errors)) {
            setErrors(props.errors);
        }
    }, [props.errors]);


    const generatePassword = async function generatePassword() {
        try {
            let response = await axios.get(api_routes.generatePassword.endpoint);
            setPassword(response.data);
            if (props.creatingUser) {
                let event = { target: { name: 'password', value: response.data } };
                handleChange(event);
            }
        } catch (error) {
            console.error(error);
            toast.notify(({onClose}) =>
                <CustomAlert type='error' message={'Could not generate password!'} onClose={onClose}/>);
        }
    };

    const updatePasswordUsage = async function updatePasswordUsageOnUserEditPage(event) {
        try {
            setSftpPassOpt(event.target.value);
            switch (event.target.value) {
                case 'no':
                    await handleChange(event, {usesPassword: false});
                    break;
                case 'keep':
                    await handleChange(event, {usesPassword: true});
                    break;
                default:
                    break;
            }
            if (!props.creatingUser) {
                setShowSavedMessage(true);
            }
        } catch (error) {
            console.error(error);
            toast.notify(({onClose}) =>
                <CustomAlert type='error' message={'Could not update password usage!'} onClose={onClose}/>);
        }
    };

    const updatePassword = async function updatePassword(event) {
        try {
            await handleChange(event, {password: password, usesPassword: true});
            setShowSavedMessage(true);
            setErrors({});
            setInitPassword(password);
        } catch (error) {
            let tempErrors = convertErrorsToObject(error.response);
            setErrors(tempErrors);
        }
    };

    return <>
        <PageSection title='SFTP Password' titleVariant='body1' pb={0} mt={2}
                     subtitle='Users can use password to log into SFTP Gateway. This is not a
                                     recommended configuration and is disabled by default.' subtitleVariant='body2'
                     action={<SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>} />
        <FormControlRadioGroup name='sftpPasswordOption' value={sftpPassOpt}
                               onChange={updatePasswordUsage}
                               options={[{
                                   value: 'no', label: 'Cannot use password login'
                               }, {
                                   value: 'keep',
                                   label: 'Use Current Password',
                                   hidden: !props.isPasswordSet
                               }, {
                                   value: 'new',
                                   label: <Grid container style={{marginTop: '9px'}}>
                                       <Grid item xs={8}><Typography variant='body2'>New Password</Typography></Grid>
                                       <Grid item xs={8}>
                                           <Typography variant='caption' color='textSecondary'>Generate a password
                                               or provide your own. The password cannot be
                                               viewed again once the user is saved.</Typography>
                                       </Grid>
                                       {sftpPassOpt === 'new' && <>
                                           <Grid item xs={8}>
                                               <PasswordField width='100' required name='password'
                                                              autoComplete={"new-password"}
                                                              placeholder='Enter SFTP password' label='SFTP Password'
                                                              showPasswordRequirements
                                                              showPasswordStrengthMeter
                                                              password={password}
                                                              handleChange={(event) => {
                                                                   if (props.creatingUser) {
                                                                       setPassword(event.target.value);
                                                                       handleChange(event);
                                                                   } else {
                                                                       setPassword(event.target.value);
                                                                   }
                                                               }}
                                                              onKeyDown={(event) => {
                                                                  if (event.key === 'Enter') {
                                                                      event.preventDefault();
                                                                  }
                                                              }}
                                                              errorMessage={errors.password}/>
                                               <Link variant='body2' onClick={generatePassword}>Suggest password</Link>
                                           </Grid>
                                           {!props.creatingUser &&
                                           <Grid item xs={3}
                                                 style={{marginTop: theme.spacing(3), marginLeft: theme.spacing(1)}}>
                                               <CircularProgressButton size='small' color='success'
                                                                       disableElevation buttonTextTransform='none'
                                                                       fullWidth={false} variant='outlined'
                                                                       onClick={updatePassword} disabled={initPassword === password}
                                                                       label='Update Password'/>
                                           </Grid>
                                           }
                                       </>
                                       }
                                   </Grid>,
                                   style: {alignItems: 'flex-start'}
                               }]}
        />
    </>;
}

PasswordSelector.propTypes = {
    user: PropTypes.string,
    handleChange: PropTypes.func,
    classes: PropTypes.object,
    fieldStyle: PropTypes.object,
    creatingUser: PropTypes.bool,
};

PasswordSelector.defaultProps = {
    creatingUser: false
};

export default PasswordSelector;
