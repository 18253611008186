import React, {useState} from 'react';
import {Grid} from '@mui/material';
import PageSection from '../../common/PageSection';
import InlineEditTextField from '../../common/InlineEditTextField';
import FormControlRadioGroup from '../../common/FormControlRadioGroup';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import HelpSystemContext from '../../../context/HelpSystemContext';
import TestConnectionResultTable from './TestConnectionResultTable';
import {isEmpty} from '../../../util/helpers';
import identityOptions from './InstanceProfileOption';

// noinspection FunctionNamingConventionJS
function AlibabaCloudConnectionEditForm(props) {

    const [showEncryptionTypeSavedMessage, setShowEncryptionTypeSavedMessage] = useState(false);
    const [showCredentialsSavedMessage, setShowCredentialsSavedMessage] = useState(false);

    const handleEncryptionTypeChange = async (event) => {
        await props.handleSubmit(event, {encryptionType: event.target.value});
        setShowEncryptionTypeSavedMessage(true);
    }

    const handleInstanceCredentialsTypeChange = async (event) => {
        props.setInstanceCredentials(event);
        await props.handleSubmit(event, {useInstanceCredentials: event.target.value});
        setShowCredentialsSavedMessage(true);
    }

    return (<HelpSystemContext.Consumer>
        {() => <form>
            <Grid container justifyContent='flex-start'>
                <Grid container item>
                    <InlineEditTextField name='name' label='Connection Name' labelVariant='body1'
                                         value={props.cloudConnection.name}
                                         save={(event, data) => props.handleSubmit(event, data)}
                                         helperText='Name used to identify this cloud connection.'
                                         placeholder='Enter unique name' required
                                         updateMessage='Saved' errorMessage={props.errors.name}/>
                </Grid>
                <Grid container item>
                    <InlineEditTextField name='notes' label='Cloud Connection Notes' labelVariant='body1'
                                         value={props.cloudConnection.notes}
                                         save={(event, data) => props.handleSubmit(event, data)}
                                         placeholder='Enter notes about the cloud connection (optional)'
                                         multiline showCount inputProps={{maxLength: 1000}}
                                         updateMessage='Saved' errorMessage={props.errors.notes}/>
                </Grid>
                <Grid container item>
                    <InlineEditTextField name='basePrefix' label='OSS URL' labelVariant='body1'
                                         value={props.cloudConnection.basePrefix}
                                         save={(event, data) => props.handleSubmit(event, data)} required
                                         autoComplete='off' placeholder='Enter URL for OSS bucket'
                                         helperText="Ex: oss://bucket-name/key-prefix"
                                         errorMessage={props.errors.basePrefix} updateMessage='Saved' copyField/>
                </Grid>
                <Grid container item>
                    <InlineEditTextField name='region' label='Region' labelVariant='body1'
                                         value={props.cloudConnection.region}
                                         save={(event, data) => props.handleSubmit(event, data)} autoComplete='off'
                                         placeholder='Enter a bucket region or leave blank for default'
                                         helperText="Ex: us-east-1"
                                         errorMessage={props.errors.region} updateMessage='Saved'/>
                </Grid>
                <Grid container item>
                    <PageSection title='OSS Encryption Type' titleVariant='body1'
                                 subtitle='Uploaded objects will be encrypted to this level.'
                                 subtitleVariant='body2' pb={0} mt={3}
                                 action={
                                     <Grid container justifyContent='center'>
                                         <SuccessMessageComponent show={showEncryptionTypeSavedMessage}
                                                                  setShow={setShowEncryptionTypeSavedMessage} mr={2}/>
                                     </Grid>
                                 }
                    />
                    <FormControlRadioGroup name='encryptionType' value={props.cloudConnection.encryptionType}
                                           onChange={handleEncryptionTypeChange} defaultValue='OSSManaged'
                                           options={[{
                                               value: 'OSSManaged', label: 'OSS Managed'
                                           }, {
                                               value: 'NONE', label: 'No Encryption'
                                           }]}
                    />
                </Grid>
                <Grid container item>
                    <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                                 subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'
                                 action={
                                     <Grid container justifyContent='center'>
                                         <SuccessMessageComponent show={showCredentialsSavedMessage}
                                                                  setShow={setShowCredentialsSavedMessage} mr={2}/>
                                     </Grid>
                                 }
                    />
                    <FormControlRadioGroup name='useInstanceCredentials'
                                           value={props.cloudConnection.useInstanceCredentials}
                                           onChange={handleInstanceCredentialsTypeChange}
                                           options={[
                                               ...(props.showInstanceCredentialsOption ?
                                                   [{
                                                       ...identityOptions.alibaba,
                                                       subcomponent: <div style={{
                                                           width: '100%', paddingLeft: '28px', marginTop: '-16px'
                                                       }}>
                                                           <InlineEditTextField
                                                               name='instanceRoleName'
                                                               label='RAM role name'
                                                               value={props.cloudConnection.instanceRoleName}
                                                               save={(event, data) => props.handleSubmit(event, data)}
                                                               placeholder='Enter RAM role name'
                                                               disabled={!props.cloudConnection.useInstanceCredentials}
                                                               required={props.cloudConnection.useInstanceCredentials}
                                                               errorMessage={props.errors.instanceRoleName}
                                                               updateMessage='Saved'
                                                               autoComplete='off'
                                                           />
                                                       </div>
                                                   }] :
                                                   []),
                                               {
                                                   value: false, label: 'Use unique credentials',
                                                   subcomponent: <div style={{
                                                       width: '100%', paddingLeft: '28px', marginTop: '-16px'
                                                   }}>
                                                       <InlineEditTextField name='accessKeyId' label='Access Key ID'
                                                                            value={props.cloudConnection.accessKeyId}
                                                                            required={!props.cloudConnection.useInstanceCredentials}
                                                                            autoComplete='off'
                                                                            save={(event, data) => props.handleSubmit(event, data)}
                                                                            placeholder='Enter Access Key ID'
                                                                            updateMessage='Saved'
                                                                            errorMessage={props.errors.accessKeyId}/>
                                                       <InlineEditTextField name='accessSecret'
                                                                            label='AWS Access Secret' autoComplete='off'
                                                                            required={!props.cloudConnection.useInstanceCredentials && !props.cloudConnection.accessSecretSet}
                                                                            emptyText={`${props.cloudConnection.accessSecretSet ? '**********' : 'None'}`}
                                                                            value={props.cloudConnection.accessSecret} type='password'
                                                                            updateMessage='Saved'
                                                                            save={(event, data) => props.handleSubmit(event, data)}
                                                                            placeholder={`${props.cloudConnection.accessSecretSet ? '**********' : 'Enter secret for key ID'}`}
                                                                            errorMessage={props.errors.accessSecret}/>
                                                   </div>
                                               }]}
                    />
                </Grid>
                {!isEmpty(props.cloudConnection.connectivity) &&
                    <Grid container item>
                        <TestConnectionResultTable provider={props.cloudConnection.cloudProvider}
                                                   connectivity={props.cloudConnection.connectivity}
                                                   connectionId={props.cloudConnection.id} setErrors={props.setErrors}/>
                    </Grid>
                }
            </Grid>
        </form>}
    </HelpSystemContext.Consumer>);
}

AlibabaCloudConnectionEditForm.propTypes = {};

AlibabaCloudConnectionEditForm.defaultProps = {};

export default AlibabaCloudConnectionEditForm;
