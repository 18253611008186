import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useTheme} from '@mui/material/styles';

const useStyles = makeStyles({
    label: {
        fontSize: '0.875rem',
    }
});

const StyledFormControlLabel = styled(FormControlLabel)`
    margin-top: ${props => props.theme.spacing(props.mt)};
`;

// noinspection FunctionNamingConventionJS
export default function FormControlCheckbox(props) {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <StyledFormControlLabel theme={theme} mt={props.mt}
            control={<Checkbox {...props} onChange={props.onChange}/>}
            label={props.label}
            classes={{label: classes.label}}
        />
    );
}

FormControlCheckbox.propTypes = {
    mt: PropTypes.number,
    label: PropTypes.string,
    onChange: PropTypes.func,
};

FormControlCheckbox.defaultProps = {
    mt: 1
};