import {useEffect, useState} from 'react';
import axios from 'axios';
import api_routes from '../util/api_routes';

const initDependencies = {
    triggerRefresh: false,
};

function useEncryptionAlgorithms({dependencies = initDependencies} = {}) {

    const {triggerRefresh} = dependencies;
    const [supportedAlgorithms, setSupportedAlgorithms] = useState([]);
    const [enabledAlgorithms, setEnabledAlgorithms] = useState([]);
    const [isLoadingSupportedAlgos, setIsLoadingSupportedAlgos] = useState(true);
    const [isLoadingEnabledAlgos, setIsLoadingEnabledAlgos] = useState(true);


    useEffect(function loadSupportedEncryptionAlgorithms () {
        const fetchSupportedEncryptionAlgorithms = async function fetchSupportedEncryptionAlgorithms() {
            const result = await axios.get(`${api_routes.encryptionAlgorithms.endpoint}/supported`);
            setSupportedAlgorithms(result.data);
            setIsLoadingSupportedAlgos(false);
        }
        fetchSupportedEncryptionAlgorithms();
    }, [triggerRefresh]);

    useEffect(function loadEnabledEncryptionAlgorithms () {
        const fetchEnabledEncryptionAlgorithms = async function fetchEnabledEncryptionAlgorithms() {
            const result = await axios.get(`${api_routes.encryptionAlgorithms.endpoint}`);
            setEnabledAlgorithms(result.data);
            setIsLoadingEnabledAlgos(false);
        }

        fetchEnabledEncryptionAlgorithms();
    }, [triggerRefresh]);

    return {isLoadingSupportedAlgos, supportedAlgorithms, isLoadingEnabledAlgos, enabledAlgorithms};
}

export default useEncryptionAlgorithms;