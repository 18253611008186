 const options = {
    alibaba: {
        value: true, label: 'Use an instance-attached RAM role',
        helpLink: {
            href: 'http://www.sftpgateway.com/alibaba/instance-identity.html',
            target: '_blank',
            text: 'How do I use an instance-attached RAM role?'
        }
    },
    aws: {
        value: true, label: 'Use instance profile credentials',
        helpLink: {
            href: 'http://www.sftpgateway.com/aws/instance-identity.html',
            target: '_blank',
            text: 'How do I use instance profile credentials?'
        }
    },
    azure: {value: true, label: 'Use instance identity',
        helpLink: {
            href: 'http://www.sftpgateway.com/azure/instance-identity.html',
            target: '_blank',
            text: 'How do I use instance identity?'
        }},
    gcp: {value: true, label: 'Use Instance\'s Service Account',
        helpLink: {
            href: 'http://www.sftpgateway.com/gcp/instance-identity.html',
            target: '_blank',
            text: 'How do I use the Instance\'s Service Account?'
        }}

};

 export default options;