import React from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import FolderUpdateComponent from '../../component/folder/FolderUpdateComponent';
import {isEmpty} from '../../util/helpers';
import api_routes from '../../util/api_routes';
import useFolder from '../../api/useFolder';
import useCloudConnections from '../../api/useCloudConnections';
import useFolderPermissions from '../../api/useFolderPermissions';

// noinspection FunctionNamingConventionJS
function FolderUpdateContainer() {

    let {folderId} = useParams();
    if (isEmpty(folderId)) {
        folderId = 0;
    }

    const {isLoading: loadingCloudConnections, cloudConnections} = useCloudConnections();

    const {isLoading, folder} = useFolder({ params: { folderId } });
    const {isLoading: isLoadingFolderPermissions, folderPermissions} = useFolderPermissions({
        params: { folderId }
    });

    const save = async function updateFolder(folder) {
        return await axios.put(`${api_routes.folders.endpoint}/${folderId}`, folder);
    };

    const deleteFolder = async function deleteFolderOnFolderListPage(folderToDelete) {
        return await axios.delete(`${api_routes.folders.endpoint}/${folderToDelete.id}`);
    };

    const updatePermission = async function updateUserFolderPermission(folderId, userId, permission) {
        return await axios.put(
            `${api_routes.folders.endpoint}/${folderId}${api_routes.folderPermissions.endpoint}/${userId}`,
            {permission}
            );
    };

    return (<FolderUpdateComponent cloudConnections={cloudConnections} loadingCloudConnections={loadingCloudConnections}
                                   folder={folder} loadingFolder={isLoading}
                                   folderPermissions={folderPermissions} loadingFolderPermissions={isLoadingFolderPermissions}
                                   updatePermission={updatePermission} updateFolder={save} deleteFolder={deleteFolder}
    />);
}

FolderUpdateContainer.propTypes = {};

FolderUpdateContainer.defaultProps = {};

export default FolderUpdateContainer;
