import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {
    TableContainer,
    Table,
    useTheme,
    TableRow,
    TableBody,
    IconButton,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
    StyledEditIcon,
    StyledEditIconButton,
    StyledTableCell,
    StyledTableHead,
    StyledTableHeadCell,
    StyledTableRow, StyledTableRowInlineEditingButton,
    StyledTableRowWithForm
} from './styled';
import CustomAlert from './CustomAlert';
import ConfirmDialog from './ConfirmDialog';
import CustomDeleteIcon from './CustomDeleteIcon';
import {isEmpty} from '../../util/helpers';
import {convertErrorsToObject} from '../../util/errorHandler';
import ConfirmIdentityDialog from '../auth/ConfirmIdentityDialog';

/*
This component will not manage the state of data, it will only signal to the parent component which items are being
updated/created/removed. This component does the heavy lifting of managing editable state of the table.
 */

// noinspection FunctionNamingConventionJS
function InlineEditTable({
                             header, data, noDataMessage, actionsColumnName, columnWidths, displayRow, editRow,
                             update, remove, showAddRow, setShowAddRow, addRow, itemToCreateInitState, create,
                             deleteModalTitle, deleteModalField, disabled, confirmIdentityOnUpdateAction
                         }) {

    const theme = useTheme();
    const [editing, setEditing] = useState(null); // index of the row that is being edited 
    const [itemToEdit, setItemToEdit] = useState(itemToCreateInitState); // object that is being edited
    const [itemToCreate, setItemToCreate] = useState(itemToCreateInitState);
    const [itemToRemoveIdx, setItemToRemoveIdx] = useState(null);
    const [errors, setErrors] = useState({});
    const [changeHappened, setChangeHappened] = useState(false);
    const [openConfirmIdentityDialog, setOpenConfirmIdentityDialog] = useState(false);

    useEffect(function updateItemWhenShowAddRowTurnsTrue() {
        if (showAddRow && !isEmpty(editing)) {
            if (changeHappened) {
                update({index: editing, itemToEdit})
                    .then(() => {
                        setChangeHappened(false);
                        setEditing(null);
                        setErrors({});
                    })
                    .catch(error => {
                        handleErrors(error);
                        setShowAddRow(false);
                    });
            } else {
                setEditing(null);
                setErrors({});
            }
        }
    }, [showAddRow, changeHappened, editing, itemToEdit, setShowAddRow, update]);

    const handleItemUpdate = function handleItemUpdateInlineEditTable(event) {
        setChangeHappened(true);
        setItemToEdit({...itemToEdit, [event.target.name]: event.target.value});
    };

    const handleItemCreate = function handleItemCreateInlineEditTable(event) {
        setChangeHappened(true);
        setItemToCreate({...itemToCreate, [event.target.name]: event.target.value});
    };

    const handleErrors = function handleErrorsInlineEditTable(error) {
        if (!isEmpty(error.response)) {
            let tempErrors = convertErrorsToObject(error.response);
            if(tempErrors.currentPassword){
                setOpenConfirmIdentityDialog(true);
            }
            setErrors({...tempErrors});
        } else {
            setErrors({...error});
        }
    }

    const cancel = function cancelInlineEditTable() {
        setEditing(null);
        setItemToEdit(itemToCreateInitState);
        setItemToCreate(itemToCreateInitState);
        setErrors({});
        setShowAddRow(false);
        setChangeHappened(false);
    };

    const updateItem = async function updateItemInlineEditTable() {
        if (confirmIdentityOnUpdateAction) {
            setOpenConfirmIdentityDialog(true);
        } else {
            await __updateItem();
        }
    };

    const __updateItem = async (currentPassword) => {
        try {
            if (changeHappened) {
                await update({index: editing, itemToEdit, currentPassword});
                setChangeHappened(false);
            }
            setEditing(null);
            setErrors({});
            return true;
        } catch (error) {
            handleErrors(error);
            return false;
        }
    }

    const removeItem = async function removeItemInlineEditTable() {
        try {
            const itemToRemove = data[itemToRemoveIdx];
            await remove({index: itemToRemoveIdx, itemToRemove});
        } catch (error) {
            toast.notify(
                ({onClose}) => <CustomAlert type='error' message='Could not delete entry!' onClose={onClose}/>);
        }
    };

    const createItem = async function createItemInlineEditTable() {
        try {
            await create(itemToCreate);
            setChangeHappened(false);
            setShowAddRow(false);
            setItemToCreate(itemToCreateInitState);
            setErrors({});
        } catch (error) {
            handleErrors(error);
        }
    }

    const editingRow = async function editingRowInlineEditTable(dataRowIdx) {
        try {
            if (showAddRow) {
                await create(itemToCreate);
            }
            if (!isEmpty(editing) && changeHappened) {
                await update({index: editing, itemToEdit});
            }
            setChangeHappened(false);
            setShowAddRow(false);
            setItemToCreate(itemToCreateInitState);
            setErrors({});
            setEditing(dataRowIdx);
            setItemToEdit(data[dataRowIdx]);
        } catch (error) {
            handleErrors(error);
        }
    }

    return (
        <>
            {confirmIdentityOnUpdateAction &&
                <ConfirmIdentityDialog open={openConfirmIdentityDialog}
                                       setOpen={setOpenConfirmIdentityDialog}
                                       errors={errors}
                                       handleConfirmPassword={
                                           async (password) => {
                                               if(await __updateItem(password)){
                                                   setOpenConfirmIdentityDialog(false);
                                               }
                                           }}
                />
            }
            <ConfirmDialog
                title={deleteModalTitle}
                open={itemToRemoveIdx !== null}
                setOpen={() => setItemToRemoveIdx(null)}
                onConfirm={removeItem}
            >
                Are you sure you want to
                delete{!isEmpty(data[itemToRemoveIdx]) ? ' ' + data[itemToRemoveIdx][deleteModalField] : ''}?
            </ConfirmDialog>
            <TableContainer>
                <Table>
                    {!isEmpty(columnWidths) &&
                        <colgroup>
                            {columnWidths.map((cw, idx) => <col key={idx} style={{width: `${cw}%`}}/>)}
                        </colgroup>
                    }
                    <StyledTableHead theme={theme} disabled={disabled}>
                        <TableRow>
                            {header.map((columnName, idx) =>
                                <StyledTableHeadCell key={idx} theme={theme} disabled={disabled}>
                                    {columnName}
                                </StyledTableHeadCell>
                            )}
                            <StyledTableHeadCell theme={theme} align='center' disabled={disabled}>
                                {actionsColumnName}
                            </StyledTableHeadCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {((isEmpty(data) || data.length === 0) && !showAddRow) &&
                            <StyledTableRow theme={theme} disabled={disabled}>
                                <StyledTableCell align='center' colSpan={header.length + 1} disabled={disabled}>
                                    {noDataMessage}
                                </StyledTableCell>
                            </StyledTableRow>
                        }
                        {!isEmpty(data) && data.map((row, rowIdx) => {
                            let component
                            if (editing === rowIdx) {
                                component = (
                                    <StyledTableRowWithForm key={rowIdx} theme={theme}>
                                        {editRow({data: itemToEdit, updateData: handleItemUpdate, errors: errors})
                                            .map((cell, cellIdx) => (
                                                <StyledTableCell key={cellIdx}
                                                                 disabled={disabled}>{cell}</StyledTableCell>
                                            ))}
                                        <StyledTableCell align='center' disabled={disabled}>
                                            <StyledTableRowInlineEditingButton variant='contained' disableElevation
                                                                               onClick={updateItem}
                                                                               style={{marginRight: '2px'}}>
                                                <CheckIcon fontSize='small'/></StyledTableRowInlineEditingButton>
                                            <StyledTableRowInlineEditingButton variant='contained' disableElevation
                                                                               onClick={cancel}>
                                                <ClearIcon fontSize='small'/>
                                            </StyledTableRowInlineEditingButton>
                                        </StyledTableCell>
                                    </StyledTableRowWithForm>
                                );
                            } else {
                                component = (
                                    <StyledTableRow key={rowIdx} theme={theme}>
                                        {displayRow(row).map((cell, cellIdx) => (
                                            <StyledTableCell key={cellIdx} disabled={disabled}>{cell}</StyledTableCell>
                                        ))}
                                        <StyledTableCell align='center' disabled={disabled}>
                                            <StyledEditIconButton size='small' onClick={() => editingRow(rowIdx)}>
                                                <StyledEditIcon fontSize='small' theme={theme} disabled={disabled}/>
                                            </StyledEditIconButton>
                                            {!isEmpty(remove) &&
                                                <IconButton size='small' onClick={() => setItemToRemoveIdx(rowIdx)}>
                                                    <CustomDeleteIcon fontSize='small' disabled={disabled}/>
                                                </IconButton>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            }
                            return component;
                        })}
                        {showAddRow && isEmpty(editing) &&
                            <StyledTableRowWithForm key='-1' theme={theme}>
                                {addRow(itemToCreate, handleItemCreate, errors).map((cell, cellIdx) => (
                                    <StyledTableCell key={-cellIdx} disabled={disabled}>{cell}</StyledTableCell>
                                ))}
                                <StyledTableCell align='center' disabled={disabled}>
                                    <StyledTableRowInlineEditingButton variant='contained' disableElevation
                                                                       onClick={createItem}
                                                                       style={{marginRight: '2px'}}>
                                        <CheckIcon fontSize='small'/></StyledTableRowInlineEditingButton>
                                    <StyledTableRowInlineEditingButton variant='contained' disableElevation
                                                                       onClick={cancel}>
                                        <ClearIcon fontSize='small'/>
                                    </StyledTableRowInlineEditingButton>
                                </StyledTableCell>
                            </StyledTableRowWithForm>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

InlineEditTable.propTypes = {
    // Column names from left to right (ex: ['Username', 'Date Created']). Do not include 'Actions' column here, as it
    // will be included automatically
    header: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.any),
    noDataMessage: PropTypes.string,
    actionsColumnName: PropTypes.string,
    // number of percentage (ex: [40, 60] translates to <col style={{width: '40%'}}/> and <col style={{width: '60%'}}/>)
    columnWidths: PropTypes.arrayOf(PropTypes.number),
    // function that accepts a row and returns a list of table cell values in the order that they appear in the header
    displayRow: PropTypes.func.isRequired,
    // function that accepts a row and returns a list of nodes that represent editable fields (in order of the header)
    editRow: PropTypes.func.isRequired,
    // this function is called when the editable row needs to be updated, definition: update({index, itemToEdit}), where
    // index is the index of the item being updated in the list and itemToEdit is the updated object itself
    update: PropTypes.func.isRequired,
    // this function is called when the row needs to be removed, definition: remove({index, itemToRemove}), where index
    // is the index of the item being deleted in the list and itemToRemove is the object to be removed
    remove: PropTypes.func,
    // if this table needs to support addition, declare the state in the parent controller and pass it down as a prop
    showAddRow: PropTypes.bool,
    // this function controls parent's add row boolean
    setShowAddRow: PropTypes.func,
    // function that accepts a row and returns a list of nodes that represent addable fields (in order of the header),
    // this might be identical to the editRow function
    addRow: PropTypes.func,
    // initial state of the object to be created (this is also the init state of the object to be updated)
    itemToCreateInitState: PropTypes.object,
    // this function is called when an item is created
    create: PropTypes.func,
    deleteModalTitle: PropTypes.string,
    // name of the field that will appear in the confirmation box during item deletion.
    deleteModalField: PropTypes.string,
    // this will make the table appear disabled, but will not disable its functionality; that is left for the parent
    // component to decide.
    disabled: PropTypes.bool,
    // if this option is set to true, a dialog asking for currently logged-in user's password will open up to make sure
    // they are authorized to perform the update action.
    confirmIdentityOnUpdateAction: PropTypes.bool,
};

InlineEditTable.defaultProps = {
    noDataMessage: 'No records to display',
    actionsColumnName: 'Actions',
    data: [],
    showAddRow: false,
    setShowAddRow: () => {
    },
    deleteModalTitle: 'Delete Item',
    disabled: false,
    confirmIdentityOnUpdateAction: false,
};

export default InlineEditTable;
