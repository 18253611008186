import {useEffect, useState} from 'react';
import axios from 'axios';
import api_routes from '../util/api_routes';

const initCloudConnections = [];

const initDependencies = {
    triggerRefresh: false,
};

function useCloudConnections({dependencies = initDependencies} = {}) {

    const {triggerRefresh} = dependencies;
    const [cloudConnections, setCloudConnections] = useState(initCloudConnections);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadCloudConnections() {
        const fetchCloudConnectionsData = async function fetchCloudConnectionsData() {
            try {
                const result = await axios.get(api_routes.cloudConnection.endpoint);
                setCloudConnections(result.data.cloudConnections);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchCloudConnectionsData();
    }, [triggerRefresh]);

    return {isLoading, cloudConnections};
}

export default useCloudConnections;
