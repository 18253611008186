import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link, Typography, Box } from '@mui/material';
import routes from '../util/routes';
import { AWS, AZURE, GCP } from '../util/cloud_providers';

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

// noinspection FunctionNamingConventionJS
function HelpSystemDrawer(props) {

    const theme = useTheme();
    const [openDrawer, setOpenDrawer] = useState(props.open);

    const supportResources = () => {
        let mailto = '';
        switch (props.cloudProvider) {
            case AWS:
                mailto = 'support@thorntech.com';
                break;
            case GCP:
                mailto = 'google-support@thorntech.com';
                break;
            case AZURE:
                mailto = 'azure-support@thorntech.com';
                break;
            default:
                mailto = 'support@thorntech.com';

        }
        return (<Typography><Link href={`mailto:${mailto}`}>Contact support</Link></Typography>);
    };

    useEffect(() => {
        setOpenDrawer(props.open);
    }, [props.open]);

    return (
        <Drawer anchor={props.drawerAnchor} open={openDrawer}
                sx={{
                    width: props.drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: props.drawerWidth,
                        boxSizing: 'border-box',
                        padding: theme.spacing(1.5, 1.5, 9, 1.5),
                        top: '65px',
                        right: 'unset',
                        border: `1px solid ${theme.palette.divider}`,
                    },
                }}
                variant='persistent'
        >
            <DrawerHeader>
                <Typography variant='subtitle1'>Support + troubleshooting</Typography>
                <IconButton onClick={props.handleDrawerClose} size='small'>
                    <CloseIcon fontSize='small'/>
                </IconButton>
            </DrawerHeader>
            <Markdown>
                {props.post}
            </Markdown>

            <Box sx={{pb: 2}}>
                <Typography variant='subtitle1'>Support Resources</Typography>
                {supportResources()}
            </Box>

            <Typography variant='subtitle1'>Need more help?</Typography>
            <Typography>Visit our <Link href={routes.kb.path} target='_blank' rel='noopener noreferrer'>
                {routes.kb.menuTitle}</Link></Typography>
        </Drawer>
    );
}

HelpSystemDrawer.propTypes = {
    drawerAnchor: PropTypes.string,
    post: PropTypes.string,
    drawerWidth: PropTypes.any,
    handleDrawerClose: PropTypes.func,
    open: PropTypes.bool,
};

HelpSystemDrawer.defaultProps = {
    drawerAnchor: 'right',
    open: false
};

export default HelpSystemDrawer;
