import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import axios from 'axios';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    IconButton, Table, TableBody, TableRow, Chip, TableContainer, Dialog, Grid, Typography, Box
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {useTheme} from '@mui/material/styles';
import {green, red} from '@mui/material/colors';
import PageSection from '../../common/PageSection';
import ConfirmDialog from '../../common/ConfirmDialog';
import CustomDeleteIcon from '../../common/CustomDeleteIcon';
import CircularProgressButton from '../../common/CircularProgressButton';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import {StyledTableCell, StyledTableHead, StyledTableHeadCell} from '../../common/styled';
import SshKeyModal from '../sshKeyModal/SshKeyModal';
import EditSshKeyModal from '../sshKeyModal/EditSshKeyModal';
import {isEmpty, truncateString} from '../../../util/helpers';
import api_routes from '../../../util/api_routes';
import HelpSystemContext from '../../../context/HelpSystemContext';

export const StyledChip = styled(Chip)`
    height: 1rem;
`;

const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }
  &:nth-of-type(even) {
    background-color: ${props => props.theme.palette.action.hover}
  }
`;

const StyledEditIconButton = styled(IconButton)`
    margin-right: 8px;
`;

// noinspection FunctionNamingConventionJS
function SSHKeyTable(props) {

    const theme = useTheme();
    const [publicKeys, setPublicKeys] = useState(props.publicKeys);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSSHModal, setShowSSHModal] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(false);
    const [editRow, setEditRow] = useState();
    const [editIndex, setEditIndex] = useState();
    const [showSavedMessage, setShowSavedMessage] = useState(false);

    const fieldRef = useRef(null);

    useEffect(() => {
        if ((!isEmpty(props.errorMessage)) && fieldRef.current) {
            fieldRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [props.errorMessage]);

    useEffect(() => {
        setPublicKeys(props.publicKeys);
    }, [props.publicKeys]);

    // If the status is enabled, show green enabled chip, otherwise show red disabled chip.
    const displayStatus = function displayStatusInSSHKeysTableOnUserForm(row) {
        let statusChip;
        if (row.enabled) {
            statusChip = <StyledChip size='small' label='Enabled' style={{backgroundColor: green['100']}}/>;
        } else {
            statusChip = <StyledChip size='small' label='Disabled' style={{backgroundColor: red['100']}}/>;
        }
        return statusChip;
    };

    const displayGeneratedFlag = function displayGeneratedFlagInSSHKeysTableOnUserForm(row) {
        let generatedFlag;
        if (isEmpty(row.generated)) {
            generatedFlag = '-';
        } else if (row.generated) {
            generatedFlag = 'Yes';
        } else {
            generatedFlag = 'No';
        }
        return generatedFlag;
    };

    // Do no display anything is value is empty, moment by default will display current time.
    const displayDate = function displayDateInSSHKeysTableOnUserForm(value, open) {
        let date;
        if (isEmpty(value)) {
            date = '-';
        } else {
            date = <Moment format={open ? 'MMM D, YYYY' : 'MMM D, YYYY h:mm a'}>{value}</Moment>;
        }
        return date;
    };

    const addPublicKey = async function handleSavePublicKeyToSSHKeysTable(key) {
        if (props.creatingUser) {
            await props.addPublicKey(key);
        } else {
            let response = await axios.post(
                `${api_routes.user.endpoint}/${props.userId}${api_routes.userSshKey.endpoint}`, key);
            setPublicKeys(publicKeys => [...publicKeys, response.data]);
            setShowSavedMessage(true);
        }
    };

    const updatePublicKey = async function handleUpdatePublicKey(index, key) {
        if (props.creatingUser) {
            await props.updatePublicKey(index, key);
        } else {
            let response = await axios.put(
                `${api_routes.user.endpoint}/${props.userId}${api_routes.userSshKey.endpoint}/` + publicKeys[index].id, key);
            let tempPublicKeys = _.map(publicKeys, function (pk) {
                if (pk.id === response.data.id) {
                    return response.data;
                }
                return pk;
            });
            setPublicKeys(tempPublicKeys);
            setShowSavedMessage(true);
        }
    };

    const removePublicKey = async function handleRemovePublicKey(index) {
        if (props.creatingUser) {
            await props.removePublicKey(index);
        } else {
            const pk = publicKeys[index];
            await axios.delete(`${api_routes.user.endpoint}/${props.userId}${api_routes.userSshKey.endpoint}/${pk.id}`);
            setPublicKeys(publicKeys.filter(item => item.id !== pk.id));
            setShowSavedMessage(true);
        }
    }

    // If the table body is empty, print 'No records to display' message, otherwise print the data.
    const displayTableBody = function displayTableBodyInSSHKeysTableOnUserForm(data, open) {
        let body;
        if (data.length === 0) {
            body = (<TableRow>
                <StyledTableCell align='center' colSpan={6}>No records to display</StyledTableCell>
            </TableRow>);
        } else {
            body = data.map((row, idx) => (
                <StyledTableRow theme={theme} key={row.id || idx}>
                    <StyledTableCell>{open ? truncateString(row.name, 20) : row.name}</StyledTableCell>
                    <StyledTableCell align='left'>{displayDate(row.createdDate, open)}</StyledTableCell>
                    <StyledTableCell align='left'>{displayDate(row.lastUsedDate, open)}</StyledTableCell>
                    <StyledTableCell align='center'>{displayStatus(row)}</StyledTableCell>
                    <StyledTableCell align='center'>{displayGeneratedFlag(row)}</StyledTableCell>
                    <StyledTableCell align='center' style={{padding: '.875rem .85rem'}}>
                        <StyledEditIconButton size='small' onClick={() => {
                            setShowEditModal(true);
                            setEditRow(row);
                            setEditIndex(idx);
                        }}>
                            <EditIcon fontSize='small' color='primary'/>
                        </StyledEditIconButton>
                        <IconButton size='small' onClick={() => setDeleteIdx(idx)}>
                            <CustomDeleteIcon fontSize='small'/>
                        </IconButton>
                        <ConfirmDialog
                            title='Delete SSH Key'
                            open={deleteIdx === idx}
                            setOpen={setDeleteIdx}
                            onConfirm={() => removePublicKey(idx)}
                        >
                            Are you sure you want to delete {row.name}?
                        </ConfirmDialog>
                    </StyledTableCell>
                </StyledTableRow>
            ))
        }
        return body;
    };

    return <HelpSystemContext.Consumer>
        {open =>
            <>
                <PageSection title='SFTP SSH Key' titleVariant='body1'
                             subtitle='Users can use SSH keys to log into SFTP Gateway.
                                 You may use your own SSH keys or generate new SSH keys as needed.
                                 If you do not want to use an SSH key, leave this table empty.'
                             subtitleVariant='body2'
                             action={<Grid container alignItems='center'>
                                 <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>
                                 <CircularProgressButton label='Add SSH Key' buttonTextTransform='none' mt={0}
                                                         color='primary' disableElevation variant='outlined'
                                                         size='small' onClick={() => setShowSSHModal(true)}/>
                             </Grid>}
                             pb={2} mt={4}/>
                <Dialog open={showSSHModal} onClose={() => setShowSSHModal(false)}>
                    <SshKeyModal handleCloseModal={() => setShowSSHModal(false)}
                                 addPublicKey={addPublicKey}/>
                </Dialog>
                <TableContainer ref={fieldRef}>
                    <Table aria-label='ssh-keys-table'>
                        <StyledTableHead theme={theme}>
                            <TableRow>
                                <StyledTableHeadCell theme={theme} align='left'>Key Name</StyledTableHeadCell>
                                <StyledTableHeadCell theme={theme} align='left'>Date Added</StyledTableHeadCell>
                                <StyledTableHeadCell theme={theme} align='left'>Date Last Used</StyledTableHeadCell>
                                <StyledTableHeadCell theme={theme} align='center'>Status</StyledTableHeadCell>
                                <StyledTableHeadCell theme={theme} align='center'>Generated</StyledTableHeadCell>
                                <StyledTableHeadCell theme={theme} align='center'>Actions</StyledTableHeadCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {displayTableBody(publicKeys, open)}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!isEmpty(props.errorMessage) &&
                <Box ml={1} mt={1}>
                    <Typography variant='caption' color='error'>{props.errorMessage}</Typography>
                </Box>
                }
                <Dialog open={showEditModal} onClose={() => setShowEditModal(false)}>
                    <EditSshKeyModal handleCloseModal={() => setShowEditModal(false)}
                                     updatePublicKey={updatePublicKey} publicKey={editRow} index={editIndex}
                    />
                </Dialog>
            </>
        }
    </HelpSystemContext.Consumer>
}

const Key = PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    createdDate: PropTypes.string,
    lastUsedDate: PropTypes.string,
    enabled: PropTypes.bool,
});

SSHKeyTable.propTypes = {
    publicKeys: PropTypes.arrayOf(Key),
    removePublicKey: PropTypes.func,
    creatingUser: PropTypes.bool,
    errorMessage: PropTypes.string,
};

SSHKeyTable.defaultProps = {
    publicKeys: [],
    creatingUser: false,
};

export default SSHKeyTable;
