import styled from 'styled-components';
import {
    Breadcrumbs,
    Button,
    IconButton,
    Link,
    ListItemIcon,
    Select,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { isEmpty } from '../../../util/helpers';
import { grey } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import { Lock } from "@mui/icons-material";

/* --- SFTP GW Tables --- */
export const StyledTableHead = styled(TableHead)`
    background-color: ${props => {
        if (!isEmpty(props.disabled) && props.disabled) {
            return grey[200];
        }
        return props.theme.palette.primary.main + '0a';
    }};
`;

export const StyledTableHeadCell = styled(TableCell)`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.75rem;
    color: ${props => {
        if (!isEmpty(props.disabled) && props.disabled) {
            return grey[500];
        }
        return props.theme.palette.primary.main
    }};
    border-color: ${props => {
        if (!isEmpty(props.disabled) && props.disabled) {
            return grey[500];
        }
        return props.theme.palette.primary.main;
    }};
    padding: .875rem 1rem;
`;

export const StyledTableRow = styled(TableRow)`
  &:hover td, &:hover th {
    cursor: pointer;
    background-color: ${props => props.theme.palette.action.hover};
    .actions {
        visibility: visible;
    }
  }
  &:nth-of-type(even) {
      background-color: ${props => {
          if (!isEmpty(props.disabled) && props.disabled) {
              return grey[100];
          }
          return grey[50];
      }};
  }
    
  text-decoration: none;
`;

export const StyledActionCell = styled.div`
    visibility:hidden;
`;

export const StyledTableIcon = styled(ListItemIcon)`
  margin-left: 0.5rem;
  min-width: 32px;
  vertical-align: middle;
`;

export const StyledTableRowWithForm = styled(StyledTableRow)`
    background-color: ${props => props.theme.palette.secondary.main + '26'}!important;
`;

export const StyledTableCell = styled(TableCell)`
    padding: .875rem 1rem .875rem 1rem;
    vertical-align: baseline;
    color: ${props => {
        if (!isEmpty(props.disabled) && props.disabled) {
            return grey[500];
        }
        return 'inherit';
    }};
`;

export const StyledEditIconButton = styled(IconButton)`
    margin-right: 8px;
`;

export const StyledEditIcon = styled(EditIcon)`
    color: ${props => {
    if (!isEmpty(props.disabled) && props.disabled) {
        return grey[500];
    }
    return props.theme.palette.primary.main;
}};
`;

export const StyledTableRowInlineEditingButton = styled(Button)`
    min-width: auto; 
    padding: 2px 6px;
`;

/* --- Select without the background --- **/
export const StyledSelect = styled(Select)`
  color: #333;
  font-size: .875rem;
  font-family: "Open Sans", sans-serif;
  padding-left: 12px;
  border-radius: 3px;
  padding-right: 12px;
`;

/* --- Forms --- */
export const StyledBreadcrumbs = styled(Breadcrumbs)`
    margin-bottom: ${props => props.theme.spacing(3)};
    cursor: pointer;
`;

export const StyledBreadcrumbLink = styled(Link)`
    display: flex;
`;

export const StyledBreadcrumbIcon = styled(ArrowBackIosIcon)`
    margin-right: ${props => props.theme.spacing(0.5)};
    width: 20px;
    height: 20px;
    align-self: center;
`;

/** Main Page Table **/
export const StyledNavControls = styled.div`
   padding: 16px;
   border: 1px solid;
   border-color: ${props => props.theme.palette.primary.main + '73'};
   background-color: ${props => props.theme.palette.primary.main + '0a'};
   border-top-left-radius: 4px; 
   border-top-right-radius: 4px;
   margin: -1px -1px 0;
   position: relative !important;
`;

export const StyledNavControlsWithoutBackground = styled.div`
   padding: 16px;
   border-bottom: 1px solid;
   border-color: ${props => props.theme.palette.divider};
`;

/** Diagnostics Page **/
export const StyledLogsWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    overflow: auto;
    margin-top: 16px;
`;

export const StyledLogsList = styled.ul`
    width: 100%;
    max-width: 100%;
    list-style-type: none;
    padding-left: unset;
    margin-top: unset;
    li:nth-child(odd) {
        background: rgba(0,0,0,.05);
    }
    li {
        padding: 4px 4px;
    }
    li > div{
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-all;
    }
`;

export const StyledStatusCell = styled(TableCell)`
    padding-right: 0;
    height: inherit;
`;

export const StyledLockIcon = styled(Lock)`
    display: ${props => props.locked === 'true' ? 'inherit' : 'none'};
    width: 1rem;
`;