// View default values here: https://material-ui.com/customization/default-theme/
const palette = {
  primary: {
    dark: '#095b96',
    main: '#2075af',
  },
  secondary: {
    dark: '#ea8400',
    main: '#ff9e21',
  },
  navbar: {
    main: '#fff',
    text: '#949292',
  },
};

export default palette;
