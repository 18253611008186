import { createTheme, adaptV4Theme } from '@mui/material/styles';
import palette from './palette';

const sftpgwTheme = createTheme(adaptV4Theme({
  palette,
  typography:  {
    system: {
      whiteSpace: 'pre-wrap',
      fontFamily: 'monospace-ui, monospace',
      fontSize: '0.875em'
    }
  },
  components: {
    MuiInput: {
      variants: [
        {
          props: {variant: 'system'},
          style: {
            whiteSpace: 'pre-wrap',
            fontFamily: 'monospace-ui, monospace',
            fontSize: '0.875em'
          }
        }
      ]
    }
  }
}));

export default sftpgwTheme;
