import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {AppBar, Button, Container, Divider, Grid, Hidden, ListItemIcon, Menu, MenuItem, Toolbar,} from '@mui/material';
import routes from '../../util/routes';
import HelpSystemComponent from '../HelpSystemComponent';
import ResponsiveNavigation from './ResponsiveNavigation';
import {
    authenticationService,
    KEY_USER_LOGIN_METHOD,
    VALUE_USER_LOGIN_METHOD_SLINK
} from '../../container/auth/authenticationService';

const StyledAppBar = styled(AppBar)`
  background-color: ${props => props.theme.palette.navbar.main};
`;

const StyledNavLogo = styled.img`
    width: 75px;
    margin-right: .75em;
`;

const StyledNavText = styled.img`
    height: 20px;
`;

const StyledMenuButton = styled(Button)`
  text-transform: none;
  color: ${grey[600]};
`;

const StyledFlexGridItem = styled(Grid)`
  flex: 1;
`;

const StyledNavLink = styled(NavLink)`
  padding-bottom: 20px;
  margin: 0 16px;
  text-decoration: none;
  color: ${props => props.theme.palette.navbar.text};
  font-size: ${props => props.theme.spacing(2)};
  font-weight: 700;

  &.active {
    color: ${props => props.theme.palette.primary.main};
    border-bottom: 3px solid ${props => props.theme.palette.secondary.main};
  }

  &:hover {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 0.85rem;
`;

const StyledChangePasswordLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 32px;
`;

// noinspection FunctionNamingConventionJS
function Navigation(props) {

    const theme = useTheme();
    const [openHelpSystemComponent, setOpenHelpSystemComponent] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const currentUser = useSelector(function getCurrentUserForNavigation(state) {
        return state.currentUser
    });

    const handleClick = function expandNavigationMenu(event) {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = function closeNavigationMenu() {
        setAnchorEl(null);
    };

    const handleSignOut = async function signOut() {
        await authenticationService.logout();
    };

    const helpTextIconButton = (
        <IconButton sx={{ mr: 1, color: grey[600] }}
                    onClick={() => setOpenHelpSystemComponent(!openHelpSystemComponent)}
                    size='small'>
            <InfoOutlinedIcon/>
        </IconButton>
    );

    return <>
        <StyledAppBar elevation={0} variant='outlined' position='fixed' theme={theme}>
            <Container maxWidth={false}>
                <Toolbar component='nav'>
                    <Grid container justifyContent='space-between' direction='row' alignItems='center'>
                        <StyledFlexGridItem item>
                            <NavLink to={routes.users.path}>
                                <Grid container alignItems='center' wrap='nowrap'>
                                    <StyledNavLogo
                                        src='/images/sftpgateway-logo-final.png'
                                        alt='SFTPgateway logo' className='logo'/>
                                    <Hidden mdDown>
                                        <StyledNavText
                                            src='/images/sftpgateway-logo-final-text.png'
                                            alt='SFTPgateway logo' className='text'/>
                                    </Hidden>
                                </Grid>
                            </NavLink>
                        </StyledFlexGridItem>

                        <Hidden lgDown>
                            <StyledFlexGridItem item>
                                <Grid container direction='row' justifyContent='center' alignItems='center'>
                                    <Grid item sm={3} xl={2}>
                                        <StyledNavLink theme={theme} to={routes.users.path}>
                                            {routes.users.menuTitle}
                                        </StyledNavLink>
                                    </Grid>
                                    <Grid item sm={3} xl={2}>
                                        <StyledNavLink theme={theme} to={routes.folders.path(0)}>
                                            {routes.folders.menuTitle}
                                        </StyledNavLink>
                                    </Grid>
                                    <Grid item sm={3} xl={2}>
                                        <StyledNavLink theme={theme} to={routes.settings.path}>
                                            {routes.settings.menuTitle}
                                        </StyledNavLink>
                                    </Grid>
                                    <Grid item sm={3} xl={2}>
                                        <StyledNavLink theme={theme} to={routes.diagnostics.path}>
                                            {routes.diagnostics.menuTitle}
                                        </StyledNavLink>
                                    </Grid>
                                </Grid>
                            </StyledFlexGridItem>
                        </Hidden>

                        <Hidden lgDown>
                            <StyledFlexGridItem item>
                                <Grid container justifyContent='flex-end'>
                                    <Grid item>
                                        {helpTextIconButton}
                                    </Grid>
                                    <Divider orientation='vertical' variant='middle' flexItem/>
                                    <Grid item sx={{ml: 1}}>
                                        <StyledMenuButton theme={theme}
                                                          aria-controls='user-menu'
                                                          aria-haspopup='true'
                                                          onClick={handleClick}
                                                          endIcon={<ArrowDropDownIcon/>}>
                                            Hi, {currentUser.username}
                                        </StyledMenuButton>
                                    </Grid>
                                    <Menu id='user-menu' anchorEl={anchorEl} keepMounted
                                          open={Boolean(anchorEl)} onClose={handleClose}
                                          anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'right'
                                          }}
                                          transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right'
                                          }}
                                    >
                                        {localStorage.getItem(KEY_USER_LOGIN_METHOD) === VALUE_USER_LOGIN_METHOD_SLINK &&
                                            <StyledChangePasswordLink theme={theme} to={routes.profile.path}>
                                                <StyledMenuItem onClick={handleClose}>
                                                    <StyledListItemIcon>
                                                        <VpnKeyIcon fontSize='small'/></StyledListItemIcon>
                                                    {routes.profile.menuTitle}
                                                </StyledMenuItem>
                                            </StyledChangePasswordLink>
                                        }
                                        <StyledMenuItem onClick={handleSignOut}>
                                            <StyledListItemIcon style={{minWidth: '32px'}}>
                                                <ExitToAppIcon fontSize='small'/></StyledListItemIcon>
                                            Log Out
                                        </StyledMenuItem>
                                    </Menu>
                                </Grid>
                            </StyledFlexGridItem>
                        </Hidden>

                        <Hidden lgUp>
                            <StyledFlexGridItem item>
                                <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                                    <Grid item>
                                        {helpTextIconButton}
                                    </Grid>
                                    <Divider orientation='vertical' variant='middle' flexItem/>
                                    <Grid item>
                                        <ResponsiveNavigation currentUser={currentUser} signOut={handleSignOut} />
                                    </Grid>
                                </Grid>
                            </StyledFlexGridItem>
                        </Hidden>
                    </Grid>
                </Toolbar>
            </Container>
        </StyledAppBar>
        <Toolbar>{/* content, do not remove, this is important for the layout */}</Toolbar>
        <HelpSystemComponent open={openHelpSystemComponent} setOpen={setOpenHelpSystemComponent}>
            <Container maxWidth={false}>
                {props.children}
            </Container>
        </HelpSystemComponent>
    </>;
}

export default Navigation;
