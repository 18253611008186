import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    useTheme
} from '@mui/material';
import PasswordField from '../common/PasswordField';
import CircularProgressButton from '../common/CircularProgressButton';
import {isEmpty} from '../../util/helpers';

function ConfirmIdentityDialog(props) {

    const theme = useTheme();

    const [password, setPassword] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.open !== open) {
            setPassword(null);
            setLoading(false);
            setOpen(props.open);
        }
    }, [props.open, open]);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleClose = () => {
        setPassword(null);
        setLoading(false);
        props.setOpen(false);
    };

    const handleConfirmIdentity = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await props.handleConfirmPassword(password);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirm Identity</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    We take your account security seriously. Please confirm your identity by providing your current
                    password. This additional step helps us verify that you are the authorized account holder.
                </DialogContentText>
                <PasswordField width={'100'} password={password} label='Current Password' required
                               autoComplete={"new-password"}
                               errorMessage={props.errors.currentPassword}
                               handleChange={handlePasswordChange} autofocus
                               onKeyDown={(e) => e.keyCode === 13 ? handleConfirmIdentity(e) : null}
                />
            </DialogContent>
            <DialogActions>
                <CircularProgressButton label='Confirm' onClick={handleConfirmIdentity} inProgress={loading}
                                        theme={theme} size='small' buttonTextTransform='none'
                                        disabled={isEmpty(password)}/>
                <CircularProgressButton label='Cancel' onClick={handleClose} theme={theme} variant='text' size='small'
                                        buttonTextTransform='none'/>
            </DialogActions>
        </Dialog>
    );
}

ConfirmIdentityDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleConfirmPassword: PropTypes.func.isRequired,
    errors: PropTypes.object
};

ConfirmIdentityDialog.defaultProps = {};

export default ConfirmIdentityDialog;
