import React, {useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Card, CardContent, Grid, Box, useTheme} from '@mui/material';
import CustomAlert from '../../common/CustomAlert';
import CustomCardHeader from '../../common/CustomCardHeader';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../../common/styled';
import IdentityProviderForm from './IdentityProviderForm';
import routes from '../../../util/routes';
import {convertErrorsToObject} from '../../../util/errorHandler';
import HelpSystemContext from '../../../context/HelpSystemContext';
import {IdentityProviderConfigInfo} from './IdentityProviderConfigInfo';

const initIdentityProvider = {
    name: '',
    notes: '',
    oidcConfigEndpoint: '',
    clientId: '',
    clientSecret: '',
};

// noinspection FunctionNamingConventionJS
function IdentityProviderCreateComponent(props) {

    const theme = useTheme();
    const history = useHistory();

    const [isBlocking, setIsBlocking] = useState(false);
    const [identityProvider, setIdentityProvider] = useState(initIdentityProvider);
    const [errors, setErrors] = useState(initIdentityProvider);

    const handleTextChange = function handleTextChangeOnForm(event) {
        setIsBlocking(true);
        setIdentityProvider({...identityProvider, [event.target.name]: event.target.value});
    };

    const onSubmit = async function createIdentityProvider(event) {
        event.preventDefault();
        try {
            await props.saveIdentityProvider(identityProvider);
            toast.notify(({onClose}) =>
                <CustomAlert message={`Created identity provider ${identityProvider.name}.`} onClose={onClose}/>);
            history.push(routes.settings.path);
        } catch (error) {
            let tempErrors = convertErrorsToObject(error.response);
            setErrors(tempErrors);
        }
    }

    const handleGoBack = function handleGoBack() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to go settings? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.push(routes.settings.path);
            }
        } else {
            history.push(routes.settings.path);
        }
    }

    const handleCancel = function handleCancel() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.push(routes.settings.path);
            }
        } else {
            history.push(routes.settings.path);
        }
    };

    // noinspection MagicNumberJS
    return (<HelpSystemContext.Consumer>
            {open => <Box sx={{p: 3}}>
                <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                    <StyledBreadcrumbLink color='inherit' onClick={handleGoBack}>
                        <StyledBreadcrumbIcon theme={theme}/>
                        Back to settings
                    </StyledBreadcrumbLink>
                </StyledBreadcrumbs>
                <Grid container>
                    <Grid item sm={12} xl={open ? 8 : 6}>
                    <Card elevation={0}>
                        <CustomCardHeader title={routes.createIdentityProvider.pageTitle}/>
                        <CardContent>
                            <IdentityProviderForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                                  name={identityProvider.name} setName={handleTextChange}
                                                  notes={identityProvider.notes} setNotes={handleTextChange}
                                                  issuerUri={identityProvider.issuerUri} setIssuerUri={handleTextChange}
                                                  clientId={identityProvider.clientId} setClientId={handleTextChange}
                                                  clientSecret={identityProvider.clientSecret}
                                                  setClientSecret={handleTextChange}
                                                  registrationId={identityProvider.registrationId}
                                                  setRegistrationId={handleTextChange}
                                                  nameAttribute={identityProvider.nameAttribute}
                                                  setNameAttribute={handleTextChange}
                                                  scopes={identityProvider.scopes} setScopes={handleTextChange}
                                                  errors={errors}/>
                        </CardContent>
                    </Card>
                    </Grid>
                <Grid item sm={12} xl={open ? 4 : 6}>
                    <IdentityProviderConfigInfo registrationId={identityProvider.registrationId}/>
                </Grid>
                </Grid>

            </Box>}
        </HelpSystemContext.Consumer>
    );
}

IdentityProviderCreateComponent.propTypes = {
    saveIdentityProvider: PropTypes.func,
};

IdentityProviderCreateComponent.defaultProps = {};

export default IdentityProviderCreateComponent;
