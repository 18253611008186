import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import toast from 'toasted-notes';
import api_routes from '../util/api_routes';
import {handleErrors} from '../util/errorHandler';
import {isEmpty} from '../util/helpers';
import httpStatus from '../util/http_status';
import CustomAlert from '../component/common/CustomAlert';
import {authenticationService} from '../container/auth/authenticationService';
import {setAuthenticated} from '../action';

const initParams = {
  // For pagination
  page: 0,
  size: 50,
  // For searching
  username: '',
};

const initDependencies = {
  triggerRefresh: false,
};

const initUserPage = {
  content: [],
  totalElements: 0,
  number: 0,
};

function useUsers({params = initParams, dependencies = initDependencies}) {

  const dispatch = useDispatch();
  const {username, size, page} = params;

  const {userSort} = useSelector(state => state);
  const {triggerRefresh} = dependencies;

  const [userPages, setUsers] = useState(initUserPage);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(function loadUsers() {
    const fetchUsersData = async function fetchUsersData() {
      try {
        // Fetch users with search and paging params
        const result = await axios.get(api_routes.user.endpoint, {
          params: {username, size, page, sortBy: userSort.by, sortOrder: userSort.order}
        });
        setUsers(result.data);
        setIsLoading(false);
      } catch (error) {
        const tempErrors = handleErrors(error.response);
        toast.notify(({onClose}) =>
            <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
        // Let the app know that this user is no longer authenticated (this will trigger the login page)
        if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
          authenticationService.clearTokenInfo();
          dispatch(setAuthenticated(false));
        }
      }
    };
    setIsLoading(true);
    // noinspection JSIgnoredPromiseFromCall
    fetchUsersData();
  }, [username, size, page, userSort, triggerRefresh, dispatch]);

  return {isLoading, userPages, userSort};
}

export default useUsers;
