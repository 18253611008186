import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DialogTitle, DialogContent, DialogActions, Box} from '@mui/material';
import FormControlInput from '../../common/FormControlInput';
import FormControlCheckbox from '../../common/FormControlCheckbox';
import CircularProgressButton from '../../common/CircularProgressButton';
import {isEmpty, isEmptyObject} from '../../../util/helpers';

// noinspection FunctionNamingConventionJS
function EditSshKeyModal(props) {
    const {updatePublicKey, handleCloseModal, publicKey, index} = props;
    const [oldPublicKey] = useState(props.publicKey);
    const [errors, setErrors] = useState({});
    const [updatedPublicKey, setUpdatedPublicKey] = useState({
        name: publicKey.name,
        enabled: publicKey.enabled,
        value: publicKey.value,
    });

    const handleTextChange = function handleTextChangeOnSSHModalForm(event) {
        event.preventDefault();
        setUpdatedPublicKey({...updatedPublicKey, [event.target.name]: event.target.value});
    };

    const handleCheckboxChange = function handleCheckboxChangeOnSSHModalForm(event) {
        setUpdatedPublicKey({...updatedPublicKey, [event.target.name]: event.target.checked});
    };

    const validateKey = function validatePublicKey(key) {
        let tempErrors = {};
        if (isEmpty(key.name)) {
            tempErrors['name'] = 'Name required.';
        }
        if (!isEmptyObject(tempErrors)) {
            throw tempErrors;
        }
    }

    const handleSaveKey = async function addPublicKeyToSSHTable(event) {
        event.preventDefault();
        try {
            validateKey(updatedPublicKey);
            await updatePublicKey(index, updatedPublicKey);
            setErrors({});
            handleCloseModal();
        } catch (errors) {
            setErrors(errors);
        }
    };

    const handleCancelKey = function cancelIpAddressUpdate() {
        setUpdatedPublicKey({
            name: oldPublicKey.name,
            enabled: oldPublicKey.enabled,
            value: oldPublicKey.value
        });
        handleCloseModal();
    };

    // noinspection MagicNumberJS
    return <Box sx={{p: 2}}>
        <DialogTitle>Edit SSH Key</DialogTitle>

        <DialogContent>
            <FormControlInput value={updatedPublicKey.name} width='100' label='Key Name' name='name'
                              removeBackground errorMessage={errors.name}
                              placeholder='Enter unique key name' onChange={handleTextChange} required/>
            <FormControlCheckbox name='enabled' checked={updatedPublicKey.enabled}
                                 onChange={handleCheckboxChange} size='small' label='Enabled'/>
            <FormControlInput value={updatedPublicKey.value} width='100' label='SSH Public Key' name='value' required
                              disabled multiline rows={8} onChange={handleTextChange}
            />
        </DialogContent>
        <DialogActions>
            <CircularProgressButton label='Save' buttonTextTransform='none' size='small' onClick={handleSaveKey}/>
            <CircularProgressButton label='Cancel' buttonTextTransform='none' onClick={handleCancelKey}
                                    size='small' variant='text'/>
        </DialogActions>
    </Box>
}

EditSshKeyModal.prototype = {
    index: PropTypes.number,
    publicKey: PropTypes.object,
    updatePublicKey: PropTypes.func,
    handleCloseModal: PropTypes.func,
};
export default EditSshKeyModal;
