import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Box, Grid, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import {useTheme} from '@mui/material/styles';
import {faExternalLinkAlt as externalLinkAlt, faFolder as solidFolderIcon} from '@fortawesome/free-solid-svg-icons';
import PageSection from '../common/PageSection';
import FormControlInput from '../common/FormControlInput';
import FormControlRadioGroup from '../common/FormControlRadioGroup';
import CircularProgressButton from '../common/CircularProgressButton';
import AdvancedSectionExpansion from '../common/AdvancedSectionExpansion';
import HomeDirectoryModal from './homeDirectoryModal/HomeDirectoryModal';
import {StyledExternalLinkIcon} from './formFields/HomeDirectory';
import PasswordSelector from './formFields/PasswordSelector';
import IpAllowListTable from './formFields/IpAllowListTable';
import {StyledRowIcon} from '../folder/FolderListComponent';
import UidAndGidHeader from './formFields/UidAndGidHeader';
import SSHKeyTable from './formFields/SSHKeyTable';
import routes from '../../util/routes';
import {getFolderPermissionString, isEmpty, isEmptyObject} from '../../util/helpers';
import HelpSystemContext from '../../context/HelpSystemContext';

function UserCreateForm(props) {
    const theme = useTheme();
    const [showHomeDirectoryModal, setShowHomeDirectoryModal] = useState(false);

    return (
        <HelpSystemContext.Consumer>
            {() =>
                <form onSubmit={props.handleSubmit}>
                    <Grid container justifyContent='flex-start'>
                        <Grid container item>
                            <FormControlInput value={props.username} width='100' label='Username'
                                              name='username' autoFocus
                                              placeholder='Enter unique username' onChange={props.setUsername}
                                              errorMessage={props.errors.username}
                            />
                        </Grid>
                        <Grid container item>
                            <FormControlInput value={props.notes} width='100' label='User Notes' name='notes'
                                              multiline onChange={props.setNotes} inputProps={{maxLength: 1000}}
                                              showCount
                                              placeholder='Enter notes about the user (optional)' onChangeReturnEvent
                                              errorMessage={props.errors.notes}
                            />
                        </Grid>


                        <Grid container item>
                            <PageSection title='Home Directory' titleVariant='body1' pb={0} mt={4}
                                         subtitle='Root location where user will land when connecting via SFTP.'
                                         subtitleVariant='body2'/>
                            <Dialog open={showHomeDirectoryModal} onClose={() => setShowHomeDirectoryModal(false)}>
                                <HomeDirectoryModal handleCloseModal={() => setShowHomeDirectoryModal(false)}
                                                    save={(event, data) => props.setHomeFolderAndPermission(data)}
                                                    currentHomeFolder={props.homeFolder.id}
                                                    currentHomeFolderPermission={props.homeFolderPermission}/>
                            </Dialog>
                            <FormControlRadioGroup name='homeDirectory' value={props.homeDirectory}
                                                   onChange={props.setHomeDirectory}
                                                   options={[{
                                                       value: '1', label: 'Create username home directory'
                                                   }, {
                                                       value: '2',
                                                       label: <Grid container direction='row' style={{marginTop: '4px'}}
                                                                    alignItems='center'>
                                                           <Grid item>
                                                               <Box mr={4}><Typography display='inline' variant='body2'>
                                                                   Select home directory</Typography>
                                                               </Box>
                                                           </Grid>
                                                           <Grid item>
                                                               <CircularProgressButton size='small' variant='outlined'
                                                                                       color='primary'
                                                                                       onClick={() => setShowHomeDirectoryModal(
                                                                                           true)}
                                                                                       label='Select Folder'
                                                                                       disabled={props.homeDirectory
                                                                                           === '1'}
                                                                                       disableElevation
                                                                                       buttonTextTransform='none'
                                                                                       mt={0}/>
                                                           </Grid>
                                                       </Grid>,
                                                       style: {alignItems: 'flex-start'}
                                                   }]}
                            />
                            {!isEmpty(props.errors.name) &&
                                <Box ml={1} mt={1} mb={1}>
                                    <Typography variant='caption' color='error'>{props.errors.name}</Typography>
                                </Box>
                            }
                            {(props.homeDirectory === '2' && !isEmptyObject(props.homeFolder) &&
                                <>
                                    <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap'
                                          style={{
                                              padding: '.875rem 1rem .25rem',
                                              backgroundColor: theme.palette.action.hover
                                          }}>
                                        <Grid item style={{flex: '1 1 auto'}}>
                                            <Box mr={4}>
                                                <Typography variant='body2' style={{wordBreak: 'break-all'}}>
                                                    <StyledRowIcon size='lg' icon={solidFolderIcon}
                                                                   color={theme.palette.primary.main}/>
                                                    <Link to={`${routes.folders.path}/${props.homeFolder?.id}`}
                                                          target='_blank'
                                                          style={{color: 'inherit', textDecoration: 'none'}}>
                                                        {props.homeFolder?.absolutePath}
                                                        <StyledExternalLinkIcon icon={externalLinkAlt}
                                                                                color={theme.palette.primary.main}/>
                                                    </Link>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item style={{flex: '0 0 auto'}}>
                                            <Typography
                                                variant='body2'>{getFolderPermissionString(
                                                props.homeFolderPermission)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap'
                                          style={{
                                              padding: '.25rem 1rem .875rem ',
                                              backgroundColor: theme.palette.action.hover
                                          }}>
                                        <Grid item width='100%'>
                                            <Typography variant={'body2'} color={'textSecondary'} style={{wordBreak: 'break-all'}}>
                                                Resolved Cloud Path: <i>{props.homeFolder?.cloudHttpPath}</i>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>)
                                || <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap'
                                         style={{
                                             padding: '.875rem 1rem',
                                             backgroundColor: theme.palette.action.hover
                                         }}>
                                        <Grid item width='100%'>
                                            <Typography variant={'body2'} color={'textSecondary'} style={{wordBreak: 'break-all'}}>
                                                Resolved Cloud Path: <i>{props.username && `${props.defaultUserFolderCloudPath}/${props.username}`}</i>
                                            </Typography>
                                        </Grid>
                                </Grid>
                            }
                        </Grid>

                        <Grid container item>
                            <SSHKeyTable publicKeys={props.publicKeys} addPublicKey={props.addPublicKey}
                                         removePublicKey={props.removePublicKey} updatePublicKey={props.updatePublicKey}
                                         creatingUser errorMessage={props.errors["publicKeys[]"]}/>
                        </Grid>

                        <Grid container item>
                            <AdvancedSectionExpansion mt={3}>
                                <Grid item>
                                    <PasswordSelector password={props.password} handleChange={props.setPassword}
                                                      errors={props.errors} creatingUser/>
                                </Grid>

                                <Grid item>
                                    <IpAllowListTable creatingUser
                                                      errorMessage={props.errors["allowedIps[]"] || props.errors.ipAllowListEnabled}
                                                      allowedIps={props.allowedIps} addIpAddress={props.addIpAddress}
                                                      removeIpAddress={props.removeIpAddress}
                                                      ipAllowListEnabled={props.ipAllowListEnabled}
                                                      setIpAllowListEnabled={props.setIpAllowListEnabled}
                                    />
                                </Grid>

                                <Grid item>
                                    <UidAndGidHeader/>
                                    <Grid item sm={12} md={6}>
                                        <FormControlInput value={props.uid} width='100' label='UID' name='uid'
                                                          type='number' onChange={props.setUid}
                                                          helperText='Leave blank to allow a number to be generated automatically or provide your own value.'
                                                          errorMessage={props.errors.uid}/>
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <FormControlInput value={props.gid} width='100' label='GID' name='gid'
                                                          type='number' onChange={props.setGid}
                                                          helperText='Leave blank to allow a number to be generated automatically or provide your own value.'
                                                          errorMessage={props.errors.gid}/>
                                    </Grid>
                                </Grid>
                            </AdvancedSectionExpansion>
                            <Grid container item justifyContent='flex-end' sx={{mt: 2}}>
                                <CircularProgressButton theme={theme} type='submit' size='small'
                                                        label='Save' mt={2} mr={1}/>
                                <CircularProgressButton onClick={props.handleCancel} size='small'
                                                        label='Cancel' mt={2} variant='text'/>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            }
        </HelpSystemContext.Consumer>
    );
}

UserCreateForm.propTypes = {
    username: PropTypes.string,
    notes: PropTypes.string,
    homeDirectory: PropTypes.string,
    publicKeys: PropTypes.array,
    password: PropTypes.string,
    ipAddresses: PropTypes.array,
    setUsername: PropTypes.func,
    setNotes: PropTypes.func,
    setHomeDirectory: PropTypes.func,
    addPublicKey: PropTypes.func,
    removePublicKey: PropTypes.func,
    updatePublicKey: PropTypes.func,
    setPassword: PropTypes.func,
    addIpAddress: PropTypes.func,
    removeIpAddress: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    deleteUser: PropTypes.func,
    homeFolder: PropTypes.object,
    setHomeFolderAndPermission: PropTypes.func,
    defaultUserFolderCloudPath: PropTypes.string,
};

UserCreateForm.defaultProps = {};

export default UserCreateForm;
