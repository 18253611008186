import React, {useState} from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import {useHistory, useParams} from 'react-router-dom';
import useFolder from '../../api/useFolder';
import {isEmpty} from '../../util/helpers';
import api_routes from '../../util/api_routes';
import FolderListComponent from '../../component/folder/FolderListComponent';
import CustomAlert from '../../component/common/CustomAlert';

function FolderListContainer() {
    useHistory();
    const [triggerRefresh, setRefresh] = useState(false);
    const [searchBox, setSearchBox] = useState('');

    let {folderId} = useParams();
    // Redirect to the root folder if no folder provided.
    if (isEmpty(folderId)) {
        folderId = 0;
    }

    const refresh = function refreshListOfFolders() {
        setRefresh(!triggerRefresh);
    };

    // Fetch folder by id and include sub folders.
    const {isLoading, folder} = useFolder({
        params: {folderId, includeSubFolders: true, searchString: searchBox},
        dependencies: {triggerRefresh}
    });

    const deleteFolder = async function deleteFolderOnFolderListPage(folderToDelete) {
        if (!isEmpty(folderToDelete.folders) && folderToDelete.folders.length !== 0) {
            toast.notify(({onClose}) => <CustomAlert type='error'
                                                     message={'Cannot delete folder with subfolders.'}
                                                     onClose={onClose}/>);
            return false;
        } else {
            try {
                await axios.delete(`${api_routes.folders.endpoint}/${folderToDelete.id}`);
                toast.notify(({onClose}) => <CustomAlert
                    message={`Deleted folder ${folderToDelete.name}.`} onClose={onClose}/>);
            } catch (error) {
                toast.notify(({onClose}) => <CustomAlert
                    type='error' message={error.response.data.message || `Could not delete folder ${folderToDelete.name}.`} onClose={onClose}/>);
                return false;
            }
            return true;
        }
    };

    return (<FolderListComponent folder={folder} loadingFolders={isLoading}
                                 searchBox={searchBox} setSearchBox={setSearchBox}
                                 refresh={refresh}
                                 deleteFolder={deleteFolder}/>);
}

export default FolderListContainer;
