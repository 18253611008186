import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Publish} from '@mui/icons-material';
import {Grid, InputAdornment} from '@mui/material';
import {isEmpty} from '../../../util/helpers';
import TestConnectionResultTable from './TestConnectionResultTable';
import PageSection from '../../common/PageSection';
import InlineEditFile from '../../common/InlineEditFile';
import InlineEditTextField from '../../common/InlineEditTextField';
import FormControlRadioGroup from '../../common/FormControlRadioGroup';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import HelpSystemContext from '../../../context/HelpSystemContext';
import identityOptions from './InstanceProfileOption';

// noinspection FunctionNamingConventionJS
function GoogleCloudConnectionEditForm(props) {
    const [errors, setErrors] = useState(props.errors);
    let [localToken, setLocalToken] = useState({
        jwtToken: '',
        jsonKeyFilename: '',
    });

    const [showSavedMessage, setShowSavedMessage] = useState(false);

    useEffect(() => {
        setErrors(props.errors);
    }, [props.errors]);

    const handleKeyUpdate = async (inputFile) => {
        return await new Promise(function (resolve, reject) {
            const reader = new FileReader();
            reader.onload = function readOnLoad(e) {
                let contents = e.target.result;
                let jwtToken = Buffer.from(contents).toString('base64')
                let jsonKeyFilename = inputFile.name
                localToken = {jwtToken, jsonKeyFilename}
                setLocalToken(prevState => ({...prevState, ...localToken}));
                resolve()
            };
            if (!isEmpty(inputFile)) {
                reader.readAsBinaryString(inputFile);
            } else {
                reject()
            }
        });
    };

    const handleInstanceCredentials = async (event) => {
        event.preventDefault();
        await props.handleSubmit(event, {...localToken, useInstanceCredentials: false});
    };

    const updateInstanceCredentialsType = async function updateInstanceCredentialsType(event) {
        props.setInstanceCredentials(event);
        await props.handleSubmit(event, {useInstanceCredentials: event.target.value});
        setShowSavedMessage(true);
    };

    return (
        <HelpSystemContext.Consumer>
            {() =>
                <form>
                    <Grid container justifyContent='flex-start'>
                        <Grid container item>
                            <InlineEditTextField name='name' label='Connection Name' labelVariant='body1'
                                                 value={props.name}
                                                 save={(event, data) => props.handleSubmit(event, data)}
                                                 helperText='Name used to identify this cloud connection.'
                                                 placeholder='Enter unique name' required
                                                 updateMessage='Saved' errorMessage={errors.name}/>
                        </Grid>
                        <Grid container item>
                            <InlineEditTextField name='notes' label='Cloud Connection Notes' labelVariant='body1'
                                                 value={props.notes}
                                                 save={(event, data) => props.handleSubmit(event, data)}
                                                 placeholder='Enter notes about the cloud connection (optional)'
                                                 multiline showCount inputProps={{maxLength: 1000}}
                                                 updateMessage='Saved' errorMessage={errors.notes}/>
                        </Grid>
                        <Grid container item>
                            <InlineEditTextField name='basePrefix' label='GCS Bucket URI' labelVariant='body1'
                                                 value={props.basePrefix}
                                                 autoComplete="off"
                                                 save={(event, data) => props.handleSubmit(event, data)} required
                                                 placeholder='Enter GsUtil URI for GCP Bucket'
                                                 helperText='Ex: gs://bucket-name'
                                                 errorMessage={errors.basePrefix} updateMessage='Saved' copyField={true}
                            />
                        </Grid>
                        <Grid container item>
                            <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                                         subtitle='Credentials used to access this cloud connection.'
                                         subtitleVariant='body2'
                                         action={<Grid container justifyContent='center'>
                                             <SuccessMessageComponent show={showSavedMessage}
                                                                      setShow={setShowSavedMessage} mr={2}/>
                                         </Grid>}
                            />
                            <FormControlRadioGroup name='useInstanceCredentials' value={props.useInstanceCredentials}
                                                   onChange={updateInstanceCredentialsType}
                                                   options={[
                                                       ...(props.showInstanceCredentialsOption ?
                                                           [identityOptions.gcp] :
                                                           []),
                                                       {value: false, label: 'Use JSON Key File'}
                                                   ]}
                            />
                        </Grid>
                        {!props.useInstanceCredentials &&
                            <Grid container item>
                                <InlineEditFile
                                    labelVariant='body1'
                                    name='jwtToken' label='Upload Service Account JSON Key File'
                                    displayValue={props.jsonKeyFilename}
                                    required={!props.useInstanceCredentials}
                                    uncontrolled
                                    emptyText={(props.jwtTokenSet) ? 'Key is Set' : "No Key Set"}
                                    autoComplete="off"
                                    helpLink={{
                                        href: 'http://www.sftpgateway.com/google-service-account-key-file.html',
                                        target: '_blank',
                                        text: 'Where do I find the Service Account JSON Key File?'
                                    }}
                                    onChange={handleKeyUpdate}
                                    type='file' updateMessage='Saved'
                                    inputProps={{accept: '.json'}}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Publish/>
                                        </InputAdornment>
                                    }
                                    save={(event, data) => handleInstanceCredentials(event, data)}
                                    errorMessage={errors.connectionString}/>
                            </Grid>
                        }
                        {!isEmpty(props.connectivity) &&
                            <Grid container item>
                                <TestConnectionResultTable connectivity={props.connectivity} connectionId={props.id}
                                                           setErrors={props.setErrors}
                                />
                            </Grid>
                        }
                    </Grid>
                </form>
            }
        </HelpSystemContext.Consumer>
    );
}

GoogleCloudConnectionEditForm.propTypes = {
    name: PropTypes.string,
    notes: PropTypes.string,
    basePrefix: PropTypes.string,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    connectivity: PropTypes.object,
    useInstanceCredentials: PropTypes.bool,
    setInstanceCredentials: PropTypes.func,
    showInstanceCredentialsOption: PropTypes.bool,
};
GoogleCloudConnectionEditForm.defaultProps = {};
export default GoogleCloudConnectionEditForm;
