import React from 'react';
import DiagnosticsComponent from '../../component/diagnostics/DiagnosticsComponent';

// noinspection FunctionNamingConventionJS
function DiagnosticsContainer() {
    return (<DiagnosticsComponent />);
}

DiagnosticsContainer.propTypes = {};

DiagnosticsContainer.defaultProps = {};

export default DiagnosticsContainer;
