import React from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import UserUpdateComponent from '../../component/user/UserUpdateComponent';
import useUser from '../../api/useUser';
import api_routes from '../../util/api_routes';

// noinspection FunctionNamingConventionJS
function UserUpdateContainer() {

    const params = useParams();

    const {userId} = params;
    const {isLoading, user} = useUser({ params: { userId } });

    const saveUser = async function updateSftpUser(data) {
        return await axios.put(`${api_routes.user.endpoint}/${userId}`, data);
    };

    return <UserUpdateComponent loadingUser={isLoading} user={user} saveUser={saveUser} />;
}

export default UserUpdateContainer;
