import React from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import FolderCreateComponent from '../../component/folder/FolderCreateComponent';
import {isEmpty} from '../../util/helpers';
import api_routes from '../../util/api_routes';
import useCloudConnections from '../../api/useCloudConnections';

// noinspection FunctionNamingConventionJS
function FolderCreateContainer() {

    let {folderId} = useParams();
    if (isEmpty(folderId)) {
        folderId = 0;
    }

    const {isLoading: loadingCloudConnections, cloudConnections} = useCloudConnections();

    const save = async function createFolder(folder) {
        await axios.post(api_routes.folders.endpoint, {parentFolderId: parseInt(folderId), ...folder});
    };

    return (<FolderCreateComponent cloudConnections={cloudConnections}
                                   loadingCloudConnections={loadingCloudConnections}
                                   createFolder={save}/>);
}

export default FolderCreateContainer;
