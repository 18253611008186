import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Grid, Box, useTheme} from '@mui/material';
import CustomAlert from '../../common/CustomAlert';
import CustomCardHeader from '../../common/CustomCardHeader';
import ProgressFormCard from '../../common/ProgressFormCard';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../../common/styled';
import {authenticationService} from '../../../container/auth/authenticationService';
import routes from '../../../util/routes';
import {isEmpty} from '../../../util/helpers';
import httpStatus from '../../../util/http_status';
import {ALIBABA, AWS, AZURE, GCP} from '../../../util/cloud_providers';
import {convertErrorsToObject, handleErrors} from '../../../util/errorHandler';
import {setAuthenticated} from '../../../action';
import {getContainerUrl} from './AzureCloudConnectionForm';
import AwsCloudConnectionEditForm from './AwsCloudConnectionEditForm';
import AzureCloudConnectionEditForm from './AzureCloudConnectionEditForm';
import GoogleCloudConnectionEditForm from './GoogleCloudConnectionEditForm';
import AlibabaCloudConnectionEditForm from './AlibabaCloudConnectionEditForm';
import HelpSystemContext from '../../../context/HelpSystemContext';

const {cloudProvider} = window._env_ || {};
const CLOUD_PROVIDER = cloudProvider || process.env.REACT_APP_CLOUD_PROVIDER;

// noinspection FunctionNamingConventionJS
function CloudConnectionUpdateComponent(props) {
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [isBlocking, setIsBlocking] = useState(false);
    const [cloudConnection, setCloudConnection] = useState(props.cloudConnection);
    const [errors, setErrors] = useState({});

    const provider = cloudConnection.cloudProvider;
    const providerTitle = () => {
        if (!isEmpty(provider)) {
            switch (provider) {
                case AWS:
                    return 'AWS';
                case AZURE:
                    return 'Azure';
                case GCP:
                    return 'Google';
                case ALIBABA:
                    return 'Alibaba';
                default: {
                    console.error("Unsupported Cloud Provider")
                    return 'Unsupported';
                }
            }
        }
    };

    // Update the state from updated props.
    useEffect(() => {
        setCloudConnection(props.cloudConnection);
    }, [props.cloudConnection]);

    const handleTextChange = function handleTextChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.value});
    };

    const handleInstanceCredentialsChange = function handleInstanceCredentialsChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.value === "true"});
    };

    const onSubmit = async function updateCloudConnection(event, data) {
        event.preventDefault();
        try {
            const response = await props.updateCloudConnection(data);
            setCloudConnection(response.data);
        } catch (error) {
            if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
                const tempErrors = handleErrors(error.response);
                toast.notify(({onClose}) =>
                    <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
                authenticationService.clearTokenInfo();
                dispatch(setAuthenticated(false));
            } else {
                let tempErrors = convertErrorsToObject(error.response);
                let newErrors = {...errors, ...tempErrors};
                setErrors(newErrors);
                throw (newErrors);
            }
        }
    };

    const updateBasePrefixBasedOnAccountInfo = async (event, azureAccountInfo) => {
        const accountName = azureAccountInfo.accountName;
        const containerName = azureAccountInfo.containerName;
        const endpoint = azureAccountInfo.endpoint;
        if (!isEmpty(containerName) && !isEmpty(accountName) && !isEmpty(endpoint)) {
            const regexp = /^[a-z0-9](?!.*--)[a-z0-9-]{1,61}[a-z0-9](\/.*)?$/g;
            if (containerName.match(regexp) === null) {
                let tempErrors = {
                    ...errors, containerName: "Please enter a valid prefix that begins with a letter " +
                        "or number with a container name at most 63 characters long. The container name can contain only lowercase letters, " +
                        "numbers, and hyphens. Hyphens must not be consecutive. Optionally, include container path after a '/'."
                };
                setErrors(tempErrors);
                throw (tempErrors);
            } else {
                let tempErrors = {...errors, containerName: ""};
                setErrors(tempErrors);
                let containerUrl = getContainerUrl(accountName, endpoint, containerName);
                if (containerUrl !== cloudConnection.basePrefix) {
                    await onSubmit(event, {basePrefix: containerUrl});
                }
            }
        }
    }

    const handleCancel = function handleCancel() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.push(routes.settings.path);
            }
        } else {
            history.push(routes.settings.path);
        }
    };

    // noinspection ConditionalExpressionJS
    return (<HelpSystemContext.Consumer>
            {() => <Box sx={{p: 3}}>
                <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                    <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.settings.path)}>
                        <StyledBreadcrumbIcon theme={theme}/>
                        Back to settings
                    </StyledBreadcrumbLink>
                </StyledBreadcrumbs>
                <Grid item sm={12}>
                    <ProgressFormCard loadingContent={props.loadingCloudConnection}
                                      header={<CustomCardHeader
                                          title={routes.updateCloudConnection.pageTitle(providerTitle())}/>}
                                      content={<>
                                          {provider === AWS &&
                                              <AwsCloudConnectionEditForm handleSubmit={onSubmit}
                                                                          handleCancel={handleCancel}
                                                                          id={cloudConnection.id}
                                                                          name={cloudConnection.name}
                                                                          cloudProvider={cloudConnection.cloudProvider}
                                                                          notes={cloudConnection.notes}
                                                                          basePrefix={cloudConnection.basePrefix}
                                                                          region={cloudConnection.region}
                                                                          encryptionType={cloudConnection.encryptionType}
                                                                          setEncryptionType={handleTextChange}
                                                                          encryptionId={cloudConnection.encryptionId}
                                                                          useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                                                          setInstanceCredentials={handleInstanceCredentialsChange}
                                                                          keyId={cloudConnection.accessKeyId}
                                                                          keySecret={cloudConnection.accessSecret}
                                                                          accessSecretSet={cloudConnection.accessSecretSet}
                                                                          connectivity={cloudConnection.connectivity}
                                                                          errors={errors}
                                                                          setErrors={setErrors}
                                                                          showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                              />}
                                          {provider === AZURE &&
                                              <AzureCloudConnectionEditForm handleSubmit={onSubmit}
                                                                            handleCancel={handleCancel}
                                                                            id={cloudConnection.id}
                                                                            name={cloudConnection.name}
                                                                            cloudProvider={cloudConnection.cloudProvider}
                                                                            notes={cloudConnection.notes}
                                                                            basePrefix={cloudConnection.basePrefix}
                                                                            updateBasePrefixBasedOnAccountInfo={updateBasePrefixBasedOnAccountInfo}
                                                                            region={cloudConnection.region}
                                                                            hnsEnabled={cloudConnection.hnsEnabled}
                                                                            useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                                                            setInstanceCredentials={handleInstanceCredentialsChange}
                                                                            accountName={cloudConnection.accountName}
                                                                            connectionString={cloudConnection.connectionString}
                                                                            connectionStringSet={cloudConnection.connectionStringSet}
                                                                            connectivity={cloudConnection.connectivity}
                                                                            errors={errors} setErrors={setErrors}
                                                                            showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                              />}
                                          {provider === GCP &&
                                              <GoogleCloudConnectionEditForm handleSubmit={onSubmit}
                                                                             handleCancel={handleCancel}
                                                                             id={cloudConnection.id}
                                                                             name={cloudConnection.name}
                                                                             cloudProvider={cloudConnection.cloudProvider}
                                                                             notes={cloudConnection.notes}
                                                                             basePrefix={cloudConnection.basePrefix}
                                                                             jwtToken={cloudConnection.jwtToken}
                                                                             jsonKeyFilename={cloudConnection.jsonKeyFilename}
                                                                             jwtTokenSet={cloudConnection.jwtTokenSet}
                                                                             useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                                                             setInstanceCredentials={handleInstanceCredentialsChange}
                                                                             errors={errors} setErrors={setErrors}
                                                                             connectivity={cloudConnection.connectivity}
                                                                             showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                              />}
                                          {provider === ALIBABA &&
                                              <AlibabaCloudConnectionEditForm handleSubmit={onSubmit}
                                                                              handleCancel={handleCancel}
                                                                              errors={errors} setErrors={setErrors}
                                                                              cloudConnection={cloudConnection}
                                                                              showInstanceCredentialsOption={provider === CLOUD_PROVIDER}
                                                                              setInstanceCredentials={handleInstanceCredentialsChange}

                                              />}
                                      </>
                                      }
                    />
                </Grid>
            </Box>}
        </HelpSystemContext.Consumer>
    );
}

CloudConnectionUpdateComponent.propTypes = {
    cloudConnection: PropTypes.object,
    updateCloudConnection: PropTypes.func,
    loadingCloudConnection: PropTypes.bool,
};

CloudConnectionUpdateComponent.defaultProps = {};

export default CloudConnectionUpdateComponent;
