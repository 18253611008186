import React from 'react';
import ChangePasswordComponent from '../../component/user/ChangePasswordComponent';
import axios from 'axios';
import api_routes from '../../util/api_routes';

// noinspection FunctionNamingConventionJS
function ChangePasswordContainer() {
  
  const changePassword = function changePassword({currentPassword, newPassword}) {
      return axios.patch(`${api_routes.adminUserPassword.endpoint}`, {currentPassword, newPassword});
  };
  
  return <ChangePasswordComponent changePassword={changePassword} />;
}

export default ChangePasswordContainer;
