import { useEffect, useState } from 'react';
import axios from 'axios';
import api_routes from '../util/api_routes';

const initParams = {
    startDate: 0,
    endDate: 0,
    nextToken: '',
};

const initDependencies = {
};

const initApplicationLogs = [];

const initError = {
    status: 0,
    message: '',
};

function useApplicationLogs({params = initParams, dependencies = initDependencies}) {

    const [logs, setLogs] = useState(initApplicationLogs);
    const [logsFetchedForStartDate, setLogsFetchedForStartDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [nextBackwardToken, setBackwardToken] = useState(null);
    const [nextForwardToken, setForwardToken] = useState(null);
    const [error, setError] = useState(initError);

    const { startDate, endDate, nextToken } = params;

    useEffect(function applicationLogs() {
        const fetchData = async function fetchData() {
            try {
                const result = await axios.get(`${api_routes.cloudLogs.endpoint}/application`, {
                    params: {
                        startTime: startDate,
                        endTime: endDate,
                        nextToken: nextToken,
                    }
                });
                setLogsFetchedForStartDate(startDate);
                setLogs(result.data.logs);
                setBackwardToken(result.data.nextBackwardToken);
                setForwardToken(result.data.nextForwardToken);
            } catch (error) {
                if (error.response) {
                    setError({
                        status: error.response.status,
                        message: error.response.data.message
                    });
                }
            } finally {
                setIsLoading(false);
            }
        };

        setError(initError);
        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchData();
    }, [startDate, endDate, nextToken]);

    return {isLoading, logs, logsFetchedForStartDate, nextBackwardToken, nextForwardToken, error};
}

export default useApplicationLogs;
