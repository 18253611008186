import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Container, Grid, Toolbar, Typography } from '@mui/material';

function FooterComponent() {
    const systemInfo = useSelector(function getSystemInfo(state) {
        return state.systemInfo;
    });

    return (
        <div style={{backgroundColor: 'inherit', textAlign: 'center'}}>
            <Container maxWidth="md">
                <Toolbar>
                    <Grid container direction='column' justifyContent={'center'}>
                        <Typography variant='subtitle2' color='textSecondary' style={{ wordBreak: 'break-word' }}>
                            Version {systemInfo.activeVersion}
                        </Typography>
                        <Typography variant='subtitle2' color='textSecondary'>
                            Copyright © {moment().year()} Thorn Technologies LLC. All Rights Reserved.
                        </Typography>
                    </Grid>
                </Toolbar>
            </Container>
        </div>
    );
}

export default FooterComponent;
