import React, {useCallback, useEffect, useState} from 'react';
import zxcvbn from 'zxcvbn';
import styled from 'styled-components';
import {useTheme} from '@mui/material/styles';
import {Box, Grid, Typography} from '@mui/material';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {isEmpty} from '../../util/helpers';

const BorderLinearProgress = styled(LinearProgress)(({theme, progresscolor}) => ({
    height: 7,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: progresscolor
    },
}));

const PasswordStrengthMeter = ({password}) => {
    const theme = useTheme();
    const testResult = zxcvbn(password);
    const num = testResult.score * 25;
    const [colorAndLabel, setColorAndLabel] = useState({color: 'none', label: '', labelColor: 'none'});

    const getColorAndLabel = useCallback(() => {
        switch (testResult.score) {
            case 0:
                return { color: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800], label: 'Very Weak', labelColor: '#828282' };
            case 1:
                return { color: '#EA1111', label: 'Weak', labelColor: '#EA1111'};
            case 2:
                return { color: '#FFAD00', label: 'Fair', labelColor: '#FFAD00'};
            case 3:
                return { color: '#9bc158', label: 'Good', labelColor: '#9bc158'};
            case 4:
                return { color: '#00b500', label: 'Strong', labelColor: '#00b500'};
            default:
                return { color: 'none', label: '', labelColor: 'none'};
        }
    }, [testResult.score, theme.palette.grey, theme.palette.mode]);

    useEffect(() => {
        setColorAndLabel(getColorAndLabel());
    }, [password, getColorAndLabel]);

    return !isEmpty(password) ?
        (<Grid justifyContent='flex-end' sx={{ width: '100%' }}>
            <Box>
                <BorderLinearProgress variant="determinate" value={num} theme={theme}
                                      progresscolor={colorAndLabel.color}/>
            </Box>
            <Box sx={{textAlign: 'right'}}>
                <Typography variant='body2' style={{color: colorAndLabel.labelColor}}>
                    {colorAndLabel.label}
                </Typography>
            </Box>
        </Grid>)
        :
        null;
}

export default PasswordStrengthMeter
