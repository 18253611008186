import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {Grid, Typography, Box, Menu, MenuItem, FormGroup, ListItemIcon} from '@mui/material';
import PageLoader from '../../common/PageLoader';
import PageSection from '../../common/PageSection';
import FormControlCheckbox from '../../common/FormControlCheckbox';
import CircularProgressButton from '../../common/CircularProgressButton';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import api_routes from '../../../util/api_routes';
import useEncryptionAlgorithms from '../../../api/useEncryptionAlgorithms';

const options = {
    viewing: {label: 'Viewing', icon: <VisibilityOutlinedIcon fontSize='small' color='text' />},
    editing: {label: 'Editing', icon: <EditIcon fontSize='small' color='text' />},
}

function EncryptionAlgorithmComponent(props) {

    const {triggerRefresh, refresh} = props;
    const {
        isLoadingSupportedAlgos: loadingSupportedAlgorithms, supportedAlgorithms,
        isLoadingEnabledAlgos: loadingEnabledAlgorithms, enabledAlgorithms
    } = useEncryptionAlgorithms({dependencies: {triggerRefresh}});
    const [selectedItems, setSelectedItems] = useState(enabledAlgorithms);
    const [changeDisabled, setChangeDisabled] = useState(true);
    const [showSavedMessage, setShowSavedMessage] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [modeButtonLabel, setModeButtonLabel] = useState(options.viewing.label);
    const [anchorEl, setAnchorEl] = useState(null);

    const systemInfo = useSelector(function getSystemInfo(state) {
        return state.systemInfo;
    });

    const handleMenuItemClick = (event, label) => {
        setAnchorEl(null);
        setModeButtonLabel(label);
        switch (label) {
            case options.viewing.label:
                setEditMode(false);
                break;
            case options.editing.label:
                setEditMode(true);
                break;
            default:
                break;
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (systemInfo.securityLevel === 'CUSTOM') {
            setChangeDisabled(false);
        }
        setSelectedItems(enabledAlgorithms);
    }, [enabledAlgorithms, systemInfo.securityLevel]);

    const handleCheckboxChange = async (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
            await axios.put(`${api_routes.encryptionAlgorithms.endpoint}/disable`, item,
                {headers: {'Content-Type': 'text/plain'}});

        } else {
            setSelectedItems([...selectedItems, item]);
            await axios.put(`${api_routes.encryptionAlgorithms.endpoint}/enable`, item,
                {headers: {'Content-Type': 'text/plain'}});
        }
        setShowSavedMessage(true);
    };

    const handleResetToDefault = async () => {
        await axios.put(`${api_routes.encryptionAlgorithms.endpoint}/reset`);
        refresh();
    }

    return (<>
        <PageSection title='Encryption Algorithms'
                     subtitle='Supported Encryption Algorithms. To customize encryption algorithm options, switch to
                     Editing mode. These algorithms will be enabled for future SFTP sessions.'
                     action={<Grid container alignItems='center'>
                         <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>
                         <Grid item>
                             <CircularProgressButton label={modeButtonLabel} size='small' mt={0}
                                                     aria-controls='ecnryption-algos-mode-menu' aria-haspopup='true'
                                                     onClick={(event) => setAnchorEl(event.currentTarget)}
                                                     endIcon={<ArrowDropDownIcon/>} buttonTextTransform='none'
                             />
                             <Menu
                                 id='alogs-mode-menu'
                                 anchorEl={anchorEl}
                                 open={Boolean(anchorEl)}
                                 onClose={handleClose}
                                 MenuListProps={{'aria-labelledby': 'alogs-mode-menu', role: 'listbox'}}
                             >
                                 <MenuItem
                                     key={'v'}
                                     disabled={options.viewing.label === modeButtonLabel}
                                     selected={options.viewing.label === modeButtonLabel}
                                     onClick={(event) => handleMenuItemClick(event, options.viewing.label)}
                                 >
                                     <ListItemIcon>{options.viewing.icon}</ListItemIcon> {options.viewing.label}
                                 </MenuItem>
                                 <MenuItem
                                     key={'e'}
                                     disabled={options.editing.label === modeButtonLabel}
                                     selected={options.editing.label === modeButtonLabel}
                                     onClick={(event) => handleMenuItemClick(event, options.editing.label)}
                                 >
                                     <ListItemIcon>{options.editing.icon}</ListItemIcon> {options.editing.label}
                                 </MenuItem>
                             </Menu>
                         </Grid>
                         <Grid item>
                             <CircularProgressButton label={'Reset to Default'} size='small' mt={0} ml={1}
                                                     onClick={handleResetToDefault}
                                                     buttonTextTransform='none'
                             />
                         </Grid>
                     </Grid>
                     }
        />
        {(loadingSupportedAlgorithms || loadingEnabledAlgorithms) && <PageLoader/>}
        {!loadingSupportedAlgorithms && !loadingEnabledAlgorithms &&
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
                {supportedAlgorithms.map(cipher => (
                    <Grid item md={3} key={cipher.name}>
                        <Box sx={{mb: 2}}>
                            <Typography variant='h6'>{cipher.name}</Typography>
                            <Typography variant='overline'>Paranoid</Typography>
                            <FormGroup>
                                {cipher.paranoidAlgorithms.map((algorithm, itemIndex) => (
                                    <FormControlCheckbox
                                        mt={0}
                                        key={itemIndex}
                                        checked={selectedItems.includes(algorithm)}
                                        onChange={() => handleCheckboxChange(algorithm)}
                                        value={algorithm}
                                        label={algorithm}
                                        disabled={changeDisabled || !editMode}
                                    />
                                ))}
                            </FormGroup>

                            <Typography variant='overline'>Strong</Typography>
                            <FormGroup>
                                {cipher.strongAlgorithms.map((algorithm, itemIndex) => (
                                    <FormControlCheckbox
                                        mt={0}
                                        key={itemIndex}
                                        checked={selectedItems.includes(algorithm)}
                                        onChange={() => handleCheckboxChange(algorithm)}
                                        value={algorithm}
                                        label={algorithm}
                                        disabled={changeDisabled || !editMode}
                                    />
                                ))}
                            </FormGroup>

                            <Typography variant='overline'>Weak</Typography>
                            <FormGroup>
                                {cipher.weakAlgorithms.map((algorithm, itemIndex) => (
                                    <FormControlCheckbox
                                        mt={0}
                                        key={itemIndex}
                                        checked={selectedItems.includes(algorithm)}
                                        onChange={() => handleCheckboxChange(algorithm)}
                                        value={algorithm}
                                        label={algorithm}
                                        disabled={changeDisabled || !editMode}
                                    />
                                ))}
                            </FormGroup>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        }
    </>);
}

export default EncryptionAlgorithmComponent;
