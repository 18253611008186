import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import toast from 'toasted-notes';
import axios from 'axios';
import _ from 'lodash';
import api_routes from '../util/api_routes';
import {handleErrors} from '../util/errorHandler';
import {isEmpty} from '../util/helpers';
import httpStatus from '../util/http_status';
import {authenticationService} from '../container/auth/authenticationService';
import CustomAlert from '../component/common/CustomAlert';
import {setAuthenticated} from '../action';

const initParams = {
    // Folder id to fetch by
    folderId: 0,
    // If true, include sub folders
    includeSubFolders: false,
    // Search all folders by absolutePath
    searchString: '',
};

const initDependencies = {
    triggerRefresh: false,
}

const initFolder = {};

function useFolder({params = initParams, dependencies = initDependencies}) {

    const dispatch = useDispatch();

    const { folderId, includeSubFolders, searchString } = params;
    const { triggerRefresh } = dependencies;

    const [folder, setFolder] = useState(initFolder);
    const [isLoading, setIsLoading] = useState(true);

    const debounceSearch = useRef(
        _.debounce(async function debounceSearch(searchTerm) {
            const filteredFolders = await axios.get(`${api_routes.folders.endpoint}`, {
                params: { searchString: searchTerm }
            });
            setFolder({folders: filteredFolders.data});
            setIsLoading(false);
        }, 500)
    );

    useEffect(function loadFolder() {
        const fetchFolderData = async function fetchFolderData() {

            // If search string is provided, we are going to search all folders
            if (!isEmpty(searchString)) {
                debounceSearch.current(searchString);
            } else if (!isEmpty(folderId)) {
                debounceSearch.current.cancel();
                try {
                    const result = await axios.get(`${api_routes.folders.endpoint}/${folderId}`, {
                        params: { includesubfolders: includeSubFolders }
                    });
                    setFolder(result.data);
                    setIsLoading(false);
                } catch (error) {
                    const tempErrors = handleErrors(error.response);
                    toast.notify(({onClose}) =>
                        <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
                    // Let the app know that this user is no longer authenticated (this will trigger the login page)
                    if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
                        authenticationService.clearTokenInfo();
                        dispatch(setAuthenticated(false));
                    }
                }
            }
        };

        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchFolderData();
    }, [folderId, includeSubFolders, searchString, triggerRefresh, dispatch]);

    return {isLoading, folder};
}

export default useFolder;
