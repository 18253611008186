import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Grid } from '@mui/material';
import CustomAlert from '../common/CustomAlert';
import PasswordField from '../common/PasswordField';
import CustomCardHeader from '../common/CustomCardHeader';
import CircularProgressButton from '../common/CircularProgressButton';
import routes from '../../util/routes';
import httpStatus from '../../util/http_status';
import { convertErrorsToObject } from '../../util/errorHandler';
import { setAuthenticated, setCurrentUser } from '../../action';
import HelpSystemContext from '../../context/HelpSystemContext';

// noinspection FunctionNamingConventionJS
function ChangePasswordComponent(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [confirmPasswordError, setConfirmPasswordError] = useState(
        {error: false, errorMessage: ''});
    const [currentPasswordError, setCurrentPasswordError] = useState(
        {error: false, errorMessage: ''});
    const [newPasswordError, setNewPasswordError] = useState(
        {error: false, errorMessage: ''});
    const [isLoading, setIsLoading] = useState(false);
    const [changePasswordForm, setChangePasswordForm] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    const handleChange = function updateChangePasswordFormFields(event) {
        setChangePasswordForm(
            {...changePasswordForm, [event.target.name]: event.target.value})
    };

    const handleCancel = function handleResettingChangePasswordForm() {
        history.goBack();
    };

    const handleSubmit = async function submitChangePassword(event) {
        event.preventDefault();
        if (changePasswordForm.newPassword === changePasswordForm.confirmNewPassword) {
            setConfirmPasswordError({error: false, errorMessage: ''});
            setIsLoading(true);
            try {
                await props.changePassword({
                    currentPassword: changePasswordForm.currentPassword,
                    newPassword: changePasswordForm.newPassword
                });
                toast.notify(
                    ({onClose}) => <CustomAlert message='Success! Please login with your new password!'
                                                onClose={onClose}/>);
                let user = {username: ''};
                // noinspection NestedFunctionCallJS
                dispatch(setCurrentUser(user));
                // noinspection NestedFunctionCallJS
                dispatch(setAuthenticated(false));
                history.push(routes.home.path);
            } catch (error) {
                setIsLoading(false);
                if (error.response.status === httpStatus.unAuthorized) {
                    setCurrentPasswordError({error: true, errorMessage: 'Wrong password. Try again.'})
                } else if (error.response.status === httpStatus.badRequest) {
                    setCurrentPasswordError({error: false, errorMessage: ''});
                    let tempErrors = convertErrorsToObject(error.response);
                    setNewPasswordError({error: true, errorMessage: tempErrors?.newPassword});
                }
            }
        } else {
            setCurrentPasswordError({error: false, errorMessage: ''});
            setNewPasswordError({error: false, errorMessage: ''});
            setConfirmPasswordError({error: true, errorMessage: 'New passwords don\'t match'});
        }
    };

    return <Box sx={{p: 3}}>
        <Card elevation={0}>
            <CustomCardHeader title={routes.profile.menuTitle}/>
            <CardContent>
                <HelpSystemContext.Consumer>
                    {open =>
                        <form onSubmit={handleSubmit}>
                            <Grid container justifyContent='flex-start' alignItems={'flex-start'}>
                                <Grid container item md={open ? 8 : 7}>
                                    <PasswordField width='100' required name='currentPassword'
                                                   password={changePasswordForm.currentPassword}
                                                   label='Current Password'
                                                   handleChange={handleChange} error={currentPasswordError.error}
                                                   errorMessage={currentPasswordError.errorMessage}/>
                                </Grid>
                                <Grid container item md={open ? 8 : 7}>
                                    <PasswordField width='100' type='password' required name='newPassword'
                                                   label='New Password' password={changePasswordForm.newPassword}
                                                   handleChange={handleChange} error={newPasswordError.error}
                                                   errorMessage={newPasswordError.errorMessage}
                                                   showPasswordRequirements showPasswordStrengthMeter/>
                                </Grid>
                                <Grid container item md={open ? 8 : 7}>
                                    <PasswordField error={confirmPasswordError.error}
                                                   errorMessage={confirmPasswordError.errorMessage}
                                                   width='100' required
                                                   name='confirmNewPassword'
                                                   password={changePasswordForm.confirmNewPassword}
                                                   label='Confirm New Password'
                                                   handleChange={handleChange}/>
                                </Grid>
                                <Grid container item md={open ? 8 : 7} justifyContent='flex-end'>
                                    <CircularProgressButton label='Update' variant='contained' size='small' mt={2}
                                                            type='submit' inProgress={isLoading}/>
                                    <CircularProgressButton label='Cancel' variant='text' size='small'
                                                            ml={1} mt={2} onClick={handleCancel} type='reset'/>
                                </Grid>
                            </Grid>
                        </form>
                    }
                </HelpSystemContext.Consumer>
            </CardContent>
        </Card>
    </Box>
}

export default ChangePasswordComponent;

ChangePasswordComponent.prototype = {
    changePassword: PropTypes.func,
};
